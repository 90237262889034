import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { config } from 'src/app/config';
import { SuperAdminService } from 'src/app/super-admin.service';
import { Observable, empty } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";
import { ImagePreviewComponent } from '../image-preview/image-preview.component';
import { DomSanitizer } from '@angular/platform-browser';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-add-lr',
  templateUrl: './add-lr.component.html',
  styleUrls: ['./add-lr.component.css']
})
export class AddLrComponent implements OnInit {
  imageSrc: any;
  imageSrcpodback: any;
  lastTripID: any;
  lastVehicleNo: any;
  companyList = [];
  lastVehicleType: any;
  filteredConsignorName: Observable<string[]>;
  filteredConsigneeName: Observable<string[]>;
  companyAddress: any = [{}];
  supervisorIDs: any;
  permitFromVehicle;
  multipletrips = [];
  multipleTrips = [];
  tripIDS = [];
  dropTripID = [];
  list = [];
  val = "";
  shown = false;
  @Input('size') size;
  selected = 'Search';
  @ViewChild('searchfield', { static: false }) searchfield: ElementRef;
  onChange: any = () => { };
  check = false;
  dropdownSettings;
  uniqueRes: any = [];
  receipt: any[];
  tripId: any;
  filterdOptions = [];
  vehicles = [];
  DriverNumber;
  vehiclesNumber = [];
  vehicleType;
  vehicleRoadPermit;
  InvoiceDetails: MatTableDataSource<any> = new MatTableDataSource([]);
  vehicletypes = [];
  vehiclePermitNum = [];
  vehicleTypesNames = [];
  vehiclePermit = [];
  filesToUpload: Array<File> = [];
  Tollfiles: Array<File> = [];
  TollfilesString = [];
  loadingSlips: Array<File> = [];
  loadingSlipsBack: Array<File> = [];
  loadingSlipsString = [];
  loadingSlipsBackString = [];
  clientList = [];
  actualbalance = 0;
  updownkm = 0;
  totalhour = 0;
  DriverList = []
  DriverLists = []
  // DriverNumber;
  DriverNames = [];
  Consigneename = [];
  DriverNumbers = [];
  displayedColumns = ['noOfArticle', 'invoiceNo', 'customerName', 'invoiceValue', 'weight', 'pod'];
  options: string[] = ['One', 'Two', 'Three'];
  filteredVehicleTypes: Observable<string[]>;
  filteredVehicleRoadPermit: Observable<string[]>;
  // filteredConsignorName: Observable<string[]>;
  filteredVehicleNo: Observable<string[]>;
  filteredDriverName: Observable<string[]>;
  filteredDriverNumber: Observable<string[]>;
  //Tollfiles:string  []  =  [];
  addNewLR = new FormGroup({
    supervisorname: new FormControl(""),
    clientList: new FormControl('', Validators.compose([Validators.required])),
    manualLR_no: new FormControl(''),
    ConsigneeName: new FormControl(''),
    ConsigneeAddress: new FormControl(''),
    ConsignorName: new FormControl(''),
    ConsignorAddress: new FormControl(''),
    VehicleNo: new FormControl(''),
    vehicleType: new FormControl(''),
    from: new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^\S+|^\s+\S+/)])),
    to: new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^\S+|^\s+\S+/)])),
    GSTIN: new FormControl('', Validators.compose([Validators.required, Validators.maxLength(15)])),
    // POD: new FormControl('',),
    date: new FormControl('', Validators.required),
    RoadPermitNo: new FormControl(''),
    StateCode: new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^\S+|^\s+\S+/)])),
    // DLY: new FormControl('',Validators.compose([Validators.required,Validators.pattern(/^\S+|^\s+\S+/)])),
    // DLY: new FormControl(""),
    // DeclaredValue: new FormControl(''),
    fixAdhoc: new FormControl('', Validators.required),
    quantity: new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^\S+|^\s+\S+/)])),
    transportorName: new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^\S+|^\s+\S+/)])),
    transportorMobile: new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^\d{10}$/)])),
    driverName: new FormControl(''),
    driverMobile: new FormControl(''),
    // customerName:new FormControl('',Validators.required),
    //tollAttachment: new FormControl(''),
    loadingSlipAttchment: new FormControl(''),
    loadingSlipAttchmentBack: new FormControl(''),
    Frieght: new FormControl(''),
    // InvoiceNo: new FormControl(''),
    // InvoiceValue: new FormControl(''),
    // Weight: new FormControl(''),
    // NoofArticle: new FormControl(''),
    startmeterReading: new FormControl('', Validators.compose([Validators.required, Validators.pattern("^[0-9]*$")])),
    endmeterReading: new FormControl('', Validators.compose([Validators.pattern("^[0-9]*$")])),
    tripSDate: new FormControl('', Validators.required),
    tripEDate: new FormControl(''),
    outtime: new FormControl('', Validators.required),
    INtime: new FormControl(''),
    noofTools: new FormControl('', Validators.pattern("^[0-9]*$")),
    tooltaxPaid: new FormControl('', Validators.pattern("^[0-9]*$")),
    labourPaid: new FormControl('', Validators.pattern("^[0-9]*$")),
    otherPayment: new FormControl('', Validators.pattern("^[0-9]*$")),
    updownkm: new FormControl(''),
    totalhour: new FormControl(''),
    extrahour: new FormControl('', Validators.pattern("^[0-9]*$")),
    detention: new FormControl('', Validators.pattern("^[0-9]*$")),
    advance: new FormControl('', Validators.compose([Validators.required, Validators.pattern("^[0-9]*$")])),
    balance: new FormControl(''),
    balancePaid: new FormControl('', Validators.compose([Validators.required, Validators.pattern("^[0-9]*$")])),
    // vehicleRate: new FormControl('', Validators.compose([Validators.required, Validators.pattern("^[0-9]*$")])),
    vehicleRate: new FormControl(''),
    tripIdGeneration: new FormControl('False', Validators.required),
    manualTripID: new FormControl('')

    //status: new FormControl('')
  })

  // addNewLR = new FormGroup({
  //   supervisorname: new FormControl(""),
  //   clientList: new FormControl('', Validators.compose([Validators.required])),
  //   manualLR_no: new FormControl(''),
  //   ConsigneeName: new FormControl(''),
  //   ConsigneeAddress: new FormControl(''),
  //   ConsignorName: new FormControl(''),
  //   ConsignorAddress: new FormControl(''),
  //   VehicleNo: new FormControl(''),
  //   vehicleType: new FormControl(''),
  //   from: new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^\S+|^\s+\S+/)])),
  //   to: new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^\S+|^\s+\S+/)])),
  //   GSTIN: new FormControl('', Validators.compose([Validators.required, Validators.maxLength(15)])),
  //   // POD: new FormControl('',),
  //   date: new FormControl('', Validators.required),
  //   RoadPermitNo: new FormControl(''),
  //   StateCode: new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^\S+|^\s+\S+/)])),
  //   // DLY: new FormControl('',Validators.compose([Validators.required,Validators.pattern(/^\S+|^\s+\S+/)])),
  //   // DLY: new FormControl(""),
  //   // DeclaredValue: new FormControl(''),
  //   fixAdhoc: new FormControl('', Validators.required),
  //   quantity: new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^\S+|^\s+\S+/)])),
  //   transportorName: new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^\S+|^\s+\S+/)])),
  //   transportorMobile: new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^\d{10}$/)])),
  //   driverName: new FormControl(''),
  //   driverMobile: new FormControl(''),
  //   // customerName:new FormControl('',Validators.required),
  //   //tollAttachment: new FormControl(''),
  //   loadingSlipAttchment: new FormControl(''),
  //   loadingSlipAttchmentBack: new FormControl(''),
  //   Frieght: new FormControl(''),
  //   // InvoiceNo: new FormControl(''),
  //   // InvoiceValue: new FormControl(''),
  //   // Weight: new FormControl(''),
  //   // NoofArticle: new FormControl(''),
  //   startmeterReading: new FormControl('', Validators.compose([Validators.required, Validators.pattern("^[0-9]*$")])),
  //   endmeterReading: new FormControl('', Validators.compose([Validators.pattern("^[0-9]*$")])),
  //   tripSDate: new FormControl('', Validators.required),
  //   tripEDate: new FormControl(''),
  //   outtime: new FormControl('', Validators.required),
  //   INtime: new FormControl(''),
  //   noofTools: new FormControl('', Validators.pattern("^[0-9]*$")),
  //   tooltaxPaid: new FormControl('', Validators.pattern("^[0-9]*$")),
  //   labourPaid: new FormControl('', Validators.pattern("^[0-9]*$")),
  //   otherPayment: new FormControl('', Validators.pattern("^[0-9]*$")),
  //   updownkm: new FormControl(''),
  //   totalhour: new FormControl(''),
  //   extrahour: new FormControl('', Validators.pattern("^[0-9]*$")),
  //   detention: new FormControl('', Validators.pattern("^[0-9]*$")),
  //   advance: new FormControl('', Validators.compose([Validators.required, Validators.pattern("^[0-9]*$")])),
  //   balance: new FormControl(''),
  //   balancePaid: new FormControl('', Validators.compose([Validators.required, Validators.pattern("^[0-9]*$")])),
  //   vehicleRate: new FormControl('', Validators.compose([Validators.required, Validators.pattern("^[0-9]*$")])),
  //   tripIdGeneration: new FormControl('False', Validators.required),
  //   manualTripID: new FormControl('')

  //   //status: new FormControl('')
  // })
  docURL: any;
  podURL: any;
  podParticularURL: any;
  paramId: any;
  isMultiple = true;
  saveAndExit = true;
  count = 0;
  isTripIDForMultiple = false
  companyL: any;

  @ViewChild('showDoc') showDoc: ElementRef;
  @ViewChild('showPOD') showPOD: ElementRef;
  @ViewChild('showParticularPOD') showParticularPOD: ElementRef;
  superVisor: any;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private toastr: ToastrService, private superAdminService: SuperAdminService, private spinner: NgxSpinnerService, public dialog: MatDialog, private sanitizer: DomSanitizer) {
    this.activatedRoute.params.subscribe(param => {
      this.paramId = param.id;
      this.getVehicleDetails();
    })

    if (this.paramId == 'single') {
      this.isMultiple = false
      // console.log(this.isMultiple)
    }
  }
  count_user_click = 0;
  decValue = 0;
  submitLRClicked = false;
  userSessionData: any
  get ff() {
    return this.addNewLR.controls
  }
  get vv() {
    return this.addNewLR.controls
  }
  ngOnInit(): any {
    // console.log(this.userSessionData = JSON.parse(sessionStorage.getItem('userSession'))._id);

    //  this.getVehicleType();
    this.getClientList();
    this.getDriverNames();
    this.getSuperVisorName();
    //   this.getLR();
    //   this.getTripIDs();


  }
  getDriverNames() {
    this.superAdminService.getDrivers()
      .subscribe((res: { status: any, message: any, data: any }) => {
        if (res.status == true) {
          var rawdata = res.data;
          this.DriverList = rawdata;
          // console.log(this.DriverList)
          this.DriverLists = this.DriverList.map((x) => x.driver_name)
          //console.log(this.DriverList.map((x) => x.driver_name))
          this.DriverList.forEach((item) => {
            if (item.driver_name) {
              this.DriverNames.push(item.driver_name)
            }
            if (item.contactNumber) {
              this.DriverNumbers.push(item.contactNumber)
            }
          })
          this.filteredDriverName = this.ff.driverName.valueChanges.pipe(
            startWith(''),
            map(value => this._filterDriverName(value || '')),
          );
          this.filteredDriverNumber = this.ff.driverMobile.valueChanges.pipe(
            startWith(''),
            map(value => this._filterDriverNumber(value || '')),
          );
          //this.dtOptions.data = this.users;
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');

          } else {
            this.toastr.success(res.message);
          }
        }
      }, error => {
      });

  }
  private _filterDriverName(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.DriverNames.filter(option => option.toLowerCase().includes(filterValue));
  }

  private _filterConsigneename(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.Consigneename.filter(option => option.toLowerCase().includes(filterValue));
  }
  private _filterDriverNumber(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.DriverNumbers.filter(option => option.toLowerCase().includes(filterValue));
  }
  getClientList() {
    this.superAdminService.getClientList()
      .subscribe((res: { status: any, message: any, data: any }) => {

        if (res.status == true) {
          var rawdata = res.data;
          this.clientList = rawdata;
          this.companyList = this.clientList.map((x) => x.clientName);
          console.log("this.companyList", this.companyList);
          this.userSessionData = JSON.parse(sessionStorage.getItem('userSession'));
          let userroleid = this.userSessionData.user_role_id[0];
          if (userroleid != 1) {
            let clients = this.userSessionData.clientList;
            // console.log('session',this.userSessionData.user_role_id[0]);
            console.log("clients1212", clients);
            this.clientList = clients.map(id => {
              return this.clientList.filter(item => item._id == id)[0]
            });
            console.log("clientlist32", this.clientList);
            this.filteredConsignorName = this.ff.ConsignorName.valueChanges.pipe(
              startWith(''),
              map(value => this._filteredConsignorName(value || ''))
            )
            this.filteredConsigneeName = this.ff.ConsigneeName.valueChanges.pipe(
              startWith(''),
              map(value => this._filteredConsigneeName(value || ''))
            )
          }
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');

          } else {
            this.toastr.success(res.message);
          }
        }
      }, error => {
      });

  }

  private _filteredConsigneeName(value: string): string[] {
    const filtervalue = value.toLowerCase();

    return this.companyList.filter(option => option?.toLowerCase().includes(filtervalue))
  }

  private _filteredConsignorName(value: string): string[] {
    const filtervalue = value.toLowerCase();

    return this.companyList.filter(option => option?.toLowerCase().includes(filtervalue))
  }


  getVehicleType() {
    // console.log("hhh")
    this.superAdminService.getVehicleType()
      .subscribe((res: { status: any, message: any, data: any }) => {

        if (res.status == true) {
          var rawdata = res.data;
          this.vehicletypes = rawdata;
          this.vehicletypes.forEach((item) => {
            this.vehicleTypesNames.push(item.vehicletype_name)
          })
          this.filteredVehicleTypes = this.ff.vehicleType.valueChanges.pipe(
            startWith(''),
            map(value => this._filterVehicleType(value || '')),
          );
          //this.dtOptions.data = this.users;
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');

          } else {
            this.toastr.success(res.message);
          }
        }

      }, error => {
      });

  }

  getvehiclePermit() {
    // console.log("ddddd")
    var NO = this.permitFromVehicle
    // console.log("vehicleNO88888",NO)
    this.superAdminService.getVehiclePermit(NO)
      .subscribe((res: { status: any, message: any, data: any }) => {
        if (res.status == true) {
          var rawdata = res.data;
          // console.log("vehicelPermit--",rawdata)
          this.vehiclePermitNum = rawdata
          this.vehiclePermitNum.forEach((item) => {
            this.vehiclePermit.push(item.permitNumber)

          })
          // this.filteredVehicleRoadPermit = this.vv.vehicleRoadPermit.valueChanges.pipe(
          //   startWith(''),
          //   map(value => this._filterVehiclePermit(value || '')),
          // );

        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');

          } else {
            this.toastr.success(res.message);
          }

        }
      })
  }
  private _filterVehicleType(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.vehicleTypesNames.filter(option => option.toLowerCase().includes(filterValue));
  }

  private _filterVehiclePermit(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.vehiclePermitNum.filter(option => option.toLowerCase().includes(filterValue));
  }
  getVehicleDetails() {
    this.superAdminService.getvehicles().subscribe((res: { status: any, message: any, data: any }) => {
      if (res.status == true) {
        this.vehicles = res.data;
        this.vehicles.forEach((item) => {
          this.vehiclesNumber.push(item.vehicle_no)
        })
        this.filteredVehicleNo = this.ff.VehicleNo.valueChanges.pipe(
          startWith(''),
          map(value => this._filterVehicleNo(value || '')),
        );
      }
      else {
        if (res.status == 401) {
          this.toastr.error(res.message); this.addNewLR.patchValue({
            driverMobile: this.DriverNumber
          });
          window.sessionStorage.clear();
          this.router.navigateByUrl('superAuth/login');

        } else {
          this.toastr.success(res.message);
        }
      }

    }, error => {
    });
  }
  private _filterVehicleNo(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.vehiclesNumber.filter(option => option.toLowerCase().includes(filterValue));
  }
  fileChangeEventtollAttachment(fileInput: any) {
    for (var i = 0; i < fileInput.target.files.length; i++) {
      this.Tollfiles.push(fileInput.target.files[i]);
    }

    // this.filesToUpload.push(fileInput.target.files[0] );

  }
  fileChangeEventloadingSlipAttchment(fileInput: any) {
    this.loadingSlips = [];
    for (var i = 0; i < fileInput.target.files.length; i++) {
      this.loadingSlips.push(fileInput.target.files[i]);
    }
    // console.log("Front POD", this.loadingSlips[0])
  }

  readURL(event: any, pod: any): void {
    if (pod === 'front') {
      //console.log("Image Event$$$$$", event)
      if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];

        const reader = new FileReader();
        reader.onload = e => this.imageSrc = reader.result;

        reader.readAsDataURL(file);
      }
    } else {
      //  console.log("Image Event$$$$$", event)
      if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];

        const reader = new FileReader();
        reader.onload = e => this.imageSrcpodback = reader.result;

        reader.readAsDataURL(file);
      }
    }

  }
  clearpodfront(pod: any) {
    if (pod === 'front') {

      this.imageSrc = ''
    } else {
      this.imageSrcpodback = ''
    }
  }

  fileChangeEventloadingSlipBackAttchment(fileInput: any) {
    this.loadingSlipsBack = [];
    for (var i = 0; i < fileInput.target.files.length; i++) {
      this.loadingSlipsBack.push(fileInput.target.files[i]);
    }
    // console.log("back pOD", this.loadingSlipsBack)

  }

  addTollFiles() {
    const formData = new FormData();

    for (let i = 0; i < this.Tollfiles.length; i++) {
      formData.append("Tollfiles[]", this.Tollfiles[i]);
    }

    this.superAdminService.addTollFiles(formData)
      .subscribe((res: { rawdata: any, status: any, message: any, authorization: any, code: any }) => {

        if (res.status == true) {
          //alert(res.message)
          this.toastr.success(res.message);
          this.TollfilesString = res.rawdata;
          //this.router.navigate(['/superAdmin/lrmanagement']);
          //this.addNewLR.reset()
        }
        else {
          // alert(res.message)
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');

          } else {
            this.toastr.success(res.message);
          }
          //this.addNewLR.reset()
        }

      }, error => {
      });

  }

  addloadingSlips() {
    this.spinner.show();
    const formData = new FormData();
    // console.log("frontPOD", this.loadingSlips)
    for (let i = 0; i < this.loadingSlips.length; i++) {
      formData.append("loadingSlips[]", this.loadingSlips[i]);
    }
    // console.log("Front", formData)
    this.superAdminService.addloadingSlips(formData)
      .subscribe((res: { rawdata: any, status: any, message: any, authorization: any, code: any }) => {

        if (res.status == true) {
          this.toastr.success(res.message);
          this.loadingSlipsString = res.rawdata;
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');

          } else {
            this.toastr.error(res.message);
          }
        }
        this.spinner.hide();

      }, error => {
        this.spinner.hide();
      });


  }

  addloadingSlipsBack() {
    this.spinner.show();
    const formData = new FormData();
    // console.log("POD BAck ADD File", this.loadingSlipsBack)
    for (let i = 0; i < this.loadingSlipsBack.length; i++) {
      formData.append("loadingSlipsback[]", this.loadingSlipsBack[i]);
    }
    // debugger;
    this.superAdminService.addloadingSlipsBack(formData)
      .subscribe((res: { rawdata: any, status: any, message: any, authorization: any, code: any }) => {

        if (res.status == true) {
          this.toastr.success(res.message);
          this.loadingSlipsBackString = res.rawdata;
          // console.log("back PODSS", this.loadingSlipsBackString)
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');

          } else {
            this.toastr.error(res.message);
          }
        }
        this.spinner.hide();

      }, error => {
        this.spinner.hide();
      });


  }
  fileChangeEventAttachment(fileInput: any) {
    this.filesToUpload.push(fileInput.target.files[0]);
  }
  onFocusOutEvent(event: any) {
    // this.actualbalance = parseInt(document.getElementById("vehicleRatectrl")['value']) -
    //   parseInt(document.getElementById("advancectrl")['value']) -
    //   parseInt(event.target.value);
    this.actualbalance = parseInt(this.addNewLR.get('vehicleRate').value) -
      parseInt(this.addNewLR.get('advance').value) -
      parseInt(event.target.value);
    this.addNewLR.patchValue({
      balance: this.actualbalance
    });

  }
  onFocusOutendmeterReading(event: any) {
    this.updownkm = parseInt(event.target.value) -
      parseInt(this.addNewLR.get('startmeterReading').value);
    this.addNewLR.patchValue({
      updownkm: this.updownkm
    });


  }
  getVehicleTypeFromNumber(event: any) {
    // console.log("hhhhhhh")
    this.multipleTrips = []
    this.multipletrips = []
    // console.log(this.vehicles)
    // this.vehicleType= this.vehicles.map{return this.vehicles.vehicle_no == event.target.value} 
    for (let i = 0; i < this.vehicles.length; i++) {
      if (this.vehicles[i].vehicle_no == event.option.value) {
        this.vehicleType = this.vehicles[i].type;

        break;
      }
    }

    for (let i = 0; i < this.tripIDS.length; i++) {
      if (this.tripIDS[i].vehicle_no == event.option.value) {
        // console.log("ressss------",this.multipleTrips)
        this.multipletrips.push(this.tripIDS[i].TripID)

      }
    }
    // console.log(this.multipletrips)
    this.removeDuplicates(this.multipletrips)
    // console.log(this.uniqueRes)
    this.multipletrips = this.uniqueRes
    if (this.multipletrips) {
      this.multipleTrips.push(this.multipletrips);
    } else {
      this.multipleTrips = []
    }



    this.addNewLR.patchValue({
      vehicleType: this.vehicleType,

    });
  }


  getVehicleRoadPermitNumber(event: any) {
    //this.permitFromVehicle = event
    // console.log("roadpermit", event.target.value)
    this.superAdminService.getVehiclePermit(event.target.value)
      .subscribe((res: { status: any, message: any, data: any }) => {
        if (res.status == true) {
          var rawdata = res.data;
          //  console.log("vehicelPermit--", rawdata[0].permitNumber)
          this.addNewLR.patchValue({
            RoadPermitNo: rawdata[0].permitNumber,

          });

        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');

          } else {
            this.toastr.success(res.message);
          }

        }
      })
    // for(let i =0;i<this.vehicles.length; i++){
    //   if(this.vehicles[i].vehicle_no == event.target.value){
    //     this.vehicleRoadPermit = this.vehicles[i].permitNumber

    //   }
    // }
  }
  // onFocusDriverName(event : any){
  //     for(let i=0; i<this.DriverList.length; i++){
  //       if(this.DriverList[i].driver_name== event.target.value){
  //              this.DriverNumber = this.DriverList[i].contactNumber
  //       }
  //     }
  //     this.addNewLR.patchValue({
  //       driverMobile:  this.DriverNumber
  //     });
  //   this.onKeyDriverName();
  // }
  onFocusOuttripEDate(event: any) {


    let outtime = this.addNewLR.get('outtime').value;
    let INtime = this.addNewLR.get('INtime').value;
    let sdate = this.addNewLR.get('tripSDate').value;
    let edate = this.addNewLR.get('tripEDate').value;
    let sd1 = sdate + ' ' + outtime + ':00';
    let date1 = new Date(sd1);
    let sd2 = edate + ' ' + INtime + ':00';
    let date2 = new Date(sd2);
    var diff = (date2.getTime() - date1.getTime()) / 1000;
    diff /= (60 * 60);
    let ht = Math.abs(Math.round(diff));

    this.addNewLR.patchValue({
      totalhour: ht
    });


  }
  // addInvoiceDetail() {
  //   //  var f={
  //   //   "InvoiceNo" : this.addNewLR.get('InvoiceNo').value,
  //   //   "InvoiceValue" : this.addNewLR.get('InvoiceValue').value,
  //   //   "NoofArticle" : this.addNewLR.get('NoofArticle').value,
  //   //   "Weight" : this.addNewLR.get('Weight').value,
  //   //   "Frieght" : this.addNewLR.get('Frieght').value,
  //   //  }
  //   // var i=1;
  //   var f = {

  //     "id": "inv" + this.count_user_click,
  //     "InvoiceNo": document.getElementById("InvoiceNoctrl")['value'],
  //     "InvoiceValue": document.getElementById("InvoiceValuectrl")['value'],
  //     "NoofArticle": document.getElementById("NoofArticlectrl")['value'],
  //     "Weight": document.getElementById("Weightctrl")['value'],
  //     //"Frieght": document.getElementById("Frieghtctrl")['value'],
  //     "customerName": document.getElementById("customerNamectrl")['value'],
  //      "POD": document.getElementById("PODctrl")['value'],

  //   }
  //   if ((document.getElementById("InvoiceNoctrl")['value'] != '')
  //     && (document.getElementById("InvoiceValuectrl")['value'] != '' )
  //     && (document.getElementById("NoofArticlectrl")['value'] != '')
  //     && (document.getElementById("Weightctrl")['value'] != '')
  //     // && (document.getElementById("Frieghtctrl")['value'] != '')
  //     && (document.getElementById("customerNamectrl")['value'] != '')
  //     // && (document.getElementById("PODctrl")['value'] != '')
  //   ) {
  //     this.count_user_click += 1;
  //     let temp = this.InvoiceDetails.filteredData;
  //     temp.push(f);
  //     this.InvoiceDetails= new MatTableDataSource(temp);


  //     this.decValue += parseInt(document.getElementById("InvoiceValuectrl")['value']);
  //     this.addNewLR.patchValue({
  //       DeclaredValue: this.decValue

  //     });

  //     document.getElementById("InvoiceNoctrl")['value'] = '';
  //     document.getElementById("InvoiceValuectrl")['value'] = '';
  //     document.getElementById("NoofArticlectrl")['value'] = '';
  //     document.getElementById("Weightctrl")['value'] = '';
  //     //document.getElementById("Frieghtctrl")['value'] = '';
  //     document.getElementById("customerNamectrl")['value'] = '';
  //     document.getElementById("PODctrl")['value'] = '';
  //     this.removevalidatorcss("InvoiceNoctrl");
  //     this.removevalidatorcss("InvoiceValuectrl");
  //     this.removevalidatorcss("NoofArticlectrl");
  //     this.removevalidatorcss("Weightctrl");
  //     //this.removevalidatorcss("Frieghtctrl");
  //     this.removevalidatorcss("customerNamectrl");
  //     this.removevalidatorcss("PODctrl");
  //   }
  //   else {
  //     if (this.addNewLR.get('InvoiceNo').invalid) {
  //       this.addvalidatorcss("InvoiceNoctrl");
  //     } else {
  //       this.removevalidatorcss("InvoiceNoctrl");
  //     }
  //     if (this.addNewLR.get('InvoiceValue').invalid) {
  //       this.addvalidatorcss("InvoiceValuectrl");
  //     } else {
  //       this.removevalidatorcss("InvoiceValuectrl");
  //     }
  //     if (this.addNewLR.get('NoofArticle').invalid) {
  //       this.addvalidatorcss("NoofArticlectrl");
  //     } else {
  //       this.removevalidatorcss("NoofArticlectrl");
  //     }
  //     if (this.addNewLR.get('Weight').invalid) {
  //       this.addvalidatorcss("Weightctrl");
  //     } else {
  //       this.removevalidatorcss("Weightctrl");
  //     }
  //     if (this.addNewLR.get('customerName').invalid) {
  //       this.addvalidatorcss("customerNamectrl");
  //     } else {
  //       this.removevalidatorcss("customerNamectrl");
  //     }
  //   }
  //   //this.addNewLR.reset();
  //   // this.InvoiceDetails = 
  //   // {
  //   //   "InvoiceNo" : this.addNewLR.get('InvoiceNo').value,
  //   // }
  // }
  getSuperVisorName() {
    this.superAdminService.getSuperVisorName()
      .subscribe((res: { status: any, message: any, data: any }) => {

        if (res.status == true) {
          var rawdata = res.data;
          this.superVisor = rawdata;
          //this.dtOptions.data = this.users;
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');

          } else {
            this.toastr.error(res.message);
          }
        }
      }, error => {
      });
  }

  addNewLRSubmit(value: any) {

    // console.log("clickde", this.addNewLR.value)
    // console.log(this.userSessionData = JSON.parse(sessionStorage.getItem('userSession'))._id);

    this.submitLRClicked = true;
    // debugger;
    if (this.addNewLR.valid) {
      this.spinner.show();
      // Basic Details 
      // this.removevalidatorcss("supervisornamectrl");
      this.removevalidatorcss("generationIDctrl");
      // this.removevalidatorcss("manualTripIDctrl");
      this.removevalidatorcss("clientListctrl");
      this.removevalidatorcss("ConsigneeNamectrl");
      this.removevalidatorcss("ConsigneeAddressctrl");
      this.removevalidatorcss("ConsignorAddressctrl");
      this.removevalidatorcss("ConsignorNamectrl");
      this.removevalidatorcss("VehicleNoctrl");
      this.removevalidatorcss("vehicleTypectrl");
      this.removevalidatorcss("fromctrl");
      this.removevalidatorcss("toctrl");
      this.removevalidatorcss("GSTINctrl");
      this.removevalidatorcss("datectrl");
      this.removevalidatorcss("fixAdhocctrl");
      this.removevalidatorcss("balancectrl");
      this.removevalidatorcss("RoadPermitNoctrl");
      this.removevalidatorcss("StateCodectrl");
      // this.removevalidatorcss("DLYctrl");
      // this.removevalidatorcss("DeclaredValuectrl");
      this.removevalidatorcss("transportorNamectrl");
      this.removevalidatorcss("transportorMobilectrl");
      this.removevalidatorcss("driverNamectrl");
      this.removevalidatorcss("driverMobilectrl");
      //this.removevalidatorcss("tollAttachmentctrl");
      this.removevalidatorcss("loadingSlipAttchmentctrl");
      this.removevalidatorcss("loadingSlipAttchmentBackctrl");
      //this.removevalidatorcss("statusctrl");
      // this.removevalidatorcss("Frieghtctrl");


      // Information For office use only
      this.removevalidatorcss("startmeterReadingctrl");
      this.removevalidatorcss("endmeterReadingctrl");
      this.removevalidatorcss("tripSDatectrl");
      this.removevalidatorcss("tripEDatectrl");
      this.removevalidatorcss("outtimectrl");
      this.removevalidatorcss("INtimectrl");
      this.removevalidatorcss("noofToolsctrl");
      this.removevalidatorcss("tooltaxPaidctrl");
      this.removevalidatorcss("labourPaidctrl");
      this.removevalidatorcss("otherPaymentctrl");
      this.removevalidatorcss("updownkmctrl");
      this.removevalidatorcss("totalhourctrl");
      this.removevalidatorcss("extrahourctrl");
      this.removevalidatorcss("detentionctrl");
      this.removevalidatorcss("advancectrl");
      this.removevalidatorcss("balancectrl");
      this.removevalidatorcss("balancePaidctrl");

      // this.removevalidatorcss("vehicleRatectrl");
      const storedData = JSON.parse(sessionStorage.getItem('userSession'))

      //console.log("storedData", storedData)
      // console.log("UID&&&", storedData.user_role_id[0])
      // debugger;
      if (storedData.user_role_id[0] == 1) {
        //  console.log("supervisorname", this.addNewLR.get('supervisorname').value);
        if ((this.addNewLR.get('supervisorname').value == '') || (this.addNewLR.get('supervisorname').value == undefined)) {
          this.submitLRClicked = false;
          alert("please select supervisor")
          this.spinner.hide();
          //this.addvalidatorcss("supervisornamectrl");
          return false;
        }
        else {
          //this.removevalidatorcss("supervisornamectrl");
          this.supervisorIDs = this.addNewLR.get('supervisorname').value
        }
      } else {
        this.supervisorIDs = JSON.parse(sessionStorage.getItem('userSession'))._id;
      }

      // console.log("this.supervisorIDs", this.supervisorIDs)
      var form = {
        // Add Invoice Details
        // "InvoiceNo": this.InvoiceDetails.filteredData,

        // Basic Details
        "manualLR_no": this.addNewLR.get('manualLR_no').value,
        "supervisorId": this.supervisorIDs,
        "clientList": this.addNewLR.get('clientList').value,
        "TripID": this.addNewLR.get('tripIdGeneration').value,
        "manualTripID": this.addNewLR.get('manualTripID').value,
        "ConsigneeName": this.addNewLR.get('ConsigneeName').value.toString(),
        "ConsigneeAddress": this.addNewLR.get('ConsigneeAddress').value,
        "ConsignorName": this.addNewLR.get('ConsignorName').value.toString(),
        "ConsignorAddress": this.addNewLR.get('ConsignorAddress').value,
        "VehicleNo": this.addNewLR.get('VehicleNo').value,
        "vehicleType": this.addNewLR.get('vehicleType').value,
        "GSTIN": this.addNewLR.get('GSTIN').value,
        "from": this.addNewLR.get('from').value,
        "to": this.addNewLR.get('to').value,
        "date": this.addNewLR.get('date').value,
        "fixAdhoc": this.addNewLR.get('fixAdhoc').value,
        "quantity": this.addNewLR.get('quantity').value,
        "RoadPermitNo": this.addNewLR.get('RoadPermitNo').value,
        "StateCode": this.addNewLR.get('StateCode').value,
        // "DLY": this.addNewLR.get('DLY').value,
        // "DeclaredValue": this.addNewLR.get('DeclaredValue').value,
        "transportorName": this.addNewLR.get('transportorName').value,
        "transportorMobile": this.addNewLR.get('transportorMobile').value,
        "driverName": this.addNewLR.get('driverName').value,
        "driverMobile": this.addNewLR.get('driverMobile').value,
        //"tollAttachment": this.TollfilesString,
        "loadingSlipAttchment": this.loadingSlipsString,
        "loadingSlipAttchmentBack": this.loadingSlipsBackString,
        "Frieght": this.addNewLR.get('Frieght').value,
        // "status": this.addNewLR.get('status').value,

        // Information For office use only

        "startmeterReading": this.addNewLR.get('startmeterReading').value,
        "endmeterReading": this.addNewLR.get('endmeterReading').value,
        "tripSDate": this.addNewLR.get('tripSDate').value,
        "tripEDate": this.addNewLR.get('tripEDate').value,
        "INtime": this.addNewLR.get('INtime').value,
        "outtime": this.addNewLR.get('outtime').value,
        "noofTools": this.addNewLR.get('noofTools').value,
        "tooltaxPaid": this.addNewLR.get('tooltaxPaid').value,
        "labourPaid": this.addNewLR.get('labourPaid').value,
        "otherPayment": this.addNewLR.get('otherPayment').value,
        "updownkm": this.addNewLR.get('updownkm').value,
        "totalhour": this.addNewLR.get('totalhour').value,
        "extrahour": this.addNewLR.get('extrahour').value,
        "detention": this.addNewLR.get('detention').value,
        "advance": this.addNewLR.get('advance').value,
        "balance": this.addNewLR.get('balance').value,
        "balancePaid": this.addNewLR.get('balancePaid').value,
        "vehicleRate": this.addNewLR.get('vehicleRate').value,

      }
      console.log("froem", form)



      //sample-code for calculation

      //  console.log( " Total distance = " + ({ this.addNewLR.get('startmeterReading').value - this.addNewLR.get('endmeterReading').value  });

      //
      // console.log("formDataaaa==>",form)


      const formData = new FormData();
      const files: Array<File> = this.filesToUpload;

      for (let i = 0; i < this.filesToUpload.length; i++) {
        formData.append("uploads[]", this.filesToUpload[i]);
      }
      // for (let i = 0; i < this.Tollfiles.length; i++) {
      //   formData.append("Tollfiles[]", this.Tollfiles[i]);
      // }

      formData.append('myFormData', JSON.stringify(form));
      this.superAdminService.createLR(formData, this.paramId)
        .subscribe((res: { status: any, message: any, data: any, authorization: any, code: any }) => {
          if (res.status == true) {
            this.toastr.success(res.message);
            if (value === 'createLR') {
              this.router.navigate(['/superAdmin/lrmanagement/detail-lr/' + res.data]);
            } if (value === 'saveandExit') {
              this.router.navigate(['/superAdmin/lrmanagement/'])
            } else {
              if (value === 'multiple') {
                this.check = false;
                this.getpatchValue();
                // this.router.navigate(['/superAdmin/lrmanagement/add-lr/multiple'])
                this.resetForm();
              }

            }
            //this.addNewLR.reset()
          }
          else {
            // alert(res.message)
            if (res.status == 401) {
              this.toastr.error(res.message);
              window.sessionStorage.clear();

              this.router.navigateByUrl('superAuth/login');

            } else if (res.status == false) {
              this.toastr.error(res.message);
              if (res.data != null) {
                //  console.log(res.data );
                alert(`Pod is not available with ${res.data}`);
              }
            } else {
              // console.log(res)
              this.toastr.success(res.message);
            }
            //this.addNewLR.reset()
          }
          this.spinner.hide();
        }, error => {
          // console.log(error);
          this.spinner.hide();
        });
    }
    else {
      // Basic Details 
      if (this.addNewLR.get('supervisorname').invalid) {
        this.addvalidatorcss("supervisornamectrl");
      } else {
        this.removevalidatorcss("supervisornamectrl");
      }
      if (this.addNewLR.get('tripIdGeneration').invalid) {
        this.addvalidatorcss("generationIDctrl");
      } else {
        this.removevalidatorcss("generationIDctrl");
      }
      // if (this.addNewLR.get('manualTripID').invalid) {
      //   this.addvalidatorcss("manualTripIDctrl");
      // } else {
      //   this.removevalidatorcss("manualTripIDctrl");
      // }
      if (this.addNewLR.get('clientList').invalid) {
        this.addvalidatorcss("clientListctrl");
      } else {
        this.removevalidatorcss("clientListctrl");
      }
      if (this.addNewLR.get('ConsigneeAddress').invalid) {
        this.addvalidatorcss("ConsigneeAddressctrl");
      } else {
        this.removevalidatorcss("ConsigneeAddressctrl");
      }
      if (this.addNewLR.get('ConsignorName').invalid) {
        this.addvalidatorcss("ConsignorNamectrl");
      } else {
        this.removevalidatorcss("ConsignorNamectrl");
      }
      if (this.addNewLR.get('ConsigneeName').invalid) {
        this.addvalidatorcss("ConsigneeNamectrl");
      } else {
        this.removevalidatorcss("ConsigneeNamectrl");
      }
      if (this.addNewLR.get('ConsignorAddress').invalid) {
        this.addvalidatorcss("ConsignorAddressctrl");
      } else {
        this.removevalidatorcss("ConsignorAddressctrl");
      }
      if (this.addNewLR.get('VehicleNo').invalid) {
        this.addvalidatorcss("VehicleNoctrl");
      } else {
        this.removevalidatorcss("VehicleNoctrl");
      }
      if (this.addNewLR.get('vehicleType').invalid) {
        this.addvalidatorcss("vehicleTypectrl");
      } else {
        this.removevalidatorcss("vehicleTypectrl");
      }
      if (this.addNewLR.get('from').invalid) {
        this.addvalidatorcss("fromctrl");
      } else {
        this.removevalidatorcss("fromctrl");
      }
      if (this.addNewLR.get('to').invalid) {
        this.addvalidatorcss("toctrl");
      } else {
        this.removevalidatorcss("toctrl");
      }
      if (this.addNewLR.get('date').invalid) {
        this.addvalidatorcss("datectrl");
      } else {
        this.removevalidatorcss("datectrl");
      }
      if (this.addNewLR.get('fixAdhoc').invalid) {
        this.addvalidatorcss("fixAdhocctrl");
      } else {
        this.removevalidatorcss("fixAdhocctrl");
      }
      if (this.addNewLR.get('quantity').invalid) {
        this.addvalidatorcss("quantityctrl");
      } else {
        this.removevalidatorcss("quantityctrl");
      }
      if (this.addNewLR.get('GSTIN').invalid) {
        this.addvalidatorcss("GSTINctrl");
      } else {
        this.removevalidatorcss("GSTINctrl");
      }
      if (this.addNewLR.get('RoadPermitNo').invalid) {
        this.addvalidatorcss("RoadPermitNoctrl");
      } else {
        this.removevalidatorcss("RoadPermitNoctrl");
      }
      if (this.addNewLR.get('StateCode').invalid) {
        this.addvalidatorcss("StateCodectrl");
      } else {
        this.removevalidatorcss("StateCodectrl");
      }
      // if (this.addNewLR.get('DLY').invalid) {
      //   this.addvalidatorcss("DLYctrl");
      // } else {
      //   this.removevalidatorcss("DLYctrl");
      // }
      // if (this.addNewLR.get('DeclaredValue').invalid) {
      //   this.addvalidatorcss("DeclaredValuectrl");
      // } else {
      //   this.removevalidatorcss("DeclaredValuectrl");
      // }

      if (this.addNewLR.get('transportorName').invalid) {
        this.addvalidatorcss("transportorNamectrl");
      } else {
        this.removevalidatorcss("transportorNamectrl");
      }
      if (this.addNewLR.get('transportorMobile').invalid) {
        this.addvalidatorcss("transportorMobilectrl");
      } else {
        this.removevalidatorcss("transportorMobilectrl");
      }
      if (this.addNewLR.get('driverName').invalid) {
        this.addvalidatorcss("driverNamectrl");
      } else {
        this.removevalidatorcss("driverNamectrl");
      }
      if (this.addNewLR.get('driverMobile').invalid) {
        this.addvalidatorcss("driverMobilectrl");
      } else {
        this.removevalidatorcss("driverMobilectrl");
      }
      // if (this.addNewLR.get('tollAttachment').invalid) {
      //   this.addvalidatorcss("tollAttachmentctrl");
      // } else {
      //   this.removevalidatorcss("tollAttachmentctrl");
      // }
      if (this.addNewLR.get('loadingSlipAttchment').invalid) {
        this.addvalidatorcss("loadingSlipAttchmentctrl");
      } else {
        this.removevalidatorcss("loadingSlipAttchmentctrl");
      }
      if (this.addNewLR.get('loadingSlipAttchmentBack').invalid) {
        this.addvalidatorcss("loadingSlipAttchmentBackctrl");
      } else {
        this.removevalidatorcss("loadingSlipAttchmentBackctrl");
      }

      // if (this.addNewLR.get('Frieght').invalid) {
      //   this.addvalidatorcss("Frieghtctrl");
      // } else {
      //   this.removevalidatorcss("Frieghtctrl");
      // }
      // if (this.addNewLR.get('status').invalid) {
      //   this.addvalidatorcss("statusctrl");
      // } else {
      //   this.removevalidatorcss("statusctrl");
      // }
      // Information For office use only  

      if (this.addNewLR.get('startmeterReading').invalid) {
        this.addvalidatorcss("startmeterReadingctrl");
      } else {
        this.removevalidatorcss("startmeterReadingctrl");
      }
      if (this.addNewLR.get('endmeterReading').invalid) {
        this.addvalidatorcss("endmeterReadingctrl");
      } else {
        this.removevalidatorcss("endmeterReadingctrl");
      }

      if (this.addNewLR.get('tripEDate').invalid) {
        this.addvalidatorcss("tripEDatectrl");
      } else {
        this.removevalidatorcss("tripEDatectrl");
      }
      if (this.addNewLR.get('tripSDate').invalid) {
        this.addvalidatorcss("tripSDatectrl");
      } else {
        this.removevalidatorcss("tripSDatectrl");
      }
      if (this.addNewLR.get('outtime').invalid) {
        this.addvalidatorcss("outtimectrl");
      } else {
        this.removevalidatorcss("outtimectrl");
      }
      if (this.addNewLR.get('INtime').invalid) {
        this.addvalidatorcss("INtimectrl");
      } else {
        this.removevalidatorcss("INtimectrl");
      }
      if (this.addNewLR.get('noofTools').invalid) {
        this.addvalidatorcss("noofToolsctrl");
      } else {
        this.removevalidatorcss("noofToolsctrl");
      }
      if (this.addNewLR.get('tooltaxPaid').invalid) {
        this.addvalidatorcss("tooltaxPaidctrl");
      } else {
        this.removevalidatorcss("tooltaxPaidctrl");
      }
      if (this.addNewLR.get('labourPaid').invalid) {
        this.addvalidatorcss("labourPaidctrl");
      } else {
        this.removevalidatorcss("labourPaidctrl");
      }
      if (this.addNewLR.get('otherPayment').invalid) {
        this.addvalidatorcss("otherPaymentctrl");
      } else {
        this.removevalidatorcss("otherPaymentctrl");
      }
      if (this.addNewLR.get('updownkm').invalid) {
        this.addvalidatorcss("updownkmctrl");
      } else {
        this.removevalidatorcss("updownkmctrl");
      }
      if (this.addNewLR.get('totalhour').invalid) {
        this.addvalidatorcss("totalhourctrl");
      } else {
        this.removevalidatorcss("totalhourctrl");
      }
      if (this.addNewLR.get('extrahour').invalid) {
        this.addvalidatorcss("extrahourctrl");
      } else {
        this.removevalidatorcss("extrahourctrl");
      }
      if (this.addNewLR.get('detention').invalid) {
        this.addvalidatorcss("detentionctrl");
      } else {
        this.removevalidatorcss("detentionctrl");
      }
      if (this.addNewLR.get('advance').invalid) {
        this.addvalidatorcss("advancectrl");
      } else {
        this.removevalidatorcss("advancectrl");
      }
      if (this.addNewLR.get('balance').invalid) {
        this.addvalidatorcss("balancectrl");
      } else {
        this.removevalidatorcss("balancectrl");
      }

      if (this.addNewLR.get('balancePaid').invalid) {
        this.addvalidatorcss("balancePaidctrl");
      } else {
        this.removevalidatorcss("balancePaidctrl");
      }
      // if (this.addNewLR.get('vehicleRate').invalid) {
      //   this.addvalidatorcss("vehicleRatectrl");
      // } else {
      //   this.removevalidatorcss("vehicleRatectrl");
      // }


      this.spinner.hide();
    }
  }
  resetForm() {
    this.spinner.hide();
    this.addNewLR.reset();
    this.submitLRClicked = false;
    this.removevalidatorcss("clientListctrl");
    this.removevalidatorcss("generationIDctrl");
    this.removevalidatorcss("manualTripIDctrl");
    this.removevalidatorcss("ConsigneeNamectrl");
    this.removevalidatorcss("ConsigneeAddressctrl");
    this.removevalidatorcss("ConsignorAddressctrl");
    this.removevalidatorcss("ConsignorNamectrl");
    this.removevalidatorcss("VehicleNoctrl");
    this.removevalidatorcss("vehicleTypectrl");
    this.removevalidatorcss("fromctrl");
    this.removevalidatorcss("toctrl");
    this.removevalidatorcss("GSTINctrl");
    this.removevalidatorcss("datectrl");
    this.removevalidatorcss("fixAdhocctrl");
    this.removevalidatorcss("quantityctrl");
    this.removevalidatorcss("balancectrl");
    this.removevalidatorcss("RoadPermitNoctrl");
    this.removevalidatorcss("StateCodectrl");
    this.removevalidatorcss("DLYctrl");
    this.removevalidatorcss("DeclaredValuectrl");
    this.removevalidatorcss("transportorNamectrl");
    this.removevalidatorcss("transportorMobilectrl");
    this.removevalidatorcss("driverNamectrl");
    this.removevalidatorcss("driverMobilectrl");
    //this.removevalidatorcss("tollAttachmentctrl");
    this.removevalidatorcss("loadingSlipAttchmentctrl");
    this.removevalidatorcss("loadingSlipAttchmentBackctrl");

    //this.removevalidatorcss("statusctrl");
    // this.removevalidatorcss("Frieghtctrl");


    // Information For office use only
    this.removevalidatorcss("startmeterReadingctrl");
    this.removevalidatorcss("endmeterReadingctrl");
    this.removevalidatorcss("tripSDatectrl");
    this.removevalidatorcss("tripEDatectrl");
    this.removevalidatorcss("outtimectrl");
    this.removevalidatorcss("INtimectrl");
    this.removevalidatorcss("noofToolsctrl");
    this.removevalidatorcss("tooltaxPaidctrl");
    this.removevalidatorcss("labourPaidctrl");
    this.removevalidatorcss("otherPaymentctrl");
    this.removevalidatorcss("updownkmctrl");
    this.removevalidatorcss("totalhourctrl");
    this.removevalidatorcss("extrahourctrl");
    this.removevalidatorcss("detentionctrl");
    this.removevalidatorcss("advancectrl");
    this.removevalidatorcss("balancectrl");
    this.removevalidatorcss("balancePaidctrl");

    // this.removevalidatorcss("vehicleRatectrl");
  }
  addvalidatorcss(id: string) {
    document.getElementById(id).classList.add('validatorcss');
  }
  removevalidatorcss(id: string) {
    document.getElementById(id).classList.remove('validatorcss');
  }

  onKeyClientName(event: any) {
    if (this.addNewLR.get('clientList').invalid) {
      this.addvalidatorcss("clientListctrl");
    } else {
      this.removevalidatorcss("clientListctrl");
    }
  }
  onKeyConsigneeName(event: any) {
    if (this.addNewLR.get('ConsigneeName').invalid) {
      this.addvalidatorcss("ConsigneeNamectrl");
    } else {
      this.removevalidatorcss("ConsigneeNamectrl");
    }
  }
  onKeyConsigneeAddress(event: any) {
    if (this.addNewLR.get('ConsigneeAddress').invalid) {
      this.addvalidatorcss("ConsigneeAddressctrl");
    } else {
      this.removevalidatorcss("ConsigneeAddressctrl");
    }
  }
  onKeyConsignorName(event: any) {
    if (this.addNewLR.get('ConsignorName').invalid) {
      this.addvalidatorcss("ConsignorNamectrl");
    } else {
      this.removevalidatorcss("ConsignorNamectrl");
    }
  }
  onKeyConsignorAddress(event: any) {
    if (this.addNewLR.get('ConsignorAddress').invalid) {
      this.addvalidatorcss("ConsignorAddressctrl");
    } else {
      this.removevalidatorcss("ConsignorAddressctrl");
    }
  }
  onKeyVehicleNo(event: any) {
    if (this.addNewLR.get('VehicleNo').invalid) {
      this.addvalidatorcss("VehicleNoctrl");
    } else {
      this.removevalidatorcss("VehicleNoctrl");
    }
  }
  onKeyVehicleType(event: any) {
    if (this.addNewLR.get('vehicleType').invalid) {
      this.addvalidatorcss("vehicleTypectrl");
    } else {
      this.removevalidatorcss("vehicleTypectrl");
    }
  }
  onKeyFrom(event: any) {
    if (this.addNewLR.get('from').invalid) {
      this.addvalidatorcss("fromctrl");
    } else {
      this.removevalidatorcss("fromctrl");
    }
  }
  onKeyTo(event: any) {
    if (this.addNewLR.get('to').invalid) {
      this.addvalidatorcss("toctrl");
    } else {
      this.removevalidatorcss("toctrl");
    }
  }
  onKeyGSTIN(event: any) {
    if (this.addNewLR.get('GSTIN').invalid) {
      this.addvalidatorcss("GSTINctrl");
    } else {
      this.removevalidatorcss("GSTINctrl");
    }
  }
  onKeyDate(event: any) {
    if (this.addNewLR.get('date').invalid) {
      this.addvalidatorcss("datectrl");
    } else {
      this.removevalidatorcss("datectrl");
    }
  }
  onKeyRoadPermitNo(event: any) {
    if (this.addNewLR.get('RoadPermitNo').invalid) {
      this.addvalidatorcss("RoadPermitNoctrl");
    } else {
      this.removevalidatorcss("RoadPermitNoctrl");
    }
  }
  onKeyStateCode(event: any) {
    if (this.addNewLR.get('StateCode').invalid) {
      this.addvalidatorcss("StateCodectrl");
    } else {
      this.removevalidatorcss("StateCodectrl");
    }
  }
  onKeyDLY(event: any) {
    if (this.addNewLR.get('DLY').invalid) {
      this.addvalidatorcss("DLYctrl");
    } else {
      this.removevalidatorcss("DLYctrl");
    }
  }
  onKeyDeclaredValue(event: any) {
    if (this.addNewLR.get('DeclaredValue').invalid) {
      this.addvalidatorcss("DeclaredValuectrl");
    } else {
      this.removevalidatorcss("DeclaredValuectrl");
    }
  }
  onKeytransportorName(event: any) {
    if (this.addNewLR.get('transportorName').invalid) {
      this.addvalidatorcss("transportorNamectrl");
    } else {
      this.removevalidatorcss("transportorNamectrl");
    }
  }
  onKeytransportorMobile(event: any) {
    if (this.addNewLR.get('transportorMobile').invalid) {
      this.addvalidatorcss("transportorMobilectrl");
    } else {
      this.removevalidatorcss("transportorMobilectrl");
    }
  }
  onKeyDriverName() {
    if (this.addNewLR.get('driverName').invalid) {
      this.addvalidatorcss("driverNamectrl");
    } else {
      this.removevalidatorcss("driverNamectrl");
    }
  }
  onKeyDriverMobile(event: any) {
    if (this.addNewLR.get('driverMobile').invalid) {
      this.addvalidatorcss("driverMobilectrl");
    } else {
      this.removevalidatorcss("driverMobilectrl");
    }
  }

  // onKeyFrieght(event: any) {
  //   if (this.addNewLR.get('Frieght').invalid) {
  //     this.addvalidatorcss("Frieghtctrl");
  //   } else {
  //     this.removevalidatorcss("Frieghtctrl");
  //   }
  // }

  onKeyloadingSlipAttchment(event: any) {
    if (this.addNewLR.get('loadingSlipAttchment').invalid) {
      this.addvalidatorcss("loadingSlipAttchmentctrl");
    } else {
      this.removevalidatorcss("loadingSlipAttchmentctrl");
    }
  }

  onKeyloadingSlipBackAttchment(event: any) {
    if (this.addNewLR.get('loadingSlipAttchmentBack').invalid) {
      this.addvalidatorcss("loadingSlipAttchmentBackctrl");
    } else {
      this.removevalidatorcss("loadingSlipAttchmentBackctrl");
    }
  }
  onKeyInvoiceNo(event: any) {
    if (this.addNewLR.get('InvoiceNo').invalid) {
      this.addvalidatorcss("InvoiceNoctrl");
    } else {
      this.removevalidatorcss("InvoiceNoctrl");
    }
  }
  onKeyInvoiceValue(event: any) {
    if (this.addNewLR.get('InvoiceValue').invalid) {
      this.addvalidatorcss("InvoiceValuectrl");
    } else {
      this.removevalidatorcss("InvoiceValuectrl");
    }
  }
  onKeyNoofArticle(event: any) {
    if (this.addNewLR.get('NoofArticle').invalid) {
      this.addvalidatorcss("NoofArticlectrl");
    } else {
      this.removevalidatorcss("NoofArticlectrl");
    }
  }
  onKeyFixAdhoc(event: any) {
    if (this.addNewLR.get('fixAdhoc').invalid) {
      this.addvalidatorcss("fixAdhocctrl");
    } else {
      this.removevalidatorcss("fixAdhocctrl");
    }
  }
  onKeyQuantity(event: any) {
    if (this.addNewLR.get('quantity').invalid) {
      this.addvalidatorcss("quantityctrl");
    } else {
      this.removevalidatorcss("quantityctrl");
    }
  }
  onKeyWeight(event: any) {
    if (this.addNewLR.get('Weight').invalid) {
      this.addvalidatorcss("Weightctrl");
    } else {
      this.removevalidatorcss("Weightctrl");
    }
  }
  onKeyTransportorName(event: any) {
    if (this.addNewLR.get('transportorName').invalid) {
      this.addvalidatorcss("transportorNamectrl");
    } else {
      this.removevalidatorcss("transportorNamectrl");
    }
  }
  onKeyTransportorMobile(event: any) {
    if (this.addNewLR.get('transportorMobile').invalid) {
      this.addvalidatorcss("transportorMobilectrl");
    } else {
      this.removevalidatorcss("transportorMobilectrl");
    }
  }
  onKeyCustomerName(event: any) {
    if (this.addNewLR.get('customerName').invalid) {
      this.addvalidatorcss("customerNamectrl");
    } else {
      this.removevalidatorcss("customerNamectrl");
    }
  }
  onKeyStartMeterReading(event: any) {
    if (this.addNewLR.get('startmeterReading').invalid) {
      this.addvalidatorcss("startmeterReadingctrl");
    } else {
      this.removevalidatorcss("startmeterReadingctrl");
    }
  }
  onKeyEndMeterReading(event: any) {
    if (this.addNewLR.get('endmeterReading').invalid) {
      this.addvalidatorcss("endmeterReadingctrl");
    } else {
      this.removevalidatorcss("endmeterReadingctrl");
    }
  }


  backTo() {
    window.history.back()
  }
  get gstin() {
    return this.addNewLR.get('GSTIN')
  }
  get InvoiceValue() {
    return this.addNewLR.get('InvoiceValue')
  }

  get Weight() {
    return this.addNewLR.get('Weight')
  }

  get NoofArticle() {
    return this.addNewLR.get('NoofArticle')
  }
  preview(index) {
    let file = this.filesToUpload[index];
    if (file) {
      if (['image/jpeg', 'image/png', 'image/svg', 'image/svg+xml'].includes(file.type)) {
        const dialogRef = this.dialog.open(ImagePreviewComponent, {
          width: '80%',
          height: '90vh',
          data: { file: file }
        });
      }
      else {
        let url = URL.createObjectURL(file);
        this.podParticularURL = this.sanitizer.bypassSecurityTrustUrl(url);
        setTimeout(() => {
          this.showParticularPOD.nativeElement.click();
        })
      }
    }
    else {
      this.toastr.error("Please upload attachment")
    }
  }
  previewLR() {
    if (this.loadingSlips[0]) {
      if (['image/jpeg', 'image/png', 'image/svg', 'image/svg+xml'].includes(this.loadingSlips[0].type)) {
        const dialogRef = this.dialog.open(ImagePreviewComponent, {
          width: '80%',
          height: '90vh',
          data: { file: this.loadingSlips[0] }
        });
      }
      else {
        let url = URL.createObjectURL(this.loadingSlips[0]);
        this.docURL = this.sanitizer.bypassSecurityTrustUrl(url);
        setTimeout(() => {
          this.showDoc.nativeElement.click();
        })
      }
    }
    else {
      this.toastr.error("Please upload attachment")
    }
  }

  previewLRBack() {
    if (this.loadingSlipsBack[0]) {
      if (['image/jpeg', 'image/png', 'image/svg', 'image/svg+xml'].includes(this.loadingSlipsBack[0].type)) {
        const dialogRef = this.dialog.open(ImagePreviewComponent, {
          width: '80%',
          height: '90vh',
          data: { file: this.loadingSlipsBack[0] }
        });
      }
      else {
        let url = URL.createObjectURL(this.loadingSlipsBack[0]);
        this.docURL = this.sanitizer.bypassSecurityTrustUrl(url);
        setTimeout(() => {
          this.showDoc.nativeElement.click();
        })
      }
    }
    else {
      this.toastr.error("Please upload attachment")
    }

  }
  previewPOD() {
    let file = this.filesToUpload.slice(-1)[0];
    if (file) {
      if (['image/jpeg', 'image/png', 'image/svg', 'image/svg+xml'].includes(file.type)) {
        const dialogRef = this.dialog.open(ImagePreviewComponent, {
          width: '80%',
          height: '90vh',
          data: { file: file }
        });
      }
      else {
        let url = URL.createObjectURL(file);
        this.podURL = this.sanitizer.bypassSecurityTrustUrl(url);
        setTimeout(() => {
          this.showPOD.nativeElement.click();
        })
      }
    }
    else {
      this.toastr.error('No file selected for Preview')
    }
  }

  onFocusDriverName(event: any) {
    // console.log("clickeeeee")
    for (let i = 0; i < this.DriverList.length; i++) {
      if (this.DriverList[i].driver_name == event) {
        this.DriverNumber = this.DriverList[i].contactNumber
      }
    }
    this.addNewLR.patchValue({
      driverMobile: this.DriverNumber
    });
    this.onKeyDriverName();
  }

  checktripid(event: any) {
    if (event == 'true') {
      this.check = true

    } else {
      this.check = false

    }
  }

  getLR() {
    this.superAdminService.getlrs()
      .subscribe((res: { status: any, message: any, data: any }) => {


        if (res.status == true) {
          var rawdata = res.data;


          var receiptData = []
          for (let i = 0; i < rawdata.length; i++) {
            receiptData.push(rawdata[i].receipt)



          }

          for (let i = 0; i < receiptData.length; i++) {
            this.dropTripID.push(receiptData[i].TripID)

          }
          // console.log('tripid',this.dropTripID);

          // this.removeDuplicates(dropTripID)
          // console.log(this.uniqueRes)
          this.dropdownSettings = {
            singleSelection: true,
            searchPlaceholderText: 'Search',
            idField: 'item_id',
            textField: 'item_text',
            allowSearchFilter: true,
            unSelectAllText: 'UnSelect All'

          };



        }
        else {
          if (res.status == 401) {
            this.spinner.hide();
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');

          } else {
            this.toastr.error(res.message);
            this.spinner.hide();
          }
        }

      }, error => {
        this.toastr.error('Something went wrong! Please contact us');
        this.spinner.hide();
      });



  }

  getTripIDs() {
    this.superAdminService.getTripIDs()
      .subscribe((res: { status: any, message: any, data: any }) => {
        if (res.status == true) {
          var rawdata = res.data;
          // console.log(rawdata)
          this.tripIDS = rawdata
          // this.lastTripID  = [];
          // this.lastVehicleNo = [];
          // this.lastVehicleType = [];

          //  console.log("Tripid", this.tripIDS )
          // this.lastTripID = this.tripIDS[this.tripIDS.length - 1].TripID;
          // this.lastVehicleNo = this.tripIDS[this.tripIDS.length - 1].vehicle_no;
          // this.lastVehicleType = this.tripIDS[this.tripIDS.length - 1].vehicle_type;
          // console.log("LRLRRRRR", this.lastVehicleType)
          // if(this.paramId === 'multiple'){
          //   this.addNewLR.patchValue({
          //     manualTripID: this.lastTripID,
          //     VehicleNo: this.lastVehicleNo

          //   })

          // }

          // if (this.lastVehicleNo) {

          //   this.addNewLR.get("VehicleNo").reset({ value: this.lastVehicleNo, disabled: true })
          //   this.addNewLR.get("vehicleType").reset({ value: this.lastVehicleType, disabled: true })
          //   this.isTripIDForMultiple = true
          //   this.addNewLR.get("tripIdGeneration").reset({ value: this.lastTripID, disabled: true })
          // }
          // let myObj = {tripID: this.lastTripID, vehicleNo: this.lastVehicleNo, vehicleType: this.lastVehicleType};
          // localStorage.setItem('data', JSON.stringify(myObj))
          // console.log("datsa",localStorage.getItem('data'))
          var temp = [];
          this.dropTripID.map((x) => {
            this.tripIDS.map((y) => {
              if (x == y.TripID && y.status == "running") {
                temp.push({ "tripid": y.TripID })

              }

              var dropTripIDs = []
              for (let i = 0; i < temp.length; i++) {
                dropTripIDs.push(temp[i].tripid)

              }
              // console.log(dropTripIDs)
              this.removeDuplicates(dropTripIDs)

              this.multipletrips = this.uniqueRes
              // console.log("njknkjn",this.multipletrips)
              // console.log("uniqueres", this.uniqueRes)

            })
          })
        }
      })
  }

  removeDuplicates(arr) {
    let unique = [];
    arr.forEach(element => {
      if (!unique.includes(element)) {
        unique.push(element);
      }
    });
    this.uniqueRes = unique

    return unique;

  }

  getCompanyAddress(event: any) {
    //  console.log("company name --address", event.option.value)
    // console.log(this.clientList)
    this.companyAddress = [{}];
    this.companyAddress.push(event.option.value);
    // console.log(this.companyAddress)
    let temp = []
    //  console.log(this.clientList.map((x) => x._id))
    this.clientList.map((x) => {
      this.companyAddress.map((y) => {
        if (x.clientName == y) {
          temp.push({ "address": x.address })
        }

      })
    })
    // console.log(temp[0].address)
    this.addNewLR.patchValue({
      ConsignorAddress: temp[0].address
    })

  }

  getCompanyAddressCon(event: any) {
    // console.log(event.option.value)
    this.companyAddress = [{}];
    this.companyAddress.push(event.option.value);
    //  console.log(this.companyAddress)
    let temp = []
    // console.log(this.clientList.map((x) => x._id))
    this.clientList.map((x) => {
      this.companyAddress.map((y) => {
        if (x.clientName == y) {
          temp.push({ "address": x.address, "gst": x.CGSTNumber })
        }

      })
    })
    // console.log(temp)
    this.addNewLR.patchValue({
      ConsigneeAddress: temp[0].address,
      GSTIN: temp[0].gst,

    })

  }
  getpatchValue() {
    this.superAdminService.getTripIDs()
      .subscribe((res: { status: any, message: any, data: any }) => {
        if (res.status == true) {
          var rawdata = res.data;
          // console.log(rawdata)
          this.tripIDS = rawdata
          // this.lastTripID  = [];
          // this.lastVehicleNo = [];
          // this.lastVehicleType = [];

          //  console.log("Tripid", this.tripIDS )
          this.lastTripID = this.tripIDS[this.tripIDS.length - 1].TripID;
          this.lastVehicleNo = this.tripIDS[this.tripIDS.length - 1].vehicle_no;
          this.lastVehicleType = this.tripIDS[this.tripIDS.length - 1].vehicle_type;
          // console.log("LRLRRRRR", this.lastVehicleType)
          if (this.paramId === 'multiple') {
            this.addNewLR.patchValue({
              manualTripID: this.lastTripID,
              VehicleNo: this.lastVehicleNo

            })

          }

          if (this.lastVehicleNo) {

            this.addNewLR.get("VehicleNo").reset({ value: this.lastVehicleNo, disabled: true })
            this.addNewLR.get("vehicleType").reset({ value: this.lastVehicleType, disabled: true })
            this.isTripIDForMultiple = true
            this.addNewLR.get("tripIdGeneration").reset({ value: this.lastTripID, disabled: true })
          }
          // let myObj = {tripID: this.lastTripID, vehicleNo: this.lastVehicleNo, vehicleType: this.lastVehicleType};
          // localStorage.setItem('data', JSON.stringify(myObj))
          // console.log("datsa",localStorage.getItem('data'))
          var temp = [];
          this.dropTripID.map((x) => {
            this.tripIDS.map((y) => {
              if (x == y.TripID && y.status == "running") {
                temp.push({ "tripid": y.TripID })

              }

              var dropTripIDs = []
              for (let i = 0; i < temp.length; i++) {
                dropTripIDs.push(temp[i].tripid)

              }
              // console.log(dropTripIDs)
              this.removeDuplicates(dropTripIDs)

              this.multipletrips = this.uniqueRes
              // console.log("njknkjn",this.multipletrips)
              // console.log("uniqueres", this.uniqueRes)

            })
          })
        }
      })
  }

}
