<div class="container-fluid">
    <div class="row manageaccount mt-3">
        <div class="col-md-12">
            <div class="d-flex align-items-baseline justify-content-between">
                <h1 class="fs-4 fw-bold mb-0">View  Maintenance</h1>
                <button class="btn btn-dark btn-sm "  matTooltip="Back"
                matTooltipClass="example-tooltip-uppercase" matTooltipPosition="left" mat-raised-button (click)="backTo()"><i class="fa-solid fa-arrow-left"></i></button>
            </div>
            <nav class="breadcrumbMaine" aria-label="breadcrumb mb-0">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item">Miscellaneous expenses </li>
                    <li class="breadcrumb-item" (click)="backTo()">Vehicle Maintenance</li>
                    <li class="breadcrumb-item" (click)="backTo()">View Maintenance</li>

                </ol>
              </nav>
        </div>
    </div>
    <div class="contentbox  mt-3">
    <div class="row ">
        <div class="col-xl-6">
           
                <div class="row">
                    <div class="col-xl-6 mb-3">
                        <div class="form-group">
                            <label for="">Supervisor Name</label>
                            <div class="viewInpBox mt-1">{{particularExpense.SupervisorName}}</div>
                        </div>
                    </div>
                    <div class="col-xl-6 mb-3">
                        <div class="form-group">
                            <label for="">Expense Category</label>
                            <div class="viewInpBox mt-1">{{particularExpense.expenseSubCategory}}</div>
                        </div>
                    </div>
                    <div class="col-xl-6 mb-3">
                        <div class="form-group">
                            <label for="">Company Name</label>
                            <div class="viewInpBox mt-1">{{particularExpense.companyName}}</div>
                        </div>
                    </div>
                    <div class="col-xl-6 mb-3">
                        <div class="form-group">
                            <label for="">Expense Amount</label>
                            <div class="viewInpBox mt-1">{{particularExpense.totalExpenseAmount}}</div>
                        </div>
                    </div>
                    <div class="col-xl-6 mb-3">
                        <div class="form-group">
                            <label for="">Expense Date</label>
                            <div class="viewInpBox mt-1">{{particularExpense.expenseDate|date}}</div>
                        </div>
                    </div>
                    <div class="col-xl-6 mb-3">
                        <div class="form-group">
                            <label for="">Vehicle Number</label>
                            <div class="viewInpBox mt-1">{{particularExpense.vehicleNumber}}</div>
                        </div>
                    </div>
                    <div class="col-xl-6 mb-3">
                        <div class="form-group">
                            <label for="">Vehicle Type</label>
                            <div class="viewInpBox mt-1">{{particularExpense.vehicleType}}</div>
                        </div>
                    </div>
                    <div class="col-xl-6 mb-3">
                        <div class="form-group">
                            <label for="">Other Charges</label>
                            <div class="viewInpBox mt-1">{{particularExpense.otherCharges}}</div>
                        </div>
                    </div>
                    <div class="col-xl-6 mb-3">
                        <div class="form-group">
                            <label for="">Mechanic Name</label>
                            <div class="viewInpBox mt-1">{{particularExpense.mechanicName}}</div>
                        </div>
                    </div>
                    <div class="col-xl-6 mb-3">
                        <div class="form-group">
                            <label for="">Mechanic Number</label>
                            <div class="viewInpBox mt-1">{{particularExpense.mechanicNumber}}</div>
                        </div>
                    </div>
                    <div class="col-xl-12 mb-3">
                        <div class="form-group">
                            <label for="">Expense Description</label>
                            <div class="viewInpBox mt-1">{{particularExpense.expenseDescription}}</div>
                        </div>
                    </div>
                   
                </div> 
            </div>
            <div class="col-xl-6 mb-3">
                <div class="form-group">
                    <label for="">Attachment</label>
                    <div class="viewInpBox mt-1">
                        <!-- <img src="../../../../assets/images/chart.svg" class="img-fluid" alt=""> -->
                        <button class="btn btn-dark btn-sm  px-4 ms-3" type="button" (click)="preview()" mat-raised-button >Preview</button>
                            <a #showSlip [href]="slipURL" target="_blank"></a>
                    </div>
                 
                </div>
            </div>
        </div>
    </div>
</div>