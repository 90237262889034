import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormControlName, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EditLrComponent } from 'src/app/superAdmin/LR-management/edit-lr/edit-lr.component';
import { SuperAdminService } from 'src/app/super-admin.service';
import { ImagePreviewComponent } from '../image-preview/image-preview.component';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-add-invoice',
  templateUrl: './add-invoice.component.html',
  styleUrls: ['./add-invoice.component.css']
})
export class AddInvoiceComponent implements OnInit {
  filesToUpload: Array<File> = [];
  previewURL: any;
  @ViewChild('showPreview') showPreview: ElementRef;
  addInvoiceForm = new FormGroup({
    InvoiceNo: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$")]),
    InvoiceValue: new FormControl('', [Validators.required]),
    NoofArticle: new FormControl('', Validators.required),
    Weight: new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^\d+[.]{1}\d+$|^\d+$/)])),
    // Frieght: new FormControl('', [Validators.required]),
    customerName: new FormControl('', [Validators.required]),
    POD: new FormControl('', [Validators.required]),

  })
  constructor(private toastr: ToastrService,private router: Router, private superAdminService: SuperAdminService, public dialogRef: MatDialogRef<EditLrComponent>,
    @Inject(MAT_DIALOG_DATA) public data,public dialog: MatDialog, private sanitizer:DomSanitizer) { }

    ngOnInit(): void {
      
    }
    get ff() {
      return this.addInvoiceForm.controls
    }
    fileChangeEventAttachment(fileInput: any) {
      this.filesToUpload=[];
      this.filesToUpload.push(fileInput.target.files[0]);
    }
    addInvoice() {
      if (this.addInvoiceForm.valid) {
        // Update Invoice Details
        this.removevalidatorcss("InvoiceNoctrl");
        this.removevalidatorcss("InvoiceValuectrl");
        this.removevalidatorcss("NoofArticlectrl");
        this.removevalidatorcss("Weightctrl");
        // this.removevalidatorcss("Frieghtctrl");
        this.removevalidatorcss("customerNamectrl");
        //this.removevalidatorcss("PODctrl");
  
        var form = {
  
          // Update Invoice Details
  
          "InvoiceNo": this.addInvoiceForm.get('InvoiceNo').value,
          "InvoiceValue": this.addInvoiceForm.get('InvoiceValue').value,
          "NoofArticle": this.addInvoiceForm.get('NoofArticle').value,
          "Weight": this.addInvoiceForm.get('Weight').value,
          // "Frieght": this.addInvoiceForm.get('Frieght').value,
          "customerName": this.addInvoiceForm.get('customerName').value,
          //"POD": this.addInvoiceForm.get('POD').value,
          "_id": this.data.acc,
  
        }
       const formData = new FormData();
      const files: Array<File> = this.filesToUpload;
     
      for (let i = 0; i < this.filesToUpload.length; i++) {
        formData.append("uploads[]", this.filesToUpload[i]);
      }
     formData.append('myFormData', JSON.stringify(form));
        this.superAdminService.addInvoice( formData )
          .subscribe((res: { status: any, message: any, authorization: any, code: any }) => {
  
            if (res.status == true) {
              //alert(res.message)
              this.toastr.success(res.message);
              //this.editInvoiceForm.reset()
              this.data.getLrDetail();
              this.dialogRef.close();
            }
            else {
              // alert(res.message)
              if (res.status == 401) {
                this.toastr.error(res.message);
                window.sessionStorage.clear();
                this.router.navigateByUrl('superAuth/login');
                
              }else {
                this.toastr.success(res.message);
              }
              //this.addNewLR.reset()
            }
  
          }, error => {
          });
      }
      else {
        // Basic Details 
  
        if (this.addInvoiceForm.get('InvoiceNo').invalid) {
          this.addvalidatorcss("InvoiceNoctrl");
        } else {
          this.removevalidatorcss("InvoiceNoctrl");
        }
        if (this.addInvoiceForm.get('InvoiceValue').invalid) {
          this.addvalidatorcss("InvoiceValuectrl");
        } else {
          this.removevalidatorcss("InvoiceValuectrl");
        }
        if (this.addInvoiceForm.get('NoofArticle').invalid) {
          this.addvalidatorcss("NoofArticlectrl");
        } else {
          this.removevalidatorcss("NoofArticlectrl");
        }
        if (this.addInvoiceForm.get('Weight').invalid) {
          this.addvalidatorcss("Weightctrl");
        } else {
          this.removevalidatorcss("Weightctrl");
        }
        // if (this.addInvoiceForm.get('Frieght').invalid) {
        //   this.addvalidatorcss("Frieghtctrl");
        // } else {
        //   this.removevalidatorcss("Frieghtctrl");
        // }
        if (this.addInvoiceForm.get('customerName').invalid) {
          this.addvalidatorcss("customerNamectrl");
        } else {
          this.removevalidatorcss("customerNamectrl");
        }
        // if (this.addInvoiceForm.get('POD').invalid) {
        //   this.addvalidatorcss("PODctrl");
        // } else {
        //   this.removevalidatorcss("PODctrl");
        // }
      }
  
  
    }
    preview(){
      if(this.filesToUpload.length){
        let file = this.filesToUpload[0];
        if(['image/jpeg','image/png','image/svg','image/svg+xml'].includes(file.type)){
          const dialogRef = this.dialog.open(ImagePreviewComponent, {
            width:'80%',
            height:'90vh',
            data:{file:file}
        });
        }
        else{
          let url =URL.createObjectURL(file);
          this.previewURL = this.sanitizer.bypassSecurityTrustUrl(url);
          setTimeout(()=>{
            this.showPreview.nativeElement.click();
          })
        }
      }
      else{
        this.toastr.error('Please upload attachment');
      }
    }
    addvalidatorcss(id: string) {
      document.getElementById(id).classList.add('validatorcss');
    }
    removevalidatorcss(id: string) {
      document.getElementById(id).classList.remove('validatorcss');
    }

}
