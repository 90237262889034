import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { config } from 'src/app/config';
import { SuperAdminService } from 'src/app/super-admin.service';

@Component({
  selector: 'app-ourown-vehicle-expense',
  templateUrl: './ourown-vehicle-expense.component.html',
  styleUrls: ['./ourown-vehicle-expense.component.css']
})
export class OurownVehicleExpenseComponent implements OnInit {
  filesToUpload: Array<File> = [];
  superVisor;
  company;
  vehicletypes;
  addOwnVicheleMaintenanceForm = new FormGroup({
    supervisorname: new FormControl('', [Validators.required]),
    expenseCategory: new FormControl('', [Validators.required]),
    companyname: new FormControl(''),
    vehicleType: new FormControl(''),
    vehicleNumber: new FormControl('', [Validators.required]),

    expenseDate:new FormControl('', [Validators.required]),
    expenseHead: new FormControl('', [Validators.required]),
    otherCharges: new FormControl('', [Validators.required]),
    labourAmount: new FormControl('', [Validators.required]),

    disel: new FormControl('', [Validators.required]),
    expenseDescription: new FormControl('', [Validators.required]),
    totalKM: new FormControl('', [Validators.required]),
    expenseAmount: new FormControl('', [Validators.required]),
    repairExpense: new FormControl('', [Validators.required]),
    tax: new FormControl('', [Validators.required]),
    driverSalary: new FormControl('', [Validators.required]),
    monthlyEMI: new FormControl('', [Validators.required]),
    police: new FormControl('', [Validators.required]),
    extraHourCharge: new FormControl('', [Validators.required]),
    
    LRReceipt1: new FormControl('', [Validators.required]),
      
    
  })
  constructor(private activatedRoute: ActivatedRoute,private router :Router, private toastr: ToastrService, private superAdminService: SuperAdminService) {
  }
 ngOnInit(): void {
   this.getCompanyName();
   this.getSuperVisorName();
   this.getVehicleType();
 }
 getSuperVisorName(){
   this.superAdminService.getSuperVisorName()
   .subscribe((res: { status: any, message: any, data: any }) => {

     if (res.status == true) {
       var rawdata = res.data;
       this.superVisor = rawdata;
       console.log(this.superVisor);
       //this.dtOptions.data = this.users;
     }
     else {
      if (res.status == 401) {
        this.toastr.error(res.message);
        window.sessionStorage.clear();
        this.router.navigateByUrl('superAuth/login');
        
      }else {
        this.toastr.error(res.message);
      }
     }

   }, error => {
   });
 }
 getCompanyName(){
     this.superAdminService.getCompanyName()
       .subscribe((res: { status: any, message: any, data: any }) => {
 
         if (res.status == true) {
           var rawdata = res.data;
           this.company = rawdata;
           console.log(this.company);
           //this.dtOptions.data = this.users;
         }
         else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');
            
          }else {
            this.toastr.error(res.message);
          }
         }
 
       }, error => {
       });
   
 }
 getVehicleType() {
   this.superAdminService.getVehicleType()
     .subscribe((res: { status: any, message: any, data: any }) => {

       if (res.status == true) {
         var rawdata = res.data;
         this.vehicletypes = rawdata;
         //console.log(this.company);
         //this.dtOptions.data = this.users;
       }
       else {
        if (res.status == 401) {
          this.toastr.error(res.message);
          window.sessionStorage.clear();
          this.router.navigateByUrl('superAuth/login');
          
        }else {
          this.toastr.error(res.message);
        }
       }

     }, error => {
     });

 }
 fileChangeEventattachReceipt1(fileInput: any) {

   // this.filesToUpload.push(fileInput.target.files[0]);
    this.filesToUpload.push(fileInput.target.files[0] );
  }
  addOwnVicheleMaintenance() {
   console.log(this.addOwnVicheleMaintenanceForm.valid)
   if (this.addOwnVicheleMaintenanceForm.valid) {
     this.removevalidatorcss("supervisornamectrl");
     this.removevalidatorcss("expenseCategoryctrl");
     this.removevalidatorcss("companynamectrl");
     this.removevalidatorcss("vehicleTypectrl");
     this.removevalidatorcss("vehicleNumberctrl");

     this.removevalidatorcss("expenseDatectrl");
     this.removevalidatorcss("expenseHeadctrl");
     this.removevalidatorcss("otherChargesctrl");
     this.removevalidatorcss("labourAmountctrl");
     this.removevalidatorcss("expenseAmountctrl");
     this.removevalidatorcss("expenseDescriptionctrl");
     
     this.removevalidatorcss("policectrl");
     this.removevalidatorcss("taxctrl");
    
    
     

     
     
     var form = {
      
       "supervisorname": this.addOwnVicheleMaintenanceForm.get('supervisorname').value,
       "expenseCategory": this.addOwnVicheleMaintenanceForm.get('expenseCategory').value,
       "companyname": this.addOwnVicheleMaintenanceForm.get('companyname').value,
       "vehicleType": this.addOwnVicheleMaintenanceForm.get('vehicleType').value,
       "vehicleNumber": this.addOwnVicheleMaintenanceForm.get('vehicleNumber').value,

       "expenseDate": this.addOwnVicheleMaintenanceForm.get('expenseDate').value,
       "expenseHead": this.addOwnVicheleMaintenanceForm.get('expenseHead').value,
       "otherCharges": this.addOwnVicheleMaintenanceForm.get('otherCharges').value,
       "labourAmount": this.addOwnVicheleMaintenanceForm.get('labourAmount').value,
       "expenseAmount": this.addOwnVicheleMaintenanceForm.get('expenseAmount').value,
       "expenseDescription": this.addOwnVicheleMaintenanceForm.get('expenseDescription').value,
       
       
       "disel": this.addOwnVicheleMaintenanceForm.get('disel').value,
       "police": this.addOwnVicheleMaintenanceForm.get('police').value,
       "tax": this.addOwnVicheleMaintenanceForm.get('tax').value,
       "totalKM": this.addOwnVicheleMaintenanceForm.get('totalKM').value,
       "repairExpense": this.addOwnVicheleMaintenanceForm.get('repairExpense').value,
       "driverSalary": this.addOwnVicheleMaintenanceForm.get('driverSalary').value,
       "monthlyEMI": this.addOwnVicheleMaintenanceForm.get('monthlyEMI').value,
       "extraHourCharge": this.addOwnVicheleMaintenanceForm.get('extraHourCharge').value,
       
       
     }
     console.log(form);
     const formData = new FormData();
      const files: Array<File> = this.filesToUpload;
      //console.log("Files ", this.filesToUpload);
     
      for (let i = 0; i < this.filesToUpload.length; i++) {
        formData.append("uploads[]", this.filesToUpload[i]);
      }
     formData.append('myFormData', JSON.stringify(form));
    
   //console.log("F",formData);
   this.superAdminService.addOwnVicheleMaintenance(formData)
   .subscribe((res: { status: any, message: any, authorization: any, code: any }) => {
   
       if (res.status == true) {
         //alert(res.message)
         this.toastr.success(res.message);
         //this.router.navigate(['/superAdmin/manageDriver']);
         this.addOwnVicheleMaintenanceForm.reset()
       }
       else {
        if (res.status == 401) {
          this.toastr.error(res.message);
          window.sessionStorage.clear();
          this.router.navigateByUrl('superAuth/login');
          
        }else {
          this.toastr.error(res.message);
        }
         //this.addNewUser.reset()
       }
     
   }, error => {
   });
   }
   else {
     if (this.addOwnVicheleMaintenanceForm.get('supervisorname').invalid) {
       this.addvalidatorcss("supervisornamectrl");
     } else {
       this.removevalidatorcss("supervisornamectrl");
     }
   
     if (this.addOwnVicheleMaintenanceForm.get('expenseCategory').invalid) {
       this.addvalidatorcss("expenseCategoryctrl");
     } else {
       this.removevalidatorcss("expenseCategoryctrl");
     }
     if (this.addOwnVicheleMaintenanceForm.get('companyname').invalid) {
       this.addvalidatorcss("companynamectrl");
     } else {
       this.removevalidatorcss("companynamectrl");
     }
     if (this.addOwnVicheleMaintenanceForm.get('vehicleType').invalid) {
       this.addvalidatorcss("vehicleTypectrl");
     } else {
       this.removevalidatorcss("vehicleTypectrl");
     }
     if (this.addOwnVicheleMaintenanceForm.get('vehicleNumber').invalid) {
       this.addvalidatorcss("vehicleNumberctrl");
     } else {
       this.removevalidatorcss("vehicleNumberctrl");
     }
     
     if (this.addOwnVicheleMaintenanceForm.get('disel').invalid) {
       this.addvalidatorcss("diselctrl");
     } else {
       this.removevalidatorcss("diselctrl");
     } 
     if (this.addOwnVicheleMaintenanceForm.get('KM').invalid) {
       this.addvalidatorcss("KMctrl");
     } else {
       this.removevalidatorcss("KMctrl");
     }
     
     if (this.addOwnVicheleMaintenanceForm.get('expenseHead').invalid) {
       this.addvalidatorcss("expenseHeadctrl");
     } else {
       this.removevalidatorcss("expenseHeadctrl");
     }
     if (this.addOwnVicheleMaintenanceForm.get('detention').invalid) {
       this.addvalidatorcss("detentionctrl");
     } else {
       this.removevalidatorcss("detentionctrl");
     }
     if (this.addOwnVicheleMaintenanceForm.get('expenseAmount').invalid) {
       this.addvalidatorcss("expenseAmountctrl");
     } else {
       this.removevalidatorcss("expenseAmountctrl");
     }
     if (this.addOwnVicheleMaintenanceForm.get('LRReceipt1').invalid) {
       this.addvalidatorcss("LRReceipt1ctrl");
     } else {
       this.removevalidatorcss("LRReceipt1ctrl");
     }
     
   }
 }
 resetForm() {
   this.addOwnVicheleMaintenanceForm.reset();
 }
 backTo(){
   window.history.back()
 }
 addvalidatorcss(id: string) {
   document.getElementById(id).classList.add('validatorcss');
 }
 removevalidatorcss(id: string) {
   document.getElementById(id).classList.remove('validatorcss');
 }
}
