<div class="position-relative">
   <div class="d-flex align-items-center justify-content-between">
    <h4 class="mb-0">Update POD</h4>

    <button mat-dialog-close class="btn shadow-none text-dark fs-4"><i class="fa-solid fa-xmark"></i></button>


   </div>

<div class="row">
    <div class="col-xl-12 mb-4">
        <div class="form-group">
            <label for="">Front</label>
            <div class="d-flex align-items-center gap-2">
                <input type="file" style="width: 700px;" accept="image/*,.pdf,.doc" (change)="fileChangeEventtollAttachment($event)"
                    class="form-control me-1 shadow-none" id="tollAttachmentctrl" formControlName="tollAttachment" multiple />
                <button (click)="addTollFiles()" mat-raised-button class="btn btn-dark" type="submit">
                    <i class="fa-solid fa-upload"></i>
                </button>
                <!-- <button (click)="updateLRSubmit()" onclick="document.location.reload()" class="btn btn-dark" type="submit" mat-raised-button>
                    Save
                </button> -->
            </div>

        </div>
    </div>
    <div class="col-xl-12">
        <div class="form-group">
            <label for="">Back</label>
            <div class="d-flex align-items-center gap-2">
                <input type="file" style="width: 700px;" accept="image/*,.pdf,.doc" (change)="fileChangeEventloadingSlipBackAttchment($event)"
                    class="form-control me-1 shadow-none" id="tollAttachmentctrl" formControlName="tollAttachment" multiple />
                <button (click)="addloadingSlipsBack()" mat-raised-button class="btn btn-dark" type="submit">
                    <i class="fa-solid fa-upload"></i>
                </button>
                <button (click)="updateLRSubmit()" onclick="document.location.reload()" class="btn btn-dark" type="submit" mat-raised-button>
                    Save
                </button>
            </div>

        </div>
    </div>
</div>


</div>