import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MydashboardRoutingModule } from './mydashboard-routing.module';
import { MydashboardComponent } from './mydashboard.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BrowserModule } from '@angular/platform-browser';
import { ViewComponent } from './view/view.component';
import { MonthlyExpenseComponent } from './monthly-expense/monthly-expense.component';
import { CategoryExpenseComponent } from './category-expense/category-expense.component';
import { DateWiseDispenseComponent } from './date-wise-dispense/date-wise-dispense.component';
import { DateWiseCategoryComponent } from './date-wise-category/date-wise-category.component';
import { DateWiseExpenseComponent } from './date-wise-expense/date-wise-expense.component';
import { DataTablesModule } from "angular-datatables";
import { sharedModule } from 'src/app/shared/myshared.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MonthlyLrExpenseComponent } from './monthly-lr-expense/monthly-lr-expense.component';
import { DateWiseLrExpenseComponent } from './date-wise-lr-expense/date-wise-lr-expense.component';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef, MatPaginatorModule, MatTableModule } from '@angular/material';
import { TotalInvoiceComponent } from '../total-Invoice/total-invoice/total-invoice.component';
import { TotalLRComponent } from '../total-lr/total-lr/total-lr.component';
import { TotalVehicleComponent } from '../total-vehicle/total-vehicle.component';
import { NgxPrintModule } from 'ngx-print';
import { TotalInvoiceTripIDComponent } from '../total-invoice-trip-id/total-invoice-trip-id.component';
import { MonthlyTripIDComponent } from './monthly-trip-id/monthly-trip-id.component';
import { DateWiseTripIDComponent } from './date-wise-trip-id/date-wise-trip-id.component';
import { MonthlyVehicleComponent } from './monthly-vehicle/monthly-vehicle.component';
import { DateWiseVehicleComponent } from './date-wise-vehicle/date-wise-vehicle.component';
import { AmountInWordsPipe } from 'src/app/amount-in-words.pipe';
@NgModule({
  declarations: [MydashboardComponent, ViewComponent, MonthlyExpenseComponent, CategoryExpenseComponent, DateWiseDispenseComponent, DateWiseCategoryComponent, DateWiseExpenseComponent, MonthlyLrExpenseComponent, DateWiseLrExpenseComponent, TotalInvoiceComponent,TotalLRComponent,TotalVehicleComponent,TotalInvoiceTripIDComponent, MonthlyTripIDComponent, DateWiseTripIDComponent, MonthlyVehicleComponent, DateWiseVehicleComponent,AmountInWordsPipe],
  imports: [
    CommonModule,
    MydashboardRoutingModule,
    NgxChartsModule,
    DataTablesModule,
    sharedModule,
    MatTableModule,
    MatPaginatorModule,
    MatDialogModule,
    NgxPrintModule,
    NgxSpinnerModule

  ],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} }
  ],
  exports: [
    MydashboardComponent
  ]
})
export class MydashboardModule { }
