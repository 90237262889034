<div class="container-fluid">
    <div class="row manageaccount mt-3">

        <div class="col-xl-12">
            <div class="d-flex align-items-baseline justify-content-between">

                <h1 class="fs-4 fw-bold mb-0"></h1>


                <button class="btn btn-dark btn-sm " matTooltip="Back" matTooltipClass="example-tooltip-uppercase"
                    matTooltipPosition="left" mat-raised-button (click)="backTo()"><i
                        class="fa-solid fa-arrow-left"></i></button>
            </div>
            <nav class="breadcrumbMaine" aria-label="breadcrumb mb-0">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item" routerLink="/superAdmin/usermanagement">User Management</li>
                    <li class="breadcrumb-item active" aria-current="page">Add New User</li>
                </ol>
            </nav>
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-xl-12">
            <form [formGroup]="addNewUser" (ngSubmit)="addNewUserSubmit()">
                <div class="contentbox">
                    <div class="row  mb-3">
                        <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                            <div class="form-group accountsinp mb-3">
                                <label for="">Title<sup class="text-danger">*</sup></label>
                                <select formControlName="title" id="titlectrl" class="form-control shadow-none" (change)="onKeyTitle($event)">
                                    <option disabled value="null">--Select Title--</option>
                                    <option value="Mr.">Mr.</option>
                                    <option value="Mrs.">Mrs.</option>
                                    <option value="Ms.">Ms.</option>
                                    <option value="Miss.">Miss.</option>
                                </select>
                                <span class="text-danger" *ngIf="ff.title.invalid  && ff.title.errors.required && (ff.title.touched || submitClicked)">
                                    Please select Title 
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                            <div class="form-group accountsinp  mb-3">
                                <label for="">First Name<sup class="text-danger">*</sup></label>
                                <input type="text" id="firstnamectrl" class="form-control shadow-none"
                                    placeholder="Enter First Name" formControlName="firstname"
                                    (keyup)="onKeyFirstName($event)">
                                <span class="text-danger" *ngIf="!ff.firstname.value && (ff.firstname.touched || submitClicked) ">
                                    Please enter First name
                                </span>
                                <span class="text-danger" *ngIf="ff.firstname.value && ff.firstname.value.length>25 && (ff.firstname.touched || submitClicked) ">
                                    Only 25 characters are allowed
                                </span>

                            </div>
                        </div>
                        <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                            <div class="form-group accountsinp  mb-3">
                                <label for="">Last Name<sup class="text-danger">*</sup></label>
                                <input type="text" id="lastnamectrl" class="form-control shadow-none"
                                    placeholder="Enter Last Name" formControlName="lastname" (keyup)="onKeyLastName($event)">
                                <span class="text-danger" *ngIf="!ff.lastname.value && (ff.lastname.touched || submitClicked)">
                                    Please enter Last name
                                </span>
                                <span class="text-danger" *ngIf="ff.firstname.value && ff.lastname.value.length>25 && (ff.lastname.touched || submitClicked)">
                                    Only 25 characters are allowed
                                </span>

                            </div>
                        </div>


                        <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                            <div class="form-group accountsinp  mb-3">
                                <label for="">User email<sup class="text-danger">*</sup></label>
                                <input type="email" id="emailctrl" class="form-control shadow-none"
                                    placeholder="Enter Email" formControlName="email"
                                    (keyup)="onKeyEmail($event)">
                                <span *ngIf="ff.email && ff.email.invalid && (ff.email.touched || submitClicked) " style="color
                                :red"> Please enter Email address

                                </span>
                            </div>
                        </div>
                        <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                            <div class="form-group accountsinp  mb-3">
                                <label for="">Password<sup class="text-danger">*</sup></label>
                                <div class="d-flex align-items-center">
                                    <div class="position-relative w-100">
                                        <input [type]="changetype1?'password':'text'" class="form-control pe-5 me-1 shadow-none"
                                            id="passwordctrl" placeholder="Enter Password" formControlName="password" (keyup)="onKeyPass($event)">
                                        <span
                                            class="eyeicon position-absolute encCUs top-0 h-auto btn" (click)="viewpass1()"
                                            > &nbsp;<i
                                                [ngClass]="visible1?'fa fa-eye':'fa fa-eye-slash'"></i>
                                        </span>
                                    </div>
                                    
                                    <div class="tooltipboxpass ms-2">
                                        <i class="fa fa-info-circle iconcolor"></i>
                                        <div class="toolboxinn">
                                            <div class="innbox2">
                                                <p>Password length must be a minimum of 8 digits including one
                                                    capital letter, one small letter, one number, and one
                                                    special character.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span class="text-danger" *ngIf="ff.password.invalid && (ff.password.touched || submitClicked)">
                                    Please enter Password
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                            <div class="form-group accountsinp  mb-3">
                                <label for="">User Mobile<sup class="text-danger">*</sup></label>
                                <input type="text" id="mobilectrl" class="form-control shadow-none"
                                    placeholder="Enter Phone" formControlName="mobile"  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                    (keyup)="onKeyMobile($event)">
                                <span class="text-danger" *ngIf="!ff.mobile.value && (ff.mobile.touched || submitClicked)">
                                    Please enter user's Mobile number
                                </span>
                                <span class="text-danger" *ngIf="ff.mobile.value && ff.mobile.value.length!=10 && (ff.mobile.touched || submitClicked)">
                                    Only 10 digits are allowed
                                </span>
                            </div>
                        </div>
                        <!-- <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                            <div class="form-group accountsinp  mb-3">
                                <label for="">Client Name</label>

                                <select id="clientListctrl" formControlName="clientList"
                                    placeholder="Select Client Name" class="form-control shadow-none" multiple>
                                    <option disabled value="null">--Select Client Name--</option>
                                    <option *ngFor="let cl of clientList" value="{{cl?.clientEmail}}">
                                        {{cl?.clientName}}</option>
                                </select>

                            </div>
                        </div> -->
                        <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                            <!-- <form [formGroup]="form"> -->
                                <label for="">Company Name<sup class="text-danger">*</sup></label>
                            <div class="form-group accountsinp  mb-3" >
                                <div id="clientListCtrl"> 
                                    <ng-multiselect-dropdown  formControlName="clientList" [placeholder]="'Company Name'"
                                        [settings]="dropdownSettings" [data]="dropdownList" 
                                        (onSelect)="onItemSelect($event)" 
                                        (onDeSelect)="onItemDeselect($event)"
                                        (onDeSelectAll)="onItemDeselectAll($event)"
                                        (onSelectAll)="onItemSelectAll($event)">
                                    </ng-multiselect-dropdown>
                                </div>
                            <span class="text-danger" *ngIf="ff.clientList.invalid && (ff.clientList.touched || submitClicked) ">
                                Please select the Company Name
                            </span>
                            </div>
                        <!-- </form> -->
                        <!-- <div class="action">
                                <button (click)="handleButtonClick()">Press button</button>
                            </div> -->

                    </div>



                    <!-- #### sir  -->
                    <!-- ##### in error message Please select the Client names -->
                    <!-- <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                            <div class="form-group accountsinp  mb-3">
                                <label for="">Client Name<sup class="text-danger">*</sup></label>
                                <div class="customeMultiSelect form-control p-0">
                                    <div class="position-relative ">
                                        <input type="text" readonly placeholder="--Select Client Name--"
                                            class=" openMultiSelect form-control border-0 form-select  shadow-none"
                                            name="" id="clientListctrl" formControlName="clientList" >
                                        <button type="button"
                                            class="btn position-absolute end-0 top-0 bottom-0 h-auto ">
                                            <img src="../../../../assets/images/icons/userdrop.svg" alt="">
                                        </button>
                                    </div>
                                    <div class="multiSelectDrop"  click="clickAnywaredropDownClose()">
                                        <input type="text" class="form-control mb-2" placeholder="Search" name="" id="" class="searchRes.active">
                                        <ul>
                                            <li *ngFor="let cl of clientList; let i = index">
                                                <input type="checkbox" value="{{cl?.clientName}}" name=""
                                                    id="multiselct{{i}}" hidden select   >
                                                <label for="multiselct{{i}}"
                                                    click="getclientLists()" >{{cl?.clientName}}</label>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    <!-- <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                            <div class="form-group accountsinp  mb-3">
                                <label for="">multiselct<sup class="text-danger">*</sup></label>
                                <div class="customeMultiSelect form-control p-0"> -->
                    <!-- <div class="position-relative ">
                                        <input type="text" readonly placeholder="--Select Client Name--"
                                            class=" openMultiSelect form-control border-0 form-select  shadow-none"
                                            name="" id="clientListctrl" formControlName="clientList">
                                        <button type="button"
                                            class="btn position-absolute end-0 top-0 bottom-0 h-auto ">
                                            <img src="../../../../assets/images/icons/userdrop.svg" alt="">
                                        </button>
                                    </div> -->
                    <!-- <div class="multiSelectDrop" click="clickAnywaredropDownClose()">
                                        <input type="text" class="form-control mb-2" placeholder="Search" name="" id=""
                                            class="searchRes.active">
                                        <ul>
                                            <li *ngFor="let cl of clientList; let i = index">
                                                <input type="checkbox" value="{{cl?.clientName}}" name=""
                                                    id="multiselct{{i}}" hidden select>
                                                <label for="multiselct{{i}}"
                                                    click="getclientLists()">{{cl?.clientName}}</label>
                                            </li>
                                        </ul>
                                    </div> -->

                    <!-- </div>
                            </div>
                        </div> -->


                    <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">
                            <label for="">Status<sup class="text-danger">*</sup></label>
                            <select id="statusctrl" formControlName="status" placeholder="Select Status" name=""
                                class="form-control shadow-none" (change)="onKeyStatus($event)">
                                <option disabled value="null">--Select Status--</option>
                                <option value="true">Active</option>
                                <option value="false">Inactive</option>
                            </select>
                            <span class="text-danger" *ngIf="ff.status.invalid && (ff.status.touched || submitClicked) ">
                                Please select the Status of the User's account
                            </span>
                        </div>
                    </div>
                    <div class="col-md-12 text-end">
                        <button class="btn btn-outline-dark" type="button" (click)="resetForm()"
                            mat-raised-button>Reset</button>
                        <button class="btn btn-dark  ms-2" type="submit" mat-raised-button>Add
                            Now</button>
                    </div>
                </div>
        </div>
        </form>
    </div>
</div>

</div>
<!-- <div class="drop-container">
    <form [formGroup]="form">
        <h1>multiSelectDrop</h1>
        <ng-multiselect-dropdown formControlName="grocery" [placeholder]="'Fruits/Vegetables'"
            [settings]="dropdownSettings" [data]="dropdownList" (onSelect)="onItemSelect($event)">
        </ng-multiselect-dropdown>
    </form>
    <div class="action">
        <button (click)="handleButtonClick()">Press button</button>
    </div>

</div> -->