<div class="container-fluid">
    <div class="row manageaccount mt-3">
        <div class="col-md-12 my-auto    ">
            <div class="d-flex align-items-baseline justify-content-between">
                <h1 class="fs-4 fw-bold mb-0">Add Our Own Vehicle Expense </h1>
                <button class="btn btn-dark btn-sm " mat-raised-button matTooltip="Back" matTooltipPosition="left" (click)="backTo()"><i
                        class="fa-solid fa-arrow-left"></i></button>
            </div>
            <nav class="breadcrumbMaine" aria-label="breadcrumb mb-0">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item">Miscellaneous expenses</li>
                    <li class="breadcrumb-item" (click)="backTo()">Our Own Vehicle Expense</li>

                    <li class="breadcrumb-item active" aria-current="page">Add Our Own Vehicle Expense </li>

                </ol>
            </nav>
        </div>

    </div>
    <div class="row mt-3">
        <div class="col-xl-12">
            <form [formGroup]="addOwnVicheleMaintenanceForm" (ngSubmit)="addOwnVicheleMaintenance()"
                enctype="multipart/form-data">
                <div class="contentbox">
                <div class="row account mb-3">

                    <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">
                            <label for="">Supervisor Name</label>
                            <select id="supervisornamectrl" formControlName="supervisorname" placeholder="Select Status"
                                class="form-control shadow-none">
                                <option disabled value="">--Select Supervisor Name--</option>
                                <option *ngFor="let s of superVisor" value="{{s.full_name}}">{{s.full_name}}</option>

                            </select>

                        </div>
                    </div>
                    <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Expense Category</label>
                            <select id="expenseCategoryctrl" formControlName="expenseCategory"
                                class="form-control shadow-none">
                                <option disabled value="">--Select Expense Category--</option>
                                <option value="Ourown Vehicle Expense">Ourown Vehicle Expense</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">
                            <label for="">Company Name</label>
                            <select id="companynamectrl" formControlName="companyname" class="form-control shadow-none">
                                <option disabled value="">--Select Company Name--</option>
                                <option *ngFor="let c of company" value="{{c.company_name}}">{{c.company_name}}</option>

                            </select>
                        </div>
                    </div>
                    <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">
                            <label for="">Vehicle Type</label>
                            <select id="vehicleTypectrl" formControlName="vehicleType" placeholder="Select Vehicle Type"
                                class="form-control shadow-none">
                                <option disabled value="">--Select Vehicle Type--</option>
                                <option *ngFor="let c of vehicletypes" value="{{c.vehicletype_name}}">
                                    {{c.vehicletype_name}}</option>

                            </select>

                        </div>
                    </div>
                    <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Vehicle Number</label>
                            <input type="text" id="vehicleNumberctrl" class="form-control shadow-none"
                                placeholder="Enter Vehicle Number" formControlName="vehicleNumber">
                        </div>
                    </div>
                    <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Expense Date</label>
                            <input type="date" id="expenseDatectrl" class="form-control shadow-none"
                                placeholder="Enter Rate " formControlName="expenseDate">
                        </div>
                    </div>

                    <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Expense Head</label>
                            <input type="text" id="expenseHeadctrl" class="form-control shadow-none"
                                placeholder="Enter Expense Head " formControlName="expenseHead">
                        </div>
                    </div>
                    <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Other Charges</label>
                            <input type="text" id="otherChargesctrl" class="form-control shadow-none"
                                placeholder="Enter Other Charges" formControlName="otherCharges">
                        </div>
                    </div>
                    <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Labour Amount</label>
                            <input type="text" id="labourAmountctrl" class="form-control shadow-none"
                                placeholder="Enter Labour Amount " formControlName="labourAmount">
                        </div>
                    </div>
                    <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Expense Amount</label>
                            <input type="number" id="expenseAmountctrl" class="form-control shadow-none"
                                placeholder="Enter Expense Amount " formControlName="expenseAmount">
                        </div>
                    </div>
                    <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Expense Description</label>
                            <input type="text" id="expenseDescriptionctrl" class="form-control shadow-none"
                                placeholder="Enter Expense Description " formControlName="expenseDescription">
                        </div>
                    </div>


                    <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Total KM</label>
                            <input type="text" id="totalKMctrl" class="form-control shadow-none"
                                placeholder="Enter Total KM " formControlName="totalKM">
                        </div>
                    </div>

                    <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Disel</label>
                            <input type="text" id="diselctrl" class="form-control shadow-none"
                                placeholder="Enter  Disel " formControlName="disel">
                        </div>
                    </div>

                    <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Tax</label>
                            <input type="text" id="taxctrl" class="form-control shadow-none"
                                placeholder="Enter Tax" formControlName="tax">
                        </div>
                    </div>

                    <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Repair Expense</label>
                            <input type="text" id="repairExpensectrl" class="form-control shadow-none"
                                placeholder="Enter Repair Expense" formControlName="repairExpense">
                        </div>
                    </div>
                    <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Driver Salary</label>
                            <input type="text" id="driverSalaryctrl" class="form-control shadow-none"
                                placeholder="Enter Driver Salary " formControlName="driverSalary">
                        </div>
                    </div>

                    <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Monthly EMI</label>
                            <input type="text" id="monthlyEMIctrl" class="form-control shadow-none"
                                placeholder="Enter Monthly EMI " formControlName="monthlyEMI">
                        </div>
                    </div>
                    <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Police</label>
                            <input type="text" id="policectrl" class="form-control shadow-none"
                                placeholder="Enter Police" formControlName="police">
                        </div>
                    </div>
                    <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Extra Hour Charge</label>
                            <input type="text" id="extraHourChargectrl" class="form-control shadow-none"
                                placeholder="Enter Extra Hour Charge" formControlName="extraHourCharge">
                        </div>
                    </div>

                    <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
                        <div class="form-group accountsinp  mb-3">
                           <label for="">LR Receipt1</label>

                            <input type="file" accept="image/*" (change)="fileChangeEventattachReceipt1($event)"
                                id="LRReceipt1ctrl" class="form-control shadow-none" formControlName="LRReceipt1">

                        </div>

                    </div>

                    <div class="col-md-12 text-end">
                        <button class="btn btn-outline-dark" type="button" (click)="resetForm()"
                        mat-raised-button>Reset</button>
                        <button class="btn btn-dark ms-2" type="submit" mat-raised-button>Save
                        </button>
                    
                    </div>
                </div>
                </div>
            </form>
        </div>
    </div>
</div>