<div class="container-fluid">
    <div class="row justify-content-between manageaccount mt-3">
        <div class="col-xl-12">
            <div class="d-flex align-items-center justify-content-between">
                <h1 class="fs-4 fw-bold">User Management</h1>
                <button class="btn btn-dark btn-sm "  matTooltip="Add User" matTooltipPosition="left" mat-raised-button routerLink="/superAdmin/usermanagement/add-user"><i
                        class="fa-solid fa-plus"></i></button>
            </div>
        </div>

    </div>
    <div class="contentbox mt-3">
    <div class="row">
      <div class="col-xl-3 ms-auto">
        <input class='search-box form-control' #data placeholder="Search"  type="text" />
      </div>
        <div class="col-md-12 table-responsive ">
          


            <table mat-table [dataSource]="dataSource" matSort matSortActive="full_name" matSortDirection="desc" (matSortChange)="sortData($event)" class="row-border hover tablelist" >
                        
                <ng-container matColumnDef="full_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                    <td mat-cell *matCellDef="let user; let i = index"> {{user.full_name}} </td>
                  </ng-container>

                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
                    <td mat-cell *matCellDef="let user"> {{user.email}} </td>
                  </ng-container>

                <ng-container matColumnDef="isactive">
                  <th mat-header-cell *matHeaderCellDef>Status</th>
                  <td mat-cell *matCellDef="let user">
                    <div *ngIf="user.isactive == true">Active</div>
                    <div *ngIf="user.isactive == false">Inactive</div>
                  </td>
                </ng-container>
                
                  <ng-container matColumnDef="action" >
                    <th mat-header-cell *matHeaderCellDef class="text-center"> Action </th>
                    <td mat-cell *matCellDef="let user">
                        <div class="d-flex justify-content-center align-items-center gap-3">
                            <button  class="btn btn-block p-0   shadow-none"  matTooltip="View" (click)="emitdata(user)" routerLink="/superAdmin/usermanagement/view" >  <i class="fa-solid fa-eye"></i></button>
                            <button  matTooltip="Edit" class="btn btn-block p-0 shadow-none" (click)="emitdata(user)" routerLink="/superAdmin/usermanagement/edit-user" >
                            <i  class="fa-solid fa-user-pen mx-2"></i>
                            </button>
                            <button   class="btn btn-block p-0  shadow-none" (click)="deleteuser(user.email)">
                            <i  matTooltip="Delete" class="fa fa-trash"
                                aria-hidden="true"></i>
                            </button>
                        </div>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                
                  
            </table>
        </div>
        <mat-paginator #paginator [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
        </mat-paginator>
        <!-- <div class="col-xl-12">
        <mat-paginator [length]="100"
        [pageSize]="10"
        [pageSizeOptions]="[5, 10, 25, 100]">
        </mat-paginator>
       </div> -->
    </div>
    </div>
</div>
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>