import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, NgModel } from '@angular/forms';
import { config } from 'src/app/config';
import { Router } from '@angular/router';
import { SuperAdminService } from 'src/app/super-admin.service';
import { ToastrService } from 'ngx-toastr';
import { async } from '@angular/core/testing';
@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {
  clientList = [];
  clientName = []
  dropdownSettings: {}
  actualData = [];
  clientListDropDown = []
  selectedItems = []
  extractFromActualData = [];
  updateUser = new FormGroup({
    title: new FormControl('', Validators.required),
    // companyName: new FormControl('', Validators.required),
    firstname: new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^.{0,25}$/)])),
    email: new FormControl('', [Validators.required, Validators.pattern(config.emailregExp)]),
    // password: new FormControl('', [Validators.required, Validators.pattern(config.passwordregExp)]),
    lastname: new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^.{0,25}$/)])),
    mobile: new FormControl('', [Validators.required, Validators.pattern(/^\d{10}$/)]),
    clientList: new FormControl('', Validators.required),
    status: new FormControl('', Validators.required)
  })
  submitClicked = false;
  userdata: any;
  get ff() {
    return this.updateUser.controls
  }
  constructor(private router: Router, private toastr: ToastrService, private superAdminService: SuperAdminService) { }

  ngOnInit(): void {
    this.getClientList();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true
    };
  }
  getClientList() {
    this.superAdminService.getClientList()
      .subscribe((res: { status: any, message: any, data: any }) => {
        if (res.status == true) {
          this.clientList = res.data;
          let temp = [];
          this.clientList.forEach((x) => {
            temp.push({ item_id: x._id, item_text: x.clientName });
          })
          this.clientListDropDown = temp;
          const userdatastringified = sessionStorage.getItem("userdata");
          this.userdata = JSON.parse(userdatastringified);
          temp = [];
          this.userdata.clientList.map((x) => {
            this.clientList.filter(y => {
              if (y._id == x) temp.push(y.clientName)
            })
          })
          this.clientName = temp;
          temp = [];
          this.clientName.map((x) => {
            this.clientListDropDown.filter(y => {
              if (y.item_text == x) {
                temp.push(y);
              }
            })
          })
          this.selectedItems = temp;
          this.updateUser.patchValue({
            title: this.userdata.title,
            firstname: this.userdata.first_name,
            lastname: this.userdata.last_name,
            email: this.userdata.email,
            mobile: this.userdata.mobile,
            status: this.userdata.isactive,
            clientList: this.selectedItems
          })
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');

          } else {
            this.toastr.error(res.message);
          }
        }

      }, error => {
      });
  }
  onItemSelect($event: any) {
    this.selectedItems.push({item_id:$event.item_id, item_text:$event.item_text})
    this.onKeyClient();
  }
  onItemDeselect($event: any) {
    let clientList = [];
    this.selectedItems.forEach((item)=>{
      if(item.item_id!=$event.item_id){
        clientList.push(item);
      }
    })
    this.selectedItems = clientList
    // console.log("item seleetd client list", this.selectedItems);
    this.onKeyClient();
  }

  onItemDeselectAll($event: any) {
    this.selectedItems = [];
    this.onKeyClient();
    this.addvalidatorcss("clientListCtrl");
  }

  onItemSelectAll($event: any) {
    this.selectedItems = this.clientListDropDown;
    this.onKeyClient();
    this.removevalidatorcss("clientListCtrl");
  }

  updateUserSubmit() {
    this.submitClicked = true;
    if (this.updateUser.valid) {
      this.extractFromActualData = []
      this.actualData = this.updateUser.value.clientList.map(item => item['item_text'])
      this.extractFromActualData = this.actualData.map((x) => {
        let client =  this.clientList.filter(y => {
          if (y.clientName == x) return true
        })
        return client[0].clientEmail;
      })
      this.removevalidatorcss("titlectrl");
      this.removevalidatorcss("firstnamectrl");
      this.removevalidatorcss("statusctrl");
      this.removevalidatorcss("emailctrl");
      this.removevalidatorcss("lastnamectrl");
      this.removevalidatorcss("mobilectrl");
      this.removevalidatorcss("clientListCtrl");

      let clientIds = this.selectedItems.map((item)=> item.item_id);
      var form = {
        "title": this.updateUser.get('title').value,
        "firstname": this.updateUser.get('firstname').value,
        "email": this.updateUser.get('email').value,
        "lastname": this.updateUser.get('lastname').value,
        "mobile": this.updateUser.get('mobile').value,
        "status": this.updateUser.get('status').value,
        // "clientList": this.updateUser.get('clientList').value,
        "clientList": clientIds,
      }
      
      this.superAdminService.updateuser({ form })
        .subscribe((res: { status: any, message: any, authorization: any, code: any }) => {

          if (res.status == true) {
            this.toastr.success(res.message);
            // alert(res.message)
            this.userdata.title = this.updateUser.get('title').value;
            this.userdata.first_name = this.updateUser.get('firstname').value
            this.userdata.last_name = this.updateUser.get('lastname').value
            this.userdata.email = this.updateUser.get('email').value
            this.userdata.mobile = this.updateUser.get('mobile').value
            this.userdata.status = this.updateUser.get('status').value
            this.userdata.clientList = clientIds
            window.sessionStorage.setItem('userdata', JSON.stringify(this.userdata));
            this.router.navigate(['/superAdmin/usermanagement']);
            this.updateUser.reset()
          }
          else {
            //alert(res.message)
            if (res.status == 401) {
              this.toastr.error(res.message);
              window.sessionStorage.clear();
              this.router.navigateByUrl('superAuth/login');

            } else {
              this.toastr.error(res.message);
            }
            this.updateUser.reset()
          }

        }, error => {
        });
    }
    else {
      if (this.updateUser.get('title').invalid) {
        this.addvalidatorcss("titlectrl");
      } else {
        this.removevalidatorcss("titlectrl");
      }

      if (this.updateUser.get('firstname').invalid) {
        this.addvalidatorcss("firstnamectrl");
      } else {
        this.removevalidatorcss("firstnamectrl");
      }
      if (this.updateUser.get('email').invalid) {
        this.addvalidatorcss("emailctrl");
      } else {
        this.removevalidatorcss("emailctrl");
      }
      if (this.updateUser.get('lastname').invalid) {
        this.addvalidatorcss("lastnamectrl");
      } else {
        this.removevalidatorcss("lastnamectrl");
      }
      if (this.updateUser.get('mobile').invalid) {
        this.addvalidatorcss("mobilectrl");
      } else {
        this.removevalidatorcss("mobilectrl");
      }
      if (this.updateUser.get('status').invalid) {
        this.addvalidatorcss("statusctrl");
      } else {
        this.removevalidatorcss("statusctrl");
      }
      if (this.updateUser.get('clientList').invalid) {
        this.addvalidatorcss("clientListCtrl");
      } else {
        this.removevalidatorcss("clientListCtrl");
      }
    }
  }
  resetForm() {
    this.updateUser.reset();
    this.ngOnInit();
    this.removevalidatorcss("titlectrl");
    this.removevalidatorcss("firstnamectrl");
    this.removevalidatorcss("statusctrl");
    this.removevalidatorcss("emailctrl");
    this.removevalidatorcss("lastnamectrl");
    this.removevalidatorcss("mobilectrl");
    this.removevalidatorcss("clientListCtrl");
  }
  addvalidatorcss(id: string) {
    document.getElementById(id).classList.add('validatorcss');
  }
  removevalidatorcss(id: string) {
    document.getElementById(id).classList.remove('validatorcss');
  }
  onKeyTitle(event: any) {
    if (this.updateUser.get('title').invalid) {
      this.addvalidatorcss("titlectrl");
    } else {
      this.removevalidatorcss("titlectrl");
    }
  }
  onKeyFirstName(event: any) {
    if (this.updateUser.get('firstname').invalid) {
      this.addvalidatorcss("firstnamectrl");
    } else {
      this.removevalidatorcss("firstnamectrl");
    }
  }
  onKeyEmail(event: any) {
    if (this.updateUser.get('email').invalid) {
      this.addvalidatorcss("emailctrl");
    } else {
      this.removevalidatorcss("emailctrl");
    }
  }
  onKeyLastName(event: any) {
    if (this.updateUser.get('lastname').invalid) {
      this.addvalidatorcss("lastnamectrl");
    } else {
      this.removevalidatorcss("lastnamectrl");
    }
  }
  onKeyMobile(event: any) {
    if (this.updateUser.get('mobile').invalid) {
      this.addvalidatorcss("mobilectrl");
    } else {
      this.removevalidatorcss("mobilectrl");
    }
  }
  onKeyStatus(event: any) {
    if (this.updateUser.get('status').invalid) {
      this.addvalidatorcss("statusctrl");
    } else {
      this.removevalidatorcss("statusctrl");
    }
  }
  
  onKeyClient() {
    setTimeout(()=>{
      if (this.updateUser.get('clientList').invalid) {
        this.addvalidatorcss("clientListCtrl");
      } else {
        this.removevalidatorcss("clientListCtrl");
      }
    })
  }
  backTo() {
    window.history.back()
  }
}
