import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute,Router } from '@angular/router';
import { MatPaginator } from '@angular/material';
import { SuperAdminService } from '../../../super-admin.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-gst-invoice',
  templateUrl: './gst-invoice.component.html',
  styleUrls: ['./gst-invoice.component.css']
})
export class GstInvoiceComponent implements OnInit {
  invtot:any;
  users: any;
  fivePercent:any;
  eightPercent :any;
  totalInvValueWithGST:any;
  paramId;
  constructor(private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private superAdminService: SuperAdminService) {
    this.activatedRoute.params.subscribe(param => {
      this.paramId = param.id;
      this.getParticularInvoice(this.paramId);
    })
  }

  ngOnInit(): void {
  }
  getParticularInvoice(id: string) {
    this.superAdminService.getParticularInvoice(id)
      .subscribe((res: { status: any, message: any, data: any }) => {

        if (res.status == true) {
          var rawdata = res.data;
          this.users = rawdata[0].invoice;
          //this.users = this.users[0].invoice;
          this.users.forEach( (value:any)=> {
            let balancepaidArray = value.vehicleRate;
            this.invtot =  balancepaidArray.reduce((a,b)=> parseInt(a)+ parseInt(b),0)
          }); 
          this.fivePercent = (this.invtot*5)/100;
          this.eightPercent = (this.invtot*8)/100;
          this.totalInvValueWithGST = this.invtot + 2*(this.fivePercent) + this.eightPercent;
        }
        else {
          alert(res.message)
        }

      }, error => {
      });
  }
  backTo() {
    window.history.back()
  }

}
