import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MatPaginator, MatSort, MatTableDataSource, Sort } from '@angular/material';
import { SuperAdminService } from '../../../super-admin.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-vechile-list',
  templateUrl: './vechile-list.component.html',
  styleUrls: ['./vechile-list.component.css']
})
export class VechileListComponent implements OnInit {

  users: any=[];
  @ViewChild('paginator') paginator: MatPaginator;
  dataSource:  MatTableDataSource<any>;
  displayedColumns: any = ['vehicle_no', 'type', 'ownerName', 'policyNumber', 'roadTaxDate', 'insuranceDueDate', 'fitnessDueDate', 'action'];
  @ViewChild(MatSort) sort: MatSort;
  constructor(private router: Router, private toastr: ToastrService, private superAdminService: SuperAdminService, private spinner:NgxSpinnerService) { }


  ngOnInit(): void {
    this.getUsers();
  }
  getUsers() {
    this.spinner.show();
    this.superAdminService.getVehicle()
      .subscribe((res: { status: any, message: any, data: any }) => {

        if (res.status == true) {
          this.spinner.hide();
          var rawdata = res.data;
          this.users = rawdata;
          //this.dtOptions.data = this.users;
          console.log(this.users)
          this.dataSource = new MatTableDataSource(this.users);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            this.spinner.hide();
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');

          } else {
            this.toastr.error(res.message);
            this.spinner.hide();
          }
        }

      }, error => {
        this.spinner.hide();
        this.toastr.error('Something went wrong! Please contact us');
      });
  }
  // emitdata(params) {
  //   sessionStorage.setItem("userdata", JSON.stringify(params));
  // }

  deleteuser(_id) {
    if (this.superAdminService.deleteDailogBox()) {
      this.superAdminService.deleteVehicle(_id)
        .subscribe((res: { status: any, message: any, authorization: any, code: any }) => {

          if (res.status == true) {
            this.toastr.success(res.message);
            //this.router.navigateByUrl('/user/userManagement');
            this.getUsers();
          }
          else {
            if (res.status == 401) {
              this.toastr.error(res.message);
              window.sessionStorage.clear();
              this.router.navigateByUrl('superAuth/login');

            } else {
              this.toastr.error(res.message);
            }
          }

        }, error => {
        });
    }
  }
  filterData(value){
    this.dataSource.filter = value;
  }
  sortData(sort: Sort) {
    const data = this.dataSource.filteredData;
    if (!sort.active || sort.direction === '') {
      return this.dataSource;
    }
    this.dataSource.filteredData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'ownerName':
          return compare(lower(a.ownerName), lower(b.ownerName), isAsc);
        case 'roadTaxDate':
          return compare(a.roadTaxDate, b.roadTaxDate, isAsc);
        case 'insuranceDueDate':
          return compare(a.insuranceDueDate, b.insuranceDueDate, isAsc);
        case 'CGSTNumber':
          return compare(a.fitnessDueDate, b.fitnessDueDate, isAsc);
        default:
          return 0;
      }
    });
    function compare(a: number | string , b: number | string, isAsc: boolean) {
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
    function lower(value){
      return value?value.toLowerCase():value;
    }
  }
}
