<div class="container-fluid">
    <div class="row justify-content-between manageaccount mt-3">
        <div class="col-xl-12">
            <div class="d-flex align-items-center justify-content-between">
                <h1 class="fs-4 fw-bold">Date Wise Cash Dispense Report : {{this.month}} {{year}}</h1>
                <button class="btn btn-dark btn-sm " mat-raised-button matTooltip="Back" matTooltipPosition="left"
                    (click)="backTo()"><i class="fa-solid fa-arrow-left"></i></button>
            </div>
        </div>

    </div>
    <div class="contentbox mt-3">
        <div class="row customepadding">
            <div class="col-md-12 table-responsive ">
                <table datatable class="row-border hover tablelist" *ngIf='dispenseArrayList.length > 0'>
                    <thead>
                        <tr>
                            <td>Date</td>
                            <td>Supervisor Name</td>
                            <td>Company Name</td>
                            <td>Description</td>
                            <td>Total Amount</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let user1 of dispenseArrayList">
                            <td>{{user1.date}}</td>
                            <td>{{user1.SupervisorName}}</td>
                            <td>{{user1.companyName}}</td>
                            <td matTooltip="{{user1.description.length>30?user1.description:''}}">{{user1.description.length>30?user1.description.slice(0,30)+'...':user1.description}}</td>
                            <td>{{user1.amount}}</td>
                        </tr>     
                    </tbody>
                </table>      
            </div>
        </div>
    </div>
</div>