<div class="container-fluid">
    <div class="row justify-content-between manageaccount mt-3">
        <div class="col-xl-12 col-12 col-sm-12 col-md-12 my-auto">
            <div class="d-flex align-items-center justify-content-between">
                <h1 class="fs-4 fw-bold">Vehicle Maintenance  </h1>

                <button class="btn btn-dark btn-sm" matTooltip="Add Maintenance" matTooltipPosition="left" mat-raised-button routerLink="../AddvehicleMaintenence"><i class="fa-solid fa-plus"></i></button>
            </div>

        </div>
    </div>
    <div class="contentbox mt-3">
        <div class="row customepadding">
          <div class="col-xl-3 ms-auto">
            <input class='search-box form-control' #data placeholder="search"type="text" (keyup)="filterData(data.value)"/>   
          </div>
            <div class="col-md-12 table-responsive ">

                
                <table mat-table [dataSource]="dataSource" matSort matSortActive="expenseDate" matSortDirection="desc" (matSortChange)="sortData($event)" class="row-border hover tablelist" >
                    <ng-container matColumnDef="SupervisorName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Supervisor Name </th>
                        <td mat-cell *matCellDef="let user; let i = index"> {{user.SupervisorName}} </td>
                      </ng-container>

                    <ng-container matColumnDef="expenseSubCategory">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Expense Category </th>
                        <td mat-cell *matCellDef="let user"> {{user.expenseSubCategory}}</td>
                      </ng-container>
                      <!-- {{user.expenseSubCategory!='null'?user.expenseSubCategory:''}} -->
                    <ng-container matColumnDef="companyName">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Company Name </th>
                      <td mat-cell *matCellDef="let user"> {{user.companyName}} </td>
                    </ng-container>
                    
                      <ng-container matColumnDef="totalExpenseAmount">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Expense Amount </th>
                        <td mat-cell *matCellDef="let user"> {{user.totalExpenseAmount}} </td>
                      </ng-container>

                      <ng-container matColumnDef="expenseDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Expense Date </th>
                        <td mat-cell *matCellDef="let user"> {{user.expenseDate | date:'yyyy-MM-dd'}} </td>
                      </ng-container>

                      <ng-container matColumnDef="expenseDescription">
                        <th mat-header-cell *matHeaderCellDef > Expense Description </th>
                        <td mat-cell *matCellDef="let user" matTooltip="{{user.expenseDescription.length>30?user.expenseDescription:''}}"> 
                          {{user.expenseDescription.length>30?user.expenseDescription.slice(0,30)+'...':user.expenseDescription}} 
                        </td>
                      </ng-container>
                    
                      <ng-container matColumnDef="action" >
                        <th mat-header-cell *matHeaderCellDef class="text-center"> Action </th>
                        <td mat-cell *matCellDef="let user">
                            <div class="d-flex justify-content-center align-items-center gap-3">
                                <button   class="btn btn-block p-0  shadow-none" [routerLink]='["/superAdmin/vehicleMaintenence/viewMaintaince", user._id]'> <i class="fa-solid fa-eye"></i></button>
                                <button   class="btn btn-block p-0  shadow-none" (click)="deleteExpense(user._id)"><i  matTooltip="Delete" class="fa fa-trash" aria-hidden="true"></i></button>
                                <button [routerLink]="['/superAdmin/vehicleMaintenence/editVehicleMaintenance', user._id]"
                                        class="btn btn-block p-0   shadow-none"><i matTooltip="Edit"
                                            class="fas fa-pen"></i></button>
                            </div>
                        </td>
                      </ng-container>
                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    
                </table>
            </div>
            <mat-paginator #paginator [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
            </mat-paginator>
        </div>
    </div>
</div>
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>