<div class="container-fluid" >
    <div class="row justify-content-between manageaccount mt-3">
        <div class="col-xl-12">
            <div class="d-flex align-items-center justify-content-between">
                <h1 class="fs-3 fw-bold">Dashboard</h1>

            </div>
        </div>

    </div>
    <div  class="row justify-content-between mt-3 mb-3">
        <div class="col-xl-4 my-auto">
            <h3  class="fs-5 mb-0">Hello {{userSessionData.first_name}}, Welcome to Optimly!</h3>
        </div>
        <div class="col-xl-1">
        <select #yearFilter placeholder="Select Year" class="form-control shadow-none" (change)="changeYear(); changeYearExpense();changeYearDispense(); changeYearExpenseCategoryWise(); changeYearLrExpense(); changeSummary()">
            <option *ngFor="let year of years" value="{{year}}">{{year}}</option>
        </select>
        </div>

    </div>
    
    <div class="row mt-3">

        <div class="col-xl-12">
            <div class="row">
                <div class="col-xl-12 " hidden>
                    <ul class="contentbox lrcontent">
                        <li>
                            <h2>25</h2>
                            <h1>Total LR</h1>
                        </li>
                        <li>
                            <h2>25</h2>
                            <h1>Total LR</h1>
                        </li>
                        <li>
                            <h2>25</h2>
                            <h1>Total LR</h1>
                        </li>
                    </ul>
                </div>

                <div class="col-xl-12">
                    <div class="row">
                        <div class="col-xl-2 col-md-4 col-sm-6 mb-4">
                            <div class="contentbox dbox1  dashboardtopbox hyperlink"  (click)="changeLRParam('totalLR')">
                                <div class="iconBox"  > 
        
                                </div>
                                <div>
                                    <h2>{{generatedSum.totalLR}}</h2>
                                    <h1>Total LR</h1>
                                </div>
                        
        
                            </div>
                        </div>
                    
                        <div class="col-xl-2 col-md-4 col-sm-6 mb-4">
                            <div class="contentbox dbox2  dashboardtopbox hyperlink"  (click)="changeLRParam('runningLR')">
                                <div class="iconBox">                    
                                </div>
                                <div>
                                    <h2>{{generatedSum.runningLR}}</h2>
                                    
                                    <h1>Running LR</h1>
                                </div>
        
        
                            </div>
                        </div>
                        <div class="col-xl-2 col-md-4 col-sm-6 mb-4">
                            <div class="contentbox dbox3 dashboardtopbox hyperlink" (click)="changeLRParam('closedLR')">
                                <div class="iconBox">
        
                                </div>
                                <div>
                                    <h2>{{generatedSum.closedLR}}</h2>
                                    <h1>Closed LR</h1>
                                </div>
        
        
                            </div>
                        </div>
                        <div class="col-xl-2 col-md-4 col-sm-6 mb-4">
                            <div class="contentbox dbox4  dashboardtopbox hyperlink"   (click)="changeInvoiceParam('totalInvoice')">
                                <div class="iconBox">
        
                                </div>
                                <div>
                                    <h2>{{generatedSum.totalInvoice}}</h2>
                                    <h1>Total Invoice</h1>
                                </div>
        
                            </div>
                        </div>
             
                        <div class="col-xl-2 col-md-4 col-sm-6 mb-4">
                            <div class="contentbox dbox5  dashboardtopbox hyperlink"  >
                                <div class="iconBox">
        
                                </div>
                                <div class="exBox hyperlink" (click)="changeInvoiceParam('generatedInvoiceUsingCompanyName')">
                                    <h2>{{generatedSum.generatedInvoiceByCampany}}</h2>
                                    <h1> Company Name </h1>
                                    </div>                   
        
        
                            </div>
                        </div>

                <div class="col-xl-2 col-md-4 col-sm-6 mb-4">
                    <div class="contentbox dbox5  dashboardtopbox hyperlink"  >
                        <div class="iconBox">

                        </div>
                        
                            <div class="exBox hyperlink" (click)="changeInvoiceParam('generatedInvoiceUsingTripID')">

                            <h2>{{generatedSum.generateInvoiceByTripID}}</h2>
                            <h1>  Using TripID </h1>
                            </div>
                        


                    </div>
                </div>
                <!-- <div class="col-xl-2 col-md-4 col-sm-6 mb-4">
                    <div class="contentbox dbox6 dashboardtopbox hyperlink" >
                        <div class="iconBox">

                        </div>
                        <div>
                            <h2>{{generatedSum.pendingInvoiceByCampany}}</h2>
                            <h1>Company Name</h1>
                        </div>


                    </div>
                </div> -->

                <!-- <div class="col-xl-3 col-md-4 col-sm-6 mb-4">
                    <div class="contentbox dbox6 dashboardtopbox hyperlink" >
                        <div class="iconBox">

                        </div>
                        <div>
                            <h2>{{generatedSum.pendingInvoiceByTripID}}</h2>
                            <h1>TripID</h1>
                        </div>


                    </div>
                </div> -->
                    </div>
                </div>
                


            </div>
        </div>
        <div class="col-xl-4 col-md-4">
            <div class="contentbox h-100">
                <div class="expanceDetails vehicals">
                    <div (click)="totalVehicle('TotalVehicle')" class="exBox hyperlink" style="background-color: #d9b02b;" >
                        <h3 class="text-white">Total Vehicles</h3>
                        <h4 class="text-white">{{vehicles.totalVehicles}}</h4>
                    </div>
                    <div class="exBox hyperlink" style="background-color: #702bd9;" (click)="totalVehicle('ExpiredDocuments')">
                        <h3 class="text-white">Document Expired</h3>
                        <h4 class="text-white">{{vehicles.documentsExpired}}</h4>
                    </div>

                </div>
                <div class="chartbx" style=" width: 100%;">
                    <canvas id="vehicals" class="w-full" height="350">{{chart}}</canvas>
                </div>
            </div>
        </div>
        <div class="col-xl-8 col-md-8">
            <div class="contentbox">
                <div class="expanceDetails">
                    <div class="exBox">
                        <h3>Total Cash Dispense</h3>
                        <h4>{{totalDispenseAmount}}</h4>
                    </div>
                    <div class="exBox">
                        <h3>Total Cash Expense</h3>
                        <h4> {{totalExpenseAmount}}</h4>
                    </div>
                    <div class="exBox">
                        <h3>Total Uncounted Cash</h3>
                        <h4> {{totalDispenseAmount - totalExpenseAmount}} </h4>
                    </div>

                </div>

                <div class="chartbx" style=" width: 100%;">
                    <canvas id="expanceAmount" class="w-full" height="350"></canvas>
                </div>
            </div>
        </div>
        <div class="col-xl-12  mt-3">
            <div class="contentbox">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home"
                            type="button" role="tab" aria-controls="home" aria-selected="true">Cash Dispense
                            Report</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="lr-tab" data-bs-toggle="tab" data-bs-target="#lr"
                            type="button" role="tab" aria-controls="lr" aria-selected="false">LR Expense
                            Report</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile"
                            type="button" role="tab" aria-controls="profile" aria-selected="false">General Expense
                            Report</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact"
                            type="button" role="tab" aria-controls="contact" aria-selected="false">Categories Expense
                            Report</button>
                    </li>

                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="expenseTripWise" data-bs-toggle="tab" data-bs-target="#tripID"
                            type="button" role="tab" aria-controls="tripID" aria-selected="false"> Expense
                            Report TripWise</button>
                    </li>

                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="vehicleExpenseReport" data-bs-toggle="tab" data-bs-target="#vehicle"
                            type="button" role="tab" aria-controls="vehicle" aria-selected="false">Vehicle Expense
                            Report </button>
                    </li>
                    <!-- <li class="nav-item" role="presentation">
                        <button class="nav-link" id="dispenseTripWise" data-bs-toggle="tab" data-bs-target="#tripID"
                            type="button" role="tab" aria-controls="contact" aria-selected="false">Dispense
                            Report TripWise</button>
                    </li> -->
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade p-3 show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <div class="table-responsive">

                            <table datatable [dtOptions]="dtOptions" class="row-border hover tablelist" *ngIf='dispenseArray.length > 0' #empTable>
                                <thead>
                                    <tr>
                                        <td>Month</td>
                                        <td>Amount</td>
                                        <!-- <td>Year</td> -->
                                        <td>Action</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let user of dispenseArray">
                                        <td [routerLink]="['monthlyDispense', user.month, this.year]">{{user.month}}</td>

                                        <td [routerLink]="['monthlyDispense', user.month, this.year]">{{user.amount}}</td>

                                        <!-- <td>{{user.year}}</td> -->

                                        <td [routerLink]="['monthlyDispense', user.month, this.year]">View</td>
                                    </tr>

                                </tbody>
                            </table>

                        </div>
                    </div>

                    <!-- <div class="tab-pane fade p-3 show active" id="home" role="tabpanel" aria-labelledby="dispenseTripWise">
                        <div class="table-responsive">

                            <table datatable [dtOptions]="dtOptions" class="row-border hover tablelist" *ngIf='tripDispenseArray.length > 0' #empTable>
                                <thead>
                                    <tr>
                                        <td>Month</td>
                                        <td>Amount</td>
                                         <td>Year</td> -->
                                        <!-- <td>Action</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let user of tripDispenseArray">
                                        <td [routerLink]="['monthlyDispense', user.month, this.year]">{{user.month}}</td>

                                        <td [routerLink]="['monthlyDispense', user.month, this.year]">{{user.amount}}</td>

                                        <td>{{user.year}}</td> -->

                                        <!-- <td [routerLink]="['monthlyDispense', user.month, this.year]">View</td>
                                    </tr>

                                </tbody>
                            </table>

                        </div>
                    </div>   -->

                    <div class="tab-pane fade p-3" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                        <div class="table-responsive">

                            <table datatable [dtOptions]="dtOptions" class="row-border hover tablelist" *ngIf='expenseArray.length > 0'>
                                <thead>
                                    <tr>
                                        <td>Month</td>
                                        <td>Amount</td>
                                        <!-- <td>Year</td> -->
                                        <td>Action</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let userdata of expenseArray">
                                        <td [routerLink]="['monthlyExpense', userdata.month, this.year]">{{userdata.month}}</td>

                                        <td [routerLink]="['monthlyExpense', userdata.month, this.year]">{{userdata.amount}}</td>

                                        <!-- <td>{{user.year}}</td> -->

                                        <td [routerLink]="['monthlyExpense', userdata.month, this.year]">View</td>


                                    </tr>

                                </tbody>
                            </table>

                        </div>
                    </div>
                    <div class="tab-pane fade p-3" id="lr" role="tabpanel" aria-labelledby="lr-tab">
                        <div class="table-responsive">
                            <table datatable [dtOptions]="dtOptions" class="row-border hover tablelist" *ngIf='lrExpenseArray.length > 0' #empTable>
                                <thead>
                                    <tr>
                                        <td>Month</td>
                                        <td>Amount</td>
                                        <!-- <td>Year</td> -->
                                        <td>Action</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let lr of lrExpenseArray">
                                        <td [routerLink]="['monthlyLrExpense', lr.month, this.year]">{{lr.month}}</td>

                                        <td [routerLink]="['monthlyLrExpense', lr.month, this.year]">{{lr.amount}}</td>

                                        <!-- <td>{{user.year}}</td> -->

                                        <td [routerLink]="['monthlyLrExpense', lr.month, this.year]">View</td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="tab-pane fade p-3" id="tripID" role="tabpanel" aria-labelledby="expenseTripWise">
                        <div class="table-responsive">
                            <table datatable [dtOptions]="dtOptions" class="row-border hover tablelist" *ngIf='tripExpenseArray.length > 0' #empTable>
                                <thead>
                                    <tr>
                                        <td>Month</td>
                                        <td>Amount</td>
                                        <!-- <td>Year</td> -->
                                        <td>Action</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let trip of tripExpenseArray">
                                        <td [routerLink]="['monthlyTripID', trip.month, this.year]">{{trip.month}}</td>

                                        <td [routerLink]="['monthlyTripID', trip.month, this.year]">{{trip.amount}}</td>

                                        <!-- <td>{{user.year}}</td> -->

                                        <td [routerLink]="['monthlyTripID', trip.month, this.year]">View</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="tab-pane fade p-3" id="vehicle" role="tabpanel" aria-labelledby="vehicleExpenseReport">
                        <div class="table-responsive">
                            <table datatable [dtOptions]="dtOptions" class="row-border hover tablelist" *ngIf='vehicleExpenseArray.length > 0' #empTable>
                                <thead>
                                    <tr>
                                        <td>Month</td>
                                        <td>Amount</td>
                                        <!-- <td>Year</td> -->
                                        <td>Action</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let vehicle of vehicleExpenseArray">
                                        <td [routerLink]="['monthlyVehicle', vehicle.month, this.year]">{{vehicle.month}}</td>

                                        <td [routerLink]="['monthlyVehicle', vehicle.month, this.year]">{{vehicle.amount}}</td>

                                        <!-- <td>{{user.year}}</td> -->

                                        <td [routerLink]="['monthlyVehicle', vehicle.month, this.year]">View</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="tab-pane fade p-3" id="tripID" role="tabpanel" aria-labelledby="dispenseTripWise">
                        <div class="table-responsive">
                            <table datatable [dtOptions]="dtOptions" class="row-border hover tablelist" *ngIf='tripExpenseArray.length > 0' #empTable>
                                <thead>
                                    <tr>
                                        <td>Month</td>
                                        <td>Amount</td>
                                        <!-- <td>Year</td> -->
                                        <td>Action</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let trip of tripExpenseArray">
                                        <td [routerLink]="['monthlyLrExpense', trip.month, this.year]">{{trip.month}}</td>

                                        <td [routerLink]="['monthlyLrExpense', trip.month, this.year]">{{trip.amount}}</td>

                                        <!-- <td>{{user.year}}</td> -->

                                        <td [routerLink]="['monthlyLrExpense', trip.month, this.year]">View</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="tab-pane fade p-3" id="contact" role="tabpanel" aria-labelledby="contact-tab" >
                        <div class="table-responsive ">

                            <table datatable [dtOptions]="dtOptions" class="row-border hover tablelist"
                                *ngIf='categorybiseExpenseAmountArray.length > 0'>
                                <thead>
                                    <tr>
                                        <td>Category</td>
                                        <td>Amount</td>
                                        <!-- <td>Year</td> -->
                                        <td>Action</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let usercategoriesdata of categorybiseExpenseAmountArray">
                                        <td [routerLink]="['categoryExpense', usercategoriesdata.expenseCategory, this.year]">{{usercategoriesdata.expenseCategory}}</td>

                                        <td [routerLink]="['categoryExpense', usercategoriesdata.expenseCategory, this.year]">{{usercategoriesdata.amount}}</td>

                                        <!-- <td>{{user.year}}</td> -->

                                        <td [routerLink]="['categoryExpense', usercategoriesdata.expenseCategory, this.year]">View</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</div>
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>