<div class="container-fluid">
    <div class="row manageaccount mt-3">
        <div class="col-md-12 my-auto    ">
            <div class="d-flex align-items-baseline justify-content-between">
                <h1 class="fs-4 fw-bold mb-0">Detail LR</h1>
                <button class="btn btn-dark btn-sm " matTooltip="Back" matTooltipPosition="left" mat-raised-button (click)="backTo()"><i
                        class="fa-solid fa-arrow-left"></i></button>
            </div>
            <nav class="breadcrumbMaine" aria-label="breadcrumb mb-0">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item" routerLink="/superAdmin/lrmanagement">Manage LR </li>
                    <li class="breadcrumb-item active" aria-current="page">Detail LR</li>

                </ol>
            </nav>
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-xl-12">
            <form [formGroup]="editLR">
                <div class="contentbox">
                    <div class="row account">
                        <div class="col-xl-6">
                            <div class="row">
                                <div class="col-md-12">
                                    <h4 class="text-dark    border-bottom pb-3 border-gray mb-3 fw-bold mb-0">Basic Details ({{ this.particlarLR?.LR_no }})
                                    </h4>

                                </div>
                                <div class="col-xl-12">
                                    <table class="tablelist tblePreview">
                                        <tr>
                                            <td>Supervisor</td>
                                            <td>{{particlarLR.supervisorId}}</td>
                                        </tr>
                                        <tr>
                                            <td>Client Name</td>
                                            <td>{{clientName}}</td>
                                        </tr>
                                        <tr>
                                            <td>System LR No.</td>
                                            <td>{{particlarLR.LR_no}}</td>
                                        </tr>
                                        <tr>
                                            <td>Manual LR No.</td>
                                            <td>{{particlarLR.manualLR_no}}</td>
                                        </tr>
                                        <tr>
                                            <td>Dates</td>
                                            <td>{{particlarLR.date | date}}</td>

                                        </tr>
                                        <tr>
                                            <td>Consignee Name</td>
                                            <td>{{particlarLR.consignee_name}}</td>

                                        </tr>
                                        <tr>
                                            <td>Consignee Address</td>
                                            <td>{{particlarLR.consignee_address}}</td>

                                        </tr>
                                        <tr>
                                            <td>Consignor Name</td>
                                            <td>{{particlarLR.consignor_name}}</td>
                                        </tr>
                                        <tr>
                                            <td>Consignor Address</td>
                                            <td>{{particlarLR.consignor_address}}</td>
                                        </tr>
                                        <tr>
                                            <td>Vehicle No.</td>
                                            <td>{{particlarLR.vehicle_id}}</td>
                                        </tr>
                                        <tr>
                                            <td>Vehicle Type</td>
                                            <td>{{particlarLR.vehicleType}}</td>
                                        </tr>
                                        <tr>
                                            <td>From</td>
                                            <td>{{particlarLR.from}}</td>
                                        </tr>
                                        <tr>
                                            <td>To</td>
                                            <td>{{particlarLR.to}}</td>
                                        </tr>
                                        <tr>
                                            <td>GSTIN</td>
                                            <td>{{particlarLR.GSTIN}}</td>
                                        </tr>
                                        <tr>
                                            <td>E-way bill number</td>
                                            <td>{{particlarLR.road_permit_no}}</td>
                                        </tr>
                                        <tr>
                                            <td>State Code</td>
                                            <td>{{particlarLR.stateCode}}</td>
                                        </tr>
                                        <!-- <tr>
                                            <td>DLY</td>
                                            <td>{{particlarLR.dly_no}}</td>
                                        </tr> -->
                                        <!-- <tr>
                                            <td>Declared Value</td>
                                            <td>{{particlarLR.declared_value}}</td>
                                        </tr> -->
                                        <tr>
                                            <td>FIX / Adhoc</td>
                                            <td>{{particlarLR.fixAdhoc}}</td>
                                        </tr>
                                        <tr>
                                            <td>Quantity/Weight</td>
                                            <td>{{particlarLR.quantity}}</td>
                                        </tr>
                                        <tr>
                                            <td>Transporter Name</td>
                                            <td>{{particlarLR.transportorName}}</td>
                                        </tr>
                                        <tr>
                                            <td>Transporter Mobile</td>
                                            <td>{{particlarLR.transportorMobile}}</td>
                                        </tr>
                                        <tr>
                                            <td>Driver Name</td>
                                            <td>{{particlarLR.driverName}}</td>
                                        </tr>
                                        <tr>
                                            <td>Driver Mobile</td>
                                            <td>{{particlarLR.driverMobile}}</td>
                                        </tr>
                                        <tr>
                                            <td>Freight</td>
                                            <td>{{particlarLR.Frieght ? particlarLR.Frieght : 'N/A'}}</td>
                                        </tr>
                                        <tr>
                                            <td>POD</td>
                                            <td><button class="btn btn-dark btn-sm  px-4 ms-3" type="button" (click)="previewLoadingSlip(particlarLR.loadingSlipAttchment[0], particlarLR.loadingSlipAttchmentBack[0])" mat-raised-button >Preview</button>
                                                <a #showLoadingSlipPreview [href]="previewLoadingSlipURL" target="_blank"></a></td>
                                        </tr>

                                    </table>
                                </div>


                            </div>
                        </div>
                        <div class="col-xl-6">
                            <div class="row">
                                <div class="col-md-12  ">
                                    <h4 class="text-dark border-bottom pb-3 border-gray mb-3 fw-bold mb-0">Information For office use only</h4>

                                </div>
                                <div class="col-xl-12">
                                    <table class="tablelist tblePreview">
                                        <tr>
                                            <td>Starting Meter Reading</td>
                                            <td>{{particlarLR.startmeterReading ? particlarLR.startmeterReading : 'N/A'}}</td>
                                        </tr>
                                        <tr>
                                            <td>End Meter Reading</td>
                                            <td>{{particlarLR.endmeterReading ? particlarLR.endmeterReading:'N/A'}}</td>
                                        </tr>
                                        <tr>
                                            <td>Trip Starting Date</td>
                                            <td>{{particlarLR.tripStartDate ? (particlarLR.tripStartDate | date):'N/A' }}</td>

                                        </tr>
                                        <tr>
                                            <td>Trip End Date</td>
                                            <td>{{particlarLR.tripEndDate ? (particlarLR.tripEndDate | date):'N/A'}}</td>

                                        </tr>
                                        <tr>
                                            <td>Trip Starting Time </td>
                                            <td>{{particlarLR.out_time  ? particlarLR.out_time:'N/A'}}</td>

                                        </tr>
                                        <tr>
                                            <td>Trip End Time</td>
                                            <td>{{particlarLR.in_time ? particlarLR.in_time:'N/A'}}</td>
                                        </tr>
                                        <tr>
                                            <td>Number of Tolls</td>
                                            <td>{{particlarLR.noofTools ? particlarLR.noofTools:'N/A'}}</td>
                                        </tr>
                                        <tr>
                                            <td>Total Toll Tax Paid</td>
                                            <td>{{particlarLR.tooltaxPaid ? particlarLR.tooltaxPaid:'N/A'}}</td>
                                        </tr>
                                        <tr>
                                            <td>Labour Loading / Labour Unloading</td>
                                            <td>{{particlarLR.labourPaid ? particlarLR.labourPaid :'N/A'}}</td>
                                        </tr>
                                        <tr>
                                            <td>Other payment</td>
                                            <td>{{particlarLR.otherPayment  ? particlarLR.otherPayment :'N/A'}}</td>
                                        </tr>
                                        <tr>
                                            <td>Usage KM</td>
                                            <td>{{particlarLR.up_down_km ? particlarLR.up_down_km :'N/A'}}</td>
                                        </tr>
                                        <tr>
                                            <td>Total Hours</td>
                                            <td>{{particlarLR.totalhour ? particlarLR.totalhour :'N/A'}}</td>
                                        </tr>
                                        <tr>
                                            <td>Extra Hours</td>
                                            <td>{{particlarLR.extrahour ? particlarLR.extrahour :'N/A'}}</td>
                                        </tr>
                                        <tr>
                                            <td>Detention</td>
                                            <td>{{particlarLR.detention ? particlarLR.detention :'N/A'}}</td>
                                        </tr>
                                        <tr>
                                            <td>Vehicle Rate</td>
                                            <td>{{particlarLR.vehicleRate ? particlarLR.vehicleRate :'N/A'}}</td>
                                        </tr>
                                        <tr>
                                            <td>Advance</td>
                                            <td>{{particlarLR.advance  ? particlarLR.advance :'N/A'}}</td>
                                        </tr>
                                        <tr>
                                            <td>Actual Balance</td>
                                            <td>{{particlarLR.balance ? particlarLR.balance :'N/A'}}</td>
                                        </tr>
                                        <tr>
                                            <td>Balance Paid</td>
                                            <td>{{particlarLR.balancePaid ? particlarLR.balancePaid :'N/A'}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td colspan="2">&nbsp;</td>
                                        </tr>

                                    </table>
                                </div>


                            </div>
                        </div>




                    </div>
                </div>
                <div class="row account mt-3 mb-3 d-none">
                    <div class="col-md-12">
                        <h4 class="text-dark"><b>Information For office use only</b></h4>
                        <hr>
                    </div>
                    <div>
                        <div class="row">
                            <div class="col-xl-6 col-md-6 col-12 col-sm-6">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Starting Meter Reading</label>
                                    <div class="d-flex align-items-center">
                                        <input type="text" class="form-control me-1 shadow-none"
                                            placeholder="Enter Meter Reading" id="startmeterReadingctrl" disabled
                                            formControlName="startmeterReading">
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-md-6 col-12 col-sm-6">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">End Meter Reading</label>
                                    <div class="d-flex align-items-center">
                                        <input type="text" class="form-control me-1 shadow-none"
                                            placeholder="Enter  Meter Reading" id="endmeterReadingctrl" disabled
                                            formControlName="endmeterReading">
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-md-6 col-12 col-sm-6">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Trip Starting Date</label>
                                    <div class="d-flex align-items-center">
                                        <input type="date" class="form-control me-1 shadow-none"
                                            placeholder="Enter Start Time" id="tripSDatectrl" disabled
                                            formControlName="tripSDate">
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-md-6 col-12 col-sm-6">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Trip End Date</label>
                                    <div class="d-flex align-items-center">
                                        <input type="date" class="form-control me-1 shadow-none"
                                            placeholder="Enter End Time" id="tripEDatectrl" disabled
                                            formControlName="tripEDate">
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-md-6 col-12 col-sm-6">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Trip Starting Time</label>
                                    <div class="d-flex align-items-center">
                                        <input type="time" class="form-control me-1 shadow-none"
                                            placeholder="Enter Start Time" id="outtimectrl" disabled
                                            formControlName="outtime">
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-md-6 col-12 col-sm-6">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Trip End Time</label>
                                    <div class="d-flex align-items-center">
                                        <input type="time" class="form-control me-1 shadow-none"
                                            placeholder="Enter End Time" id="INtimectrl" disabled
                                            formControlName="INtime">
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-md-6 col-12 col-sm-6">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Number of Tolls</label>
                                    <div class="d-flex align-items-center">
                                        <input type="text" class="form-control me-1 shadow-none"
                                            placeholder="Enter Tolls" id="noofToolsctrl" disabled
                                            formControlName="noofTools">
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-md-6 col-12 col-sm-6">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Total Toll Tax Paid</label>
                                    <div class="d-flex align-items-center">
                                        <input type="text" class="form-control me-1 shadow-none"
                                            placeholder="Enter Tolls Tax Paid" id="tooltaxPaidctrl" disabled
                                            formControlName="tooltaxPaid">
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-md-6 col-12 col-sm-6">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Labour Loading / Labour Unloading</label>
                                    <div class="d-flex align-items-center">
                                        <input type="text" class="form-control me-1 shadow-none"
                                            placeholder="Labour Paid" id="labourPaidctrl" disabled
                                            formControlName="labourPaid">
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-md-6 col-12 col-sm-6">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Other payment</label>
                                    <div class="d-flex align-items-center">
                                        <input type="text" class="form-control me-1 shadow-none"
                                            placeholder="Other payment" id="otherPaymentctrl" disabled
                                            formControlName="otherPayment">
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-md-6 col-12 col-sm-6">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Usage KM</label>
                                    <div class="d-flex align-items-center">
                                        <input type="text" class="form-control me-1 shadow-none" placeholder="KM"
                                            id="updownkmctrl" disabled formControlName="updownkm">
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-md-6 col-12 col-sm-6">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Total Hours</label>
                                    <div class="d-flex align-items-center">
                                        <input type="text" class="form-control me-1 shadow-none"
                                            placeholder="Total Hours" id="totalhourctrl" disabled
                                            formControlName="totalhour">
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-6 col-md-6 col-12 col-sm-6">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Extra Hours</label>
                                    <div class="d-flex align-items-center">
                                        <input type="text" class="form-control me-1 shadow-none"
                                            placeholder="Extra Hours" id="extrahourctrl" disabled
                                            formControlName="extrahour">
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-md-6 col-12 col-sm-6">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Detention</label>
                                    <div class="d-flex align-items-center">
                                        <input type="text" class="form-control me-1 shadow-none" placeholder="Detention"
                                            id="detentionctrl" disabled formControlName="detention">
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-md-6 col-12 col-sm-6">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Vehicle Rate</label>
                                    <div class="d-flex align-items-center">
                                        <input type="text" class="form-control me-1 shadow-none"
                                            placeholder="Vehicle Rate" id="vehicleRatectrl" disabled
                                            formControlName="vehicleRate">
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-md-6 col-12 col-sm-6">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Advance</label>
                                    <div class="d-flex align-items-center">
                                        <input type="text" class="form-control me-1 shadow-none" placeholder="Advance"
                                            disabled id="advancectrl" formControlName="advance">
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-md-6 col-12 col-sm-6">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Actual Balance</label>
                                    <div class="d-flex align-items-center">
                                        <input type="text" class="form-control me-1 shadow-none"
                                            placeholder="Actual Balance" disabled id="balancectrl"
                                            formControlName="balance">
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-md-6 col-12 col-sm-6">
                                <div class="form-group accountsinp  mb-3">
                                    <label for="">Balance Paid</label>
                                    <div class="d-flex align-items-center">
                                        <input type="text" class="form-control me-1 shadow-none"
                                            placeholder="Balance Paid" disabled id="balancePaidctrl"
                                            formControlName="balancePaid">
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="row justify-content-center mt-2">
                            <div class="col-lg-2 col-sm-3 col-md-3 col-12 text-center">
                                <button hidden (click)="updateLRSubmit()" class="btn btn-primary text-white px-5"
                                    type="submit" mat-raised-button>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <!-- <div class="col-xl-12">
            <div class="contentbox mt-3">
                <div class="row account">
                    <div class="col-xl-12">
                        <div class="row">
                            <div class="col-md-12 my-auto  border-bottom pb-3 border-gray mb-3 fw-bold mb-0">
                                <h4 class="fs-4 fw-bold mb-0">Invoice Details</h4>
        
                            </div>
                            <div class="col-md-3 text-end   " hidden>
                                <button  (click)="addnewinv(this.particlarLR?._id)"
                                    class="btn btn-primary text-white px-5 btn-sm">+ Add New Invoice</button>
                            </div>
                        </div>
        
                        <div class="row ">
                            <div class="col-md-12 table-responsive">
                                <table datatable class="tablelist" *ngIf='this.particlarLR?.invoice_no.length > 0'>
                                    <thead>
                                        <tr>
            
                                            <td>NO of Article</td>
                                            <td>Invoice No.</td>
                                            <td>Customer Name</td>
                                            <td>Invoice Value</td>
                                            <td>Weight</td> -->
                                            <!-- <td>Frieght</td> -->
            
                                            <!-- <td>ACTION</td>
                                        </tr>
                                    </thead>
                                    <tbody>
             -->
<!--             
                                        <tr *ngFor="let inv of this.particlarLR?.invoice_no">
                                            <td>{{ inv.NoofArticle}}</td>
                                            <td>{{ inv.InvoiceNo}}</td>
                                            <td>{{ inv.customerName}}</td>
                                            <td>{{ inv.InvoiceValue}}</td>
                                            <td>{{ inv.Weight}}</td> -->
            
                                            <!-- <td>{{ inv.Frieght}}</td> -->
                                            <!-- <td><button (click)="editInvoice(inv,this.particlarLR?._id)" class="btn btn-block"><i
                                                        matTooltip="Edit" class="fas fa-pen"></i></button></td>
                                        </tr>
            
                                    </tbody>
            
                                </table>
                            </div>
            
                        </div>
                    </div>
        
                    
                </div>
            </div>
        </div> -->
        
    </div>
</div>