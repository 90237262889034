import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuperAdminService } from 'src/app/super-admin.service';

@Component({
  selector: 'app-date-wise-category',
  templateUrl: './date-wise-category.component.html',
  styleUrls: ['./date-wise-category.component.css']
})
export class DateWiseCategoryComponent implements OnInit {
  myArr: any=[]
  catArr: any=[]
  paramId:any;
  users:any;
  userSessionData:any=JSON.parse(sessionStorage.getItem('userSession'));
  monthArr : any=["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
  monthArrFull : any=["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  paramSubCategory: any;
  paramSupervisor: any;
  paramCompany: any;
  categoryArray: any = [];
  month: any;
  year: any;
  constructor(private activatedRoute: ActivatedRoute,private router: Router,private toastr: ToastrService, private superadminService: SuperAdminService) {
    this.activatedRoute.params.subscribe(param => {
      this.paramId = param.month;
      this.month = this.monthArrFull[this.monthArr.indexOf(this.paramId)];
      this.paramSubCategory = param.category;
      this.paramCompany = param.company;
      this.paramSupervisor = param.supervisor;
      this.year = param.year;
    })
   }
  ngOnInit(): void {
    this.getExpense()
  }
  getExpense() {
    this.superadminService.getAllExpense()
      .subscribe((res: { status: any, message: any, data: any }) => {
       if(res.status == true){
        this.myArr= res.data;
        this.myArr = this.myArr.filter((item) => {
          return item.expenseDate.split('T')[0].split('-')[0] == this.year
        })
        if(this.userSessionData.user_role_id[0]!=1){
          this.myArr = this.myArr.filter((item)=>{
            return item.supervisorId==this.userSessionData._id
          })
        }
        for(let i=0; i<this.myArr.length; i++){
          if(this.monthArr[this.myArr[i].expenseDate.split('-')[1]-1]==this.paramId && this.myArr[i].supervisorId==this.paramSupervisor && this.myArr[i].companyId==this.paramCompany){
          this.catArr.push({"companyId":this.myArr[i].companyId, "supervisorId":this.myArr[i].supervisorId, "date":this.myArr[i].expenseDate.split('T')[0], "LrNumber":this.myArr[i].LRNumber, "supervisor":this.myArr[i].SupervisorName,"destination":this.myArr[i].destination, "amount":this.myArr[i].totalExpenseAmount,  "expenseSubCategoryId":this.myArr[i].expenseSubCategoryId
        })
        this.superadminService.getClientList()
          .subscribe((client: { status: any, message: any, data: any }) => {
            if (client.status == true) {
              this.catArr.forEach((x, index) => {
                client.data.filter(y => {
                  if (y._id == x.companyId) this.catArr[index].companyName = y.clientName
                })
              });
              this.superadminService.getSuperVisorName().subscribe((supervisors: {status: any, message: any, data: any}) => {
                if(supervisors.status == true){
                  var supervisorsData = supervisors.data;
                  this.catArr.forEach((item,i)=>{
                    this.catArr[i].SupervisorName = supervisorsData.filter((item)=> this.catArr[i].supervisorId==item._id);
                    this.catArr[i].SupervisorName = this.catArr[i].SupervisorName[0]?this.catArr[i].SupervisorName[0]['full_name']:this.catArr[i].SupervisorName[0]
                  })
                  this.superadminService.getSubCategory().subscribe((subCategories: {status: any, message: any, data: any}) => {
                    if(subCategories.status == true){
                      var subCategoriesData = subCategories.data;
                      this.catArr.forEach((item,i)=>{
                        let category = subCategoriesData.filter((item)=> this.catArr[i].expenseSubCategoryId==item._id);
                        this.catArr[i].expenseSubCategory = category[0]?category[0]['expenseSubCategory']:category[0];
                        this.catArr[i].expenseCategory = category[0]?category[0]['expenseCategory']:category[0];
                      })
                      this.catArr = this.catArr.filter((item) => item.expenseSubCategoryId==this.paramSubCategory);
                      this.categoryArray = this.catArr;
                    }else{
                      console.log(res.message);
                    }
                  }, error => {
                    console.log('error', error);
                  })
                }else{
                  console.log(res.message);
                }
              }, error => {
                console.log('error', error);
              })
            }
            else{
              console.log(res.message);
            }
          }, error => {
            console.log('error', error);
          })
        }
        }
       }else{
        if (res.status == 401) {
          this.toastr.error(res.message);
          window.sessionStorage.clear();
          this.router.navigateByUrl('superAuth/login');
          
        }else {
          this.toastr.error(res.message);
        }
       }
      }
      )
}
backTo(){
  window.history.back()
}

}
