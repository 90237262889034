<div class="container-fluid">
    <div class="row justify-content-between manageaccount mt-3">
        <div class="col-xl-12 col-12 col-sm-12 col-md-12 my-auto">
            <div class="d-flex align-items-baseline justify-content-between">
                <h1 class="fs-4 fw-bold mb-0">View LR</h1>
                <button class="btn btn-dark btn-sm " matTooltip="Back" matTooltipPosition="left" mat-raised-button
                    (click)="backTo()"><i class="fa-solid fa-arrow-left"></i></button>
            </div>
            <nav class="breadcrumbMaine" aria-label="breadcrumb mb-0">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item" routerLink="/superAdmin/lrmanagement">Manage LR</li>
                    <li class="breadcrumb-item active" aria-current="page">View LR</li>
                </ol>
            </nav>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-xl-12">
            <div class="contentbox">
                <div class="text-center ">

                    <button mat-raised-button class="btn btn-outline-dark btn-sm px-4" [useExistingCss]="true"
                        printSectionId="print-section" ngxPrint>Print</button>&nbsp;
                    <button mat-raised-button class="btn btn-dark btn-sm px-4"
                        (click)="sendEmail('print-section')">Email</button>
                </div>
                <div class="invoicetabl" id="print-section">

                    <div class="inner">
                        <div class="leftbox">
                            <p class="gstno">GSTIN: 06BKAPP5757J1ZT </p>

                            <div class="adresspanel">

                                <div class="logopanel">
                                    <img src="../../../assets/images/profilephoto.jpg" alt="">
                                </div>
                                <div class="addressbox">
                                    <h1>K LOGISTICS</h1>
                                    <h4>Fleet Owners & Transport Contractor</h4>
                                    <p>1ST FLOOR, PLOT NO. 176, SEC-58, TRANSPORT NAGAR, BALLABGARH, Faridabad, Haryana, 121002,</p>
                                    <p>SEC-58, Faridabad, Haryana: Pin Code:121002</p>
                                    <p>Ph.: , e-mail : care&#64;transport.com</p>
                                </div>

                            </div>
                            <div class="inputbox1">
                                <div class="consinorbox">
                                    <div class="input1">
                                        <label for="">Consignor Name & Address</label>
                                        <input type="text" disabled value="{{ particlarLR?.consignor_name }}">
                                    </div>
                                    <div class="input2">
                                        <!-- <input type="text" disabled value="{{ particlarLR?.consignor_address }}"> -->
                                        <p>{{ particlarLR?.consignor_address }}</p>
                                    </div>
                                </div>
                                <div class="consinorbox">
                                    <div class="input1">
                                        <label for="">Consignee Name & Address</label>
                                        <input type="text" disabled value="{{ particlarLR?.consignee_name }}">
                                    </div>
                                    <div class="input2">
                                        <p>{{ particlarLR?.consignee_address }}</p>
                                        <!-- <input type="text" disabled value="{{ particlarLR?.consignee_address }}"> -->
                                    </div>
                                </div>
                                <div class="inputbox3">
                                    <div class="input1">
                                        <label for="">GSTIN </label>
                                        <input type="text" disabled value="{{ particlarLR?.GSTIN }}">
                                    </div>
                                    <div class="input1">
                                        <label for="">State Code</label>
                                        <input type="text" disabled value="{{ particlarLR?.stateCode }}">
                                    </div>
                                </div>
                                <div class="inputbox3">
                                    <!-- <div class="input1">
                                        <label for="">DLY </label>
                                        <input type="text" disabled value="{{ particlarLR?.dly_no }}">
                                    </div>
                                    <div class="input1">
                                        <label for="">Declared Value Rs.</label>
                                        <input type="text" disabled value="{{ particlarLR?.declared_value }}">
                                    </div> -->
                                </div>
                            </div>
                            <div class="inpoicedetails">
                                <table>
                                    <thead>
                                        <tr>
                                            <td>No. of Articles</td>
                                            <td class="withinv">Customer Name </td>
                                            <td class="withinv">Invoice No.</td>
                                            <td>Value&nbsp;(&#8377;)</td>
                                            <td>Weight&nbsp;(Kg)</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let inv of particlarLR?.invoice_no ">

                                            <td>{{inv.NoofArticle}}</td>
                                            <td class="withinv">{{inv.customerName}}</td>
                                            <td>{{inv.InvoiceNo}}</td>
                                            <td>{{inv.InvoiceValue}}</td>
                                            <td>{{inv.Weight}}</td>
                                        </tr>

                                    </tbody>
                                    <tfoot>
                                        <!-- <tr>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
            
                                            <!- <td class="withinv" style="border-bottom: none;">
                                                <div class="input1">
                                                    <label for="">DLY</label>
                                                    <input type="text" disabled value="{{particlarLR?.dly_no}}">
                                                </div>
                                            </td> --
                                            <td>&nbsp;</td>
                                            <td style="border-top:1px solid #000;" colspan="2">Frieght : {{ particlarLR?.Frieght }}
                                            </td>
            
                                        </tr> -->
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td>Total</td>
                                            <!-- <td class="withinv" style="border-top: none;">
                                                <div class="input1">
                                                    <label for="">Declared Value Rs.</label>
                                                    <input type="text" disabled value="{{particlarLR?.declared_value}}">
                                                </div>
                                            </td> -->
                                            <td>&nbsp;</td>
                                            <td>{{ particlarLR?.declared_value}}</td>
                                            <td>{{getTotalWeight(particlarLR?.invoice_no)}}</td>
                                        </tr>
                                        <tr>
                                            <td colspan="3"></td>
                                            <td colspan="2">
                                                Frieght&nbsp;:&nbsp;{{ particlarLR?.Frieght }}
                                            </td>
                                        </tr>
                                        <!-- <tr>
                                            <td colspan="6" class="text-center">GST Paid by Consignor</td>
                                        </tr> -->
                                    </tfoot>

                                </table>
                            </div>

                        </div>
                        <div class="rightbox">
                            <div class="rightdetailbox">
                                <div class="rightinnbx">
                                    <p>Vehicle No.</p>
                                    <div class="trkno"> {{ particlarLR?.vehicle_id }}</div>
                                </div>
                            </div>
                            <div class="rightdetailbox">
                                <div class="rightinnbx">
                                    <p>Consignment Note</p>
                                    <div class="trkno"> {{ particlarLR?.consigment_note }}</div>
                                </div>
                                <div class="rightinnbx">
                                    <p>System LR No.</p>
                                    <div class="trkno"> {{ particlarLR?.LR_no }}</div>
                                </div>
                                <div class="rightinnbx">
                                    <p>Manual LR No.</p>
                                    <div class="trkno"> {{ particlarLR?.manualLR_no }}</div>
                                </div>
                            </div>
                            <div class="rightdetailbox">
                                <div class="rightinnbx">
                                    <p>Dated</p>
                                    <div class="trkno"> {{ particlarLR?.date | date:'yyyy-MM-dd' }}</div>
                                </div>
                            </div>
                            <div class="rightdetailbox">
                                <div class="rightinnbx">
                                    <p>From</p>
                                    <div class="trkno"> {{ particlarLR?.from }}</div>
                                </div>
                            </div>
                            <div class="rightdetailbox">
                                <div class="rightinnbx">
                                    <p>To</p>
                                    <div class="trkno"> {{ particlarLR?.to }}</div>
                                </div>
                            </div>
                            <div class="rightdetailbox">
                                <div class="rightinnbx">
                                    <p>E-way bill number</p>
                                    <div class="trkno"> {{ particlarLR?.road_permit_no }}</div>
                                </div>
                            </div>
                            <!-- <div class="righttblbox">
                                <table>
                                    <thead>
                                        <tr>
                                            <td colspan="3">Branch Offices</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Office </td>
                                            <td>: 0120-4108268</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp; </td>
                                            <td>: 9311074287</td>
                                        </tr>
                                        <tr>
                                            <td>&nbsp; </td>
                                            <td>: 7835005355</td>
                                        </tr>
                                        <tr>
                                            <td>U.P. Border </td>
                                            <td>: 9311074271</td>
                                        </tr>
                                        <tr>
                                            <td>Mundka (Delhi)</td>
                                            <td>: 9311074267</td>
                                        </tr>
                                        <tr>
                                            <td>Shahdra (Delhi)</td>
                                            <td>: 9311074286</td>
                                        </tr>

                                        <tr>
                                            <td>Punjab </td>
                                            <td>: 9056191488</td>
                                        </tr>

                                        <tr>
                                            <td>Amritsar</td>
                                            <td>: 7508864941</td>
                                        </tr>
                                        <tr>
                                            <td>Gurgaon</td>
                                            <td>: 7835005354</td>
                                        </tr>

                                        <tr>
                                            <td>Lucknow</td>
                                            <td>: 9935572471</td>
                                        </tr>
                                        <tr>
                                            <td>Patna </td>
                                            <td>: 8968637225</td>
                                        </tr>
                                        <tr>
                                            <td>Kasna </td>
                                            <td>: 9311074290</td>
                                        </tr>
                                        <tr>
                                            <td>Delhi NCR </td>
                                            <td>: 7009373634</td>
                                        </tr>
                                        <tr>
                                            <td>Samalkha (Delhi)</td>
                                            <td>: 9915514317</td>
                                        </tr>
                                        <tr>
                                            <td>South X </td>
                                            <td>: 8639876120</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> -->
                            <div class="rightfoot">
                                <!-- <div class="input1">
                                    <label for="">Vehicle Name:</label>
                                    <input type="text" disabled value="{{ vehiclesDetail?.vehicle_name }} {{ vehiclesDetail?.type }}" >
                                </div> -->
                                <div class="input1">
                                    <label for="">Start reading</label>
                                    <input type="text" disabled value="{{ particlarLR?.startmeterReading }}">
                                </div>
                                <div class="input1">
                                    <label for="">End reading</label>
                                    <input type="text" disabled value="{{ particlarLR?.endmeterReading }}">
                                </div>
                                <div class="input1">
                                    <label for="">UP/DOWN Km.</label>
                                    <input type="text" disabled value="{{ particlarLR?.up_down_km }}">
                                </div>
                                <div class="inputbox3">
                                    <div class="input1">
                                        <label for="">Out Time </label>
                                        <input type="text" disabled value="{{ particlarLR?.out_time }}">
                                    </div>
                                    <div class="input1">
                                        <label for="">In Time</label>
                                        <input type="text" disabled value="{{ particlarLR?.in_time }}">
                                    </div>
                                </div>
                                <div class="input1">
                                    <label for="">Total Hours</label>
                                    <input type="text" disabled
                                        value="{{getTotalHrs( particlarLR?.totalhour, particlarLR?.extrahour)}}">
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="footerpanel">
                        <div class="footerleft">
                            <div class="footbox1">
                                <p>1. No. Re-Booking Arrengement.</p>
                                <p>2. Destination octroi Paid by th Customer</p>
                                <p>3. Subject to Ghaziabad Jurisdiction only</p>
                            </div>
                            <div class="footbox1">
                                <p>At Owner's Risk</p>
                                <p>At Carrier's Risk</p>
                            </div>
                            <div class="footbox1">
                                <p>Company is not liable for Leakage/</p>
                                <p>Damage/Shortage during Accidents of lorry.</p>
                                <p>Company is not liable for leakage of Liquid material.</p>
                            </div>
                        </div>
                        <div class="footerright">
                            <h6>K LOGISTICS</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>