<div class="container-fluid">
  <div class="row manageaccount mt-3">
    <div class="col-md-12 my-auto">
      <div class="d-flex align-items-center justify-content-between">
        <h1 class="fs-4 fw-bold">Edit Vehicle</h1>
        <button
          class="btn btn-dark btn-sm"
          mat-raised-button
          matTooltip="Back"
          matTooltipPosition="left"
          (click)="backTo()"
        >
          <i class="fa-solid fa-arrow-left"></i>
        </button>
      </div>
      <nav class="breadcrumbMaine" aria-label="breadcrumb mb-0">
        <ol class="breadcrumb mb-0">
          <li class="breadcrumb-item" routerLink="/superAdmin/manageVehicle">
            Manage Vehicle
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Edit Vehicle
          </li>
        </ol>
      </nav>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-xl-12">
      <form
        [formGroup]="editVehicle"
        (ngSubmit)="updateVehicle()"
        enctype="multipart/form-data"
      >
        <div class="contentbox">
          <div class="row account mb-3">
            <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
              <div class="form-group accountsinp mb-3">
                <label for="">Vehicle Number</label>
                <input
                  type="text"
                  id="vehicleNumberctrl"
                  class="form-control shadow-none"
                  placeholder="Enter Vehicle Number"
                  formControlName="vehicleNumber"
                />
              </div>
            </div>
            <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
              <div class="form-group accountsinp mb-3">
                <label for="">Vehicle Type</label>
                <input
                  type="text"
                  id="vehicleTypectrl"
                  class="form-control shadow-none"
                  placeholder="Enter Vehicle Type"
                  formControlName="vehicleType"
                  [matAutocomplete]="autoVehicleType"
                />
                <mat-autocomplete
                  autoActiveFirstOption
                  #autoVehicleType="matAutocomplete"
                >
                  <mat-option
                    *ngFor="let option of filteredVehicleTypes | async"
                    [value]="option"
                  >
                    {{ option }}
                  </mat-option>
                </mat-autocomplete>
                <!-- <select id="vehicleTypectrl" formControlName="vehicleType" placeholder="Select Vehicle Type"
                                    class="form-control shadow-none">
                                    <option disabled value="">--Select Vehicle Type--</option>
                                    <option *ngFor="let c of vehicletypes" value="{{c.vehicletype_name}}">
                                        {{c.vehicletype_name}}</option>

                                </select> -->
              </div>
            </div>
            <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
              <div class="form-group accountsinp mb-3">
                <label for="">Owner Name</label>
                <input
                  type="text"
                  id="ownerNamectrl"
                  class="form-control shadow-none"
                  placeholder="Enter Owner Name"
                  formControlName="ownerName"
                />
              </div>
            </div>
            <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
              <div class="form-group accountsinp mb-3">
                <label for="">Driver Name</label>
                <input
                  type="text"
                  id="driverNamectrl"
                  class="form-control shadow-none"
                  placeholder="Enter Driver Name"
                  formControlName="driverName"
                  [matAutocomplete]="autoDriverName"
                />
                <mat-autocomplete
                  autoActiveFirstOption
                  #autoDriverName="matAutocomplete"
                >
                  <mat-option
                    *ngFor="let option of filteredDriverName | async"
                    [value]="option"
                  >
                    {{ option }}
                  </mat-option>
                </mat-autocomplete>
                <!-- <select id="driverNamectrl" formControlName="driverName" placeholder="Select Driver Name"
                                    class="form-control shadow-none">
                                    <option disabled value="">--Select Driver Name--</option>
                                    <option *ngFor="let d of driver" value="{{d.driver_name}}">{{d.driver_name}}</option>

                                </select> -->
              </div>
            </div>

            <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
              <div class="form-group accountsinp mb-3">
                <label for="">Maker Name</label>
                <input
                  type="text"
                  id="makerNamectrl"
                  class="form-control shadow-none"
                  placeholder="Enter Maker Name"
                  formControlName="makerName"
                />
              </div>
            </div>
            <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
              <div class="form-group accountsinp mb-3">
                <label for="">Chassis Number</label>
                <input
                  type="text"
                  id="chassisNumberctrl"
                  class="form-control shadow-none"
                  placeholder="Enter Chassis Number"
                  formControlName="chassisNumber"
                />
              </div>
            </div>
            <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
              <div class="form-group accountsinp mb-3">
                <label for="">Engine Number</label>
                <input
                  type="text"
                  id="engineNumberctrl"
                  class="form-control shadow-none"
                  placeholder="Enter Engine Number"
                  formControlName="engineNumber"
                />
              </div>
            </div>
            <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
              <div class="form-group accountsinp mb-3">
                <label for="">Permit Number</label>
                <input
                  type="text"
                  id="permitNumberctrl"
                  class="form-control shadow-none"
                  placeholder="Enter Permit Number"
                  formControlName="permitNumber"
                />
              </div>
            </div>
            <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
              <div class="form-group accountsinp mb-3">
                <label for="">Policy Number</label>
                <input
                  type="text"
                  id="policyNumberctrl"
                  class="form-control shadow-none"
                  placeholder="Enter Policy Number"
                  formControlName="policyNumber"
                />
              </div>
            </div>
            <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
              <div class="form-group accountsinp mb-3">
                <label for="">City</label>
                <input
                  type="text"
                  id="cityctrl"
                  class="form-control shadow-none"
                  placeholder="Enter City"
                  formControlName="city"
                />
              </div>
            </div>
            <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
              <div class="form-group accountsinp mb-3">
                <label for="">Permit Due Date</label>
                <input
                  type="date"
                  id="permitDueDatectrl"
                  class="form-control shadow-none"
                  placeholder="Enter Permit Due Date"
                  formControlName="permitDueDate"
                />
              </div>
            </div>
            <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
              <div class="form-group accountsinp mb-3">
                <label for="">Permit 5 Years Due Date</label>
                <input
                  type="date"
                  id="permitYearsDueDatectrl"
                  class="form-control shadow-none"
                  placeholder="Enter Permit 5 Years Due Date"
                  formControlName="permitYearsDueDate"
                />
              </div>
            </div>
            <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
              <div class="form-group accountsinp mb-3">
                <label for="">Purchase Date</label>
                <input
                  type="date"
                  id="purchaseDatectrl"
                  class="form-control shadow-none"
                  placeholder="Enter Purchase Date"
                  formControlName="purchaseDate"
                />
              </div>
            </div>

            <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
              <div class="form-group accountsinp mb-3">
                <label for="">Road Tax Date</label>
                <input
                  type="date"
                  id="roadTaxDatectrl"
                  class="form-control shadow-none"
                  placeholder="Enter Road Tax Date"
                  formControlName="roadTaxDate"
                />
              </div>
            </div>
            <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
              <div class="form-group accountsinp mb-3">
                <label for="">Insurance Due Date</label>
                <input
                  type="date"
                  id="insuranceDueDatectrl"
                  class="form-control shadow-none"
                  placeholder="Enter Insurance Due Date"
                  formControlName="insuranceDueDate"
                />
              </div>
            </div>
            <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
              <div class="form-group accountsinp mb-3">
                <label for="">Fitness Due Date</label>
                <input
                  type="date"
                  id="fitnessDueDatectrl"
                  class="form-control shadow-none"
                  placeholder="Enter Fitness Due Date"
                  formControlName="fitnessDueDate"
                />
              </div>
            </div>

            <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
              <div class="form-group accountsinp mb-3">
                <label for="">Pollution Expiry Date</label>
                <input
                  type="date"
                  id="pollutionExpiryDatectrl"
                  class="form-control shadow-none"
                  placeholder="Enter Pollution Expiry Date"
                  formControlName="pollutionExpiryDate"
                />
              </div>
            </div>
            <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
              <div class="form-group accountsinp mb-3">
                <label for="">Permit</label>
                <!-- <div class="previewImage">
                                    <img src="{{apiUrl+'/'+this.particlarVehicle?.permit}}">
                                </div> -->

            <div class="fileupload d-none align-items-center justify-content-between">

                  <input
                    type="file"
                    accept="image/*,.pdf,.doc"
                    (change)="fileChangeEventPermit($event)"
                    id="permitctrl"                   
                    class="form-control shadow-none w-auto"
                    formControlName="permit"
                  />
                  <div class="my-auto d-flex align-items-center">
                  <button
                      mat-raised-button
                      class="btn btn-dark me-2"
                      type="submit"
                    >
                      <i class="fa-solid fa-upload"></i>
                    </button>

                    <button
                    class="btn btn-dark btn-sm px-2 canceluplod"
                      type="button"
                      mat-raised-button
                    >
                      Cancel
                    </button>
                  </div>
                 </div>   

                 <div class="filenPreview d-flex align-items-center justify-content-between">
                    <label for="" 
                      ><strong>FileName: </strong>{{ fileNameLoad ? fileNameLoad.permit:" No Attachment Found"}}</label
                    >
                    <div>
                    <button
                    class="btn btn-dark btn-sm px-2 me-2"
                      type="button"
                      (click)="preview('permit')"
                      mat-raised-button
                    >
                      Preview
                    </button>
                    <button
                    class="btn btn-dark btn-sm px-2 editImg"
                      type="button"
                      mat-raised-button
                    >
                      Edit Image
                    </button>
                    </div>                    
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
              <div class="form-group accountsinp mb-3">
                <label for="">RC Copy</label>
                <!-- <div class="previewImage">
                                <img src="{{apiUrl+'/'+this.particlarVehicle?.RCCopy}}">
                                </div> -->
                <div class="fileupload d-none align-items-center justify-content-between">
                  
                  <input
                    type="file"
                    accept="image/*,.pdf,.doc"
                    id="RCCopyctrl"
                    (change)="fileChangeEventRC($event)"
                    class="form-control shadow-none w-auto"
                    formControlName="RCCopy"
                  />

                  <div class="my-auto d-flex align-items-center">
                    <button
                      mat-raised-button
                      class="btn btn-dark me-2"
                      type="submit">
                      <i class="fa-solid fa-upload"></i>
                  </button>
                  <button
                      class="btn btn-dark btn-sm px-2 canceluplod"
                      type="button"
                      mat-raised-button
                      >
                      Cancel
                    </button>
                  </div>
                </div>
                <div class="filenPreview d-flex align-items-center justify-content-between">

                  <label for=""><strong>FileName: </strong>{{ fileNameLoad ? fileNameLoad.RCCopy:" No Attachment Found"}}</label>

                    <div>
                      <button
                      class="btn btn-dark btn-sm px-2 me-2"
                      type="button"
                      mat-raised-button
                      (click)="preview('RCCopy')">
                      Preview
                    </button>
                    <button
                        class="btn btn-dark btn-sm px-2 editImg"
                        type="button"
                        mat-raised-button>
                        Edit Image
                    </button>
      
                    </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
              <div class="form-group accountsinp mb-3">
                <label for="">Insurance </label>
                <!-- <img src="{{apiUrl+'/'+this.particlarVehicle?.insurance}}" width="100" class="mb-3"> -->
                <div class="d-none align-items-center justify-content-between">
                  <input
                    type="file"
                    accept="image/*,.pdf,.doc"
                    id="insurancectrl"
                    (change)="fileChangeEventInsurance($event)"
                    class="form-control shadow-none w-auto"
                    formControlName="insurance"
                  />
                  
                  <div class="my-auto d-flex align-items-center">
                    <button
                      mat-raised-button
                      class="btn btn-dark me-2"
                      type="submit">
                      <i class="fa-solid fa-upload"></i>
                  </button>
                  <button
                      class="btn btn-dark btn-sm px-2 canceluplod"
                      type="button"
                      mat-raised-button
                      >
                      Cancel
                    </button>
                  </div>
                </div>
                <div class="filenPreview d-flex align-items-center justify-content-between">

                  <label for=""><strong>FileName: </strong>{{ fileNameLoad ? fileNameLoad.insurance:" No Attachment Found"}}</label>

                    <div>
                      <button
                      class="btn btn-dark btn-sm px-2 me-2"
                      type="button"
                      mat-raised-button
                      (click)="preview('insurance')">
                      Preview
                    </button>
                    <button
                        class="btn btn-dark btn-sm px-2 editImg"
                        type="button"
                        mat-raised-button>
                        Edit Image
                    </button>
      
                    </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
              <div class="form-group accountsinp mb-3">
                <label for="">Permit 5 Years</label>
                <!-- <img src="{{apiUrl+'/'+this.particlarVehicle?.permitYears}}" width="100"class="mb-3"> -->
                <div class="fileupload d-none align-items-center justify-content-between">
                  <input
                    type="file"
                    accept="image/*,.pdf,.doc"
                    id="permitYearsctrl"
                    (change)="fileChangeEventPermitYears($event)"
                    class="form-control shadow-none w-auto"
                    formControlName="permitYears"
                  />
                
                  <div class="my-auto d-flex align-items-center">
                  <button
                      mat-raised-button
                      class="btn btn-dark me-2"
                      type="submit"
                    >
                      <i class="fa-solid fa-upload"></i>
                    </button>
                    <button
                    class="btn btn-dark btn-sm px-2 canceluplod"
                      type="button"
                      mat-raised-button
                    >
                      Cancel
                    </button>
                    </div>
                    </div>
                    <div class="filenPreview d-flex align-items-center justify-content-between">
                    <label for="" 
                      ><strong>FileName: </strong>{{ fileNameLoad ? fileNameLoad.permitYears:" No Attachment Found"}}</label
                    >
                    <div>
                    <button
                    class="btn btn-dark btn-sm px-2 me-2"
                      type="button"
                      (click)="preview('permitYears')"
                      mat-raised-button
                    >
                      Preview
                    </button>
                    <button
                    class="btn btn-dark btn-sm px-2 editImg"
                      type="button"
                      mat-raised-button
                    >
                      Edit Image
                    </button>

                    </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
              <div class="form-group accountsinp mb-3">
                <label for="">Road Tax</label>
                <!-- <img src="{{apiUrl+'/'+this.particlarVehicle?.roadTax}}" width="100"  class="mb-3"> -->
                <div class="fileupload d-none align-items-center justify-content-between">
                  <input
                    type="file"
                    accept="image/*,.pdf,.doc"
                    id="roadTaxctrl"
                    (change)="fileChangeEventRoadTax($event)"
                    class="form-control shadow-none w-auto"
                    formControlName="roadTax"
                  />
                  <div class="my-auto d-flex align-items-center">
                  <button
                      mat-raised-button
                      class="btn btn-dark me-2"
                      type="submit"
                    >
                      <i class="fa-solid fa-upload"></i>
                    </button>
                    <button
                    class="btn btn-dark btn-sm px-2 canceluplod"
                      type="button"
                      mat-raised-button
                    >
                      Cancel
                    </button>
                    </div>
                    </div>
                    <div class="filenPreview d-flex align-items-center justify-content-between">
                    <label for="" 
                      ><strong>FileName: </strong>{{ fileNameLoad ? fileNameLoad.roadTax:" No Attachment Found"}}</label
                    >
                    <div>

                    <button
                    class="btn btn-dark btn-sm px-2 me-2"
                      type="button"
                      (click)="preview('roadTax')"
                      mat-raised-button
                    >
                      Preview
                    </button>
                    <button
                    class="btn btn-dark btn-sm px-2 editImg"
                      type="button"
                      mat-raised-button
                    >
                      Edit Image
                    </button>
                    </div>

                    
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
              <div class="form-group accountsinp mb-3">
                <label for="">Fitness </label>
                <!-- <img src="{{apiUrl+'/'+this.particlarVehicle?.fitness}}" width="100"class="mb-3"> -->
                <div class="fileupload d-none align-items-center justify-content-between">
                  <input
                    type="file"
                    accept="image/*,.pdf,.doc"
                    id="fitnessctrl"
                    (change)="fileChangeEventFitness($event)"
                    class="form-control shadow-none w-auto"
                    formControlName="fitness"
                  />
                 
                  <div class="my-auto d-flex align-items-center">
                  <button
                      mat-raised-button
                      class="btn btn-dark me-2"
                      type="submit"
                    >
                      <i class="fa-solid fa-upload"></i>
                    </button>

                    <button
                    class="btn btn-dark btn-sm px-2 canceluplod"
                      type="button"
                      mat-raised-button
                    >
                      Cancel
                    </button>
                    </div>
                    </div>
                    <div class="filenPreview d-flex align-items-center justify-content-between">
                    <label for="" 
                      ><strong>FileName: </strong>{{ fileNameLoad ? fileNameLoad.fitness:" No Attachment Found"}}</label
                    >
                      <div>
                    <button
                    class="btn btn-dark btn-sm px-2 me-2"
                      type="button"
                      (click)="preview('fitness')"
                      mat-raised-button
                    >
                      Preview
                    </button>
                    <button
                      class="btn btn-dark btn-sm px-2 editImg"
                      type="button"
                      mat-raised-button
                    >
                      Edit Image
                    </button>
                    </div>

                    
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
              <div class="form-group accountsinp mb-3">
                <label for="">Owner Pancard </label>
                <!-- <img src="{{apiUrl+'/'+this.particlarVehicle?.ownerPancard}}" width="100"class="mb-3"> -->
                <div class="fileupload d-none align-items-center justify-content-between">
                  <input
                    type="file"
                    accept="image/*,.pdf,.doc"
                    id="ownerPancardctrl"
                    (change)="fileChangeEventOwnerPancard($event)"
                    class="form-control shadow-none w-auto"
                    formControlName="ownerPancard"
                  />
                 
                  <div class="my-auto d-flex align-items-center">
                  <button
                      mat-raised-button
                      class="btn btn-dark me-2"
                      type="submit"
                    >
                      <i class="fa-solid fa-upload"></i>
                    </button>

                    <button
                    class="btn btn-dark btn-sm px-2 canceluplod"
                      type="button"
                      mat-raised-button
                    >
                      Cancel
                    </button>
                    </div>
                    </div>
                    <div class="filenPreview d-flex align-items-center justify-content-between">
                    <label for="" 
                      ><strong>FileName: </strong>{{ fileNameLoad ? fileNameLoad.ownerPancard:" No Attachment Found"}}</label
                    >
                      <div>
                    <button
                    class="btn btn-dark btn-sm px-2 me-2"
                      type="button"
                      (click)="preview('ownerPancard')"
                      mat-raised-button
                    >
                      Preview
                    </button>
                    <button
                    class="btn btn-dark btn-sm px-2 editImg"
                      type="button"
                      mat-raised-button
                    >
                      Edit Image
                    </button>
                    </div>

                    
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
              <div class="form-group accountsinp mb-3">
                <label for="">Owner Aadhaar Card </label>
                <!-- <img src="{{apiUrl+'/'+this.particlarVehicle?.ownerAadharCard}}" width="100"class="mb-3"> -->
                <div class="fileupload d-none align-items-center justify-content-between">
                  <input
                    type="file"
                    accept="image/*,.pdf,.doc"
                    id="ownerAadharCardctrl"
                    (change)="fileChangeEventOwnerAadharcard($event)"
                    class="form-control shadow-none w-auto"
                    formControlName="ownerAadharCard"
                  />
                  <div class="my-auto d-flex align-items-center">
                  <button
                      mat-raised-button
                      class="btn btn-dark me-2"
                      type="submit"
                    >
                      <i class="fa-solid fa-upload"></i>
                    </button>
                    <button
                    class="btn btn-dark btn-sm px-2 canceluplod"
                    type="button"
                    mat-raised-button
                  >
                    Cancel
                  </button>
                  </div>
                  </div>
                  <div class="filenPreview d-flex align-items-center justify-content-between">

                    <label for="" 
                      ><strong>FileName: </strong>{{ fileNameLoad ? fileNameLoad.ownerAadharCard:" No Attachment Found"}}</label
                    >
                    <div>

                    <button
                    class="btn btn-dark btn-sm px-2 me-2"
                      type="button"
                      (click)="preview('ownerAadharCard')"
                      mat-raised-button
                    >
                      Preview
                    </button>
                    <button
                    class="btn btn-dark btn-sm px-2 editImg"
                      type="button"
                      mat-raised-button
                    >
                      Edit Image
                    </button>
                    </div>

                   

                  
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-xl-4 col-sm-6 col-12 col-md-4">
              <div class="form-group accountsinp mb-3">
                <label for="">Cancelled Cheque </label>
                <!-- <img src="{{apiUrl+'/'+this.particlarVehicle?.cancelledCheque}}" width="100" class="mb-3"> -->
                <div class="fileupload d-none align-items-center justify-content-between">
                  <input
                    type="file"
                    accept="image/*,.pdf,.doc"
                    id="cancelledChequectrl"
                    (change)="fileChangeEventCancelledCheque($event)"
                    class="form-control shadow-none w-auto"
                    formControlName="cancelledCheque"
                  />
                  <div class="my-auto d-flex align-items-center">
                  <button
                  mat-raised-button
                  class="btn btn-dark me-2"
                  type="submit"
                >
                  <i class="fa-solid fa-upload"></i>
                </button>

                <button
                class="btn btn-dark btn-sm px-2 canceluplod"
                      type="button"
                      mat-raised-button
                    >
                      Cancel
                    </button>
                    </div>
                    </div>

                    <div class="filenPreview d-flex align-items-center justify-content-between">

                  <label for="" 
                      ><strong>FileName: </strong>{{ fileNameLoad ? fileNameLoad.cancelledCheque:" No Attachment Found"}}</label
                    >
                    <div>
                  <button
                  class="btn btn-dark btn-sm px-2 me-2"
                    type="button"
                    mat-raised-button
                    (click)="preview('cancelledCheque')"
                  >
                    Preview
                  </button>
                  <button
                  class="btn btn-dark btn-sm px-2 editImg"
                      type="button"
                      mat-raised-button
                    >
                      Edit Image
                    </button>
                    </div>

                    
                </div>
              </div>
            </div>
            <a #showSlip [href]="slipURL" target="_blank"></a>
            <div class="col-md-12 text-end">
              <button
                class="btn btn-outline-dark"
                type="button"
                (click)="resetForm()"
                mat-raised-button
              >
                Reset
              </button>
              <button class="btn btn-dark ms-2" type="submit" mat-raised-button>
                Update
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
