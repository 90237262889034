import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MatPaginator, MatSort, MatTableDataSource, Sort } from '@angular/material';
import { SuperAdminService } from '../../../super-admin.service';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.css']
})
export class UsersListComponent implements OnInit {
  // @ViewChild(DataTableDirective, { static: false })
  // private datatableElement: DataTableDirective;

  // dtOptions: DataTables.Settings = {
  //     data:[],
  //     columns: [{
  //       title: 'Name',
  //       data: 'username'
  //     }, {
  //       title: 'Email',
  //       data: 'email'
  //     },
  //     {
  //       title: 'Mobile',
  //       data: 'mobile'
  //     }, {
  //       title: 'Role',
  //       data: 'user_role_id'
  //     }, {
  //       title: 'Status',
  //       data: 'isactive'
  //     },{
  //       title: 'Action',
  //       data: null,
  //       orderable: true,
  //       render: (data, type, full) => '<button (click)="deleteuser(data)">Delete</button>'
  //     }]
  // };

  // displayToConsole(datatableElement: DataTableDirective): void {
  //   datatableElement.dtInstance.then((dtInstance: DataTables.Api) => console.log(dtInstance));
  // }

  loginsearchform = new FormGroup({
    username: new FormControl(''),
    login: new FormControl(''),
    logout: new FormControl('')
  });
  users: any=[];
  @ViewChild('paginator') paginator: MatPaginator;
  dataSource:  MatTableDataSource<any>;
  displayedColumns: any = ['full_name', 'email', 'isactive', 'action'];
  @ViewChild(MatSort) sort: MatSort;

  constructor(private router: Router, private toastr: ToastrService, private superAdminService: SuperAdminService, private spinner:NgxSpinnerService) { }

  ngOnInit(): void {
    this.getUsers();
  }
  async getUsers() {
    this.spinner.show();
    await this.superAdminService.getUsers()
      .subscribe((res: { status: any, message: any, data: any }) => {
        if (res.status == true) {
          this.spinner.hide();
          var rawdata = res.data;
          this.users = rawdata;
          this.dataSource = new MatTableDataSource(this.users);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');
            
          }else {
            this.toastr.error(res.message);
          }
          this.spinner.hide();
        }

      }, error => {
        this.toastr.error('Something went wrong! Please contact us');
        this.spinner.hide();
      });
  }
  searchLoginHistory() {

  }

  emitdata(params) {
    sessionStorage.setItem("userdata", JSON.stringify(params));
  }

  deleteuser(email) {
    if(this.superAdminService.deleteDailogBox()){
    this.superAdminService.deleteuser(email)
      .subscribe((res: { status: any, message: any, authorization: any, code: any }) => {
        if (res.status == true) {
          this.toastr.success(res.message);
          //this.router.navigateByUrl('/user/userManagement');
          this.getUsers();
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');
          }else {
            this.toastr.success(res.message);
          }
        }

      }, error => {
      });
  }
}
  filterData(value){
    this.dataSource.filter = value;
  }
  sortData(sort: Sort) {
    const data = this.dataSource.filteredData;
    if (!sort.active || sort.direction === '') {
      return this.dataSource;
    }
    this.dataSource.filteredData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'full_name':
          return compare(lower(a.full_name), lower(b.full_name), isAsc);
        case 'email':
          return compare(lower(a.email), lower(b.email), isAsc);
        default:
          return 0;
      }
    });
    function compare(a: number | string , b: number | string, isAsc: boolean) {
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
    function lower(value){
      return value?value.toLowerCase():value;
    }
  }

}
