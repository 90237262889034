import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, Sort } from '@angular/material';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SuperAdminService } from 'src/app/super-admin.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

@Component({
  selector: 'app-total-invoice',
  templateUrl: './total-invoice.component.html',
  styleUrls: ['./total-invoice.component.css']
})
export class TotalInvoiceComponent implements OnInit {


  dataSource: MatTableDataSource<any>;
  clientList: any[] = [{}];
  users: any = [];
  tooltaxPaidtot: any;
  extrahour: any;
  invtot: any;
  kmtot: any;
  invoiceList: any = [{}];
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  clientName: any = [];
  pageName: any;
  displayedColumns: any = ['sno', 'clientName', 'created_date', 'action']
  @ViewChild('printBtn') printBtn: ElementRef<HTMLElement>;

  public subscribers: any = {};




  constructor(private router: Router, private toastr: ToastrService, private superAdminService: SuperAdminService, private spinner: NgxSpinnerService, private route: ActivatedRoute) {

  }



  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.pageName = params['param2']
      console.log("data invoice from route parameters", this.pageName)
    })



    this.getClientList()
  }





  getClientList() {
    this.superAdminService.getClientList()
      .subscribe((res: { status: any, message: any, data: any }) => {
        if (res.status == true) {

          var rawdata = res.data;
          this.clientList = rawdata;
          let userSessionData = JSON.parse(sessionStorage.getItem('userSession'));
          let userroleid = userSessionData.user_role_id[0];
          if (userroleid == 1) {
            let clients = userSessionData.clientList;
            this.clientList = clients.map(email => {
              return this.clientList.filter(item => item.clientEmail == email)[0]
            });
          }
          this.superAdminService.getInvoice().subscribe((invoices: { status: any, message: any, data: any }) => {

            if (invoices.status == true) {
              this.spinner.hide(); 
              let newrawdata = invoices.data;
              this.invoiceList = newrawdata;

              if (this.pageName === 'Generated Invoice Using Company Name') {
                let temp = []
                this.invoiceList.map((x) => {
                  this.clientList.map((y) => {
                    if (x.clientName == y._id) temp.push({ "clientName": y.clientName, "date": x.created_date.split("T")[0], "_id": x._id, "created_date": x.created_date })
                  })
                })
                this.clientName = temp;
                this.dataSource = new MatTableDataSource(this.clientName);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
              }

              if (this.pageName === 'Generated Invoice Using TripID') {
                console.log(this.invoiceList)
                let temp = []
                this.invoiceList.map((x) => {
                  this.clientList.map((y) => {
                    if (x.clientName == y._id) temp.push({ "clientName": y.clientName, "date": x.created_date.split("T")[0], "_id": x._id, "created_date": x.created_date })
                  })
                })
                this.clientName = temp;
                this.dataSource = new MatTableDataSource(this.clientName);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
              }

              if (this.pageName === 'Pending Invoice Using Company Name') {
                let temps = []
                  this.invoiceList = this.invoiceList.map((x) => x.invoice[0])
                console.log("invoicelist===" , this.invoiceList)
                console.log("clientlist====", this.clientList)
                this.invoiceList.map((x) => {
                  this.clientList.map((y) => {
                    if (x.consignee_name == y._id) temps.push({ "clientName": y.clientName, "date": x.created_by, "_id": x._id, "created_date": x.created_date })
                  })
                })

                console.log("temp======" , temps)
                this.clientName = temps;
                this.dataSource = new MatTableDataSource(this.clientName);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;

              }

              if (this.pageName === 'Pending Invoice Using TripID') {
                let temps = []
                  this.invoiceList = this.invoiceList.map((x) => x.invoice[0])
                console.log("invoicelist===" , this.invoiceList)
                console.log("clientlist====", this.clientList)
                this.invoiceList.map((x) => {
                  this.clientList.map((y) => {
                    if (x.consignee_name == y._id) temps.push({ "clientName": y.clientName, "date": x.created_by, "_id": x._id, "created_date": x.created_date })
                  })
                })

                console.log("temp======" , temps)
                this.clientName = temps;
                this.dataSource = new MatTableDataSource(this.clientName);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;

              }
            }
            else {
              if (res.status == 401) {
                this.toastr.error(res.message);
                this.spinner.hide();
                window.sessionStorage.clear();
                this.router.navigateByUrl('superAuth/login');

              } else {
                this.toastr.error(res.message);
                this.spinner.hide();
              }
            }
          }
          );
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            this.spinner.hide();
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');

          } else {
            this.spinner.hide();
            this.toastr.error(res.message);
          }
        }

      });

  }



  sortData(sort: Sort) {
    const data = this.dataSource.filteredData;
    if (!sort.active || sort.direction === '') {
      return this.dataSource;
    }
    this.dataSource.filteredData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'clientName':
          return compare(lower(a.clientName), lower(b.clientName), isAsc);
        case 'created_date':
          return compare(a.created_date, b.created_date, isAsc);
        default:
          return 0;
      }
    });
    function compare(a: number | string, b: number | string, isAsc: boolean) {
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
    function lower(value) {
      return value ? value.toLowerCase() : value;
    }
  }

  backTo() {
    window.history.back()
  }

  filterData(value) {
    this.dataSource.filter = value;
  }

  getParticularInvoice(id: string) {
    this.superAdminService.getParticularInvoice(id)
      .subscribe((res: { status: any, message: any, data: any }) => {

        if (res.status == true) {
          var rawdata = res.data;
          this.users = rawdata[0].invoice;

          this.users.forEach((value: any) => {
            let balancepaidArray = value.balancePaid;
            this.invtot = balancepaidArray.reduce((a, b) => parseInt(a) + parseInt(b), 0)

            let up_down_kmArray = value.up_down_km;
            this.kmtot = up_down_kmArray.reduce((a, b) => parseInt(a) + parseInt(b), 0)

            let extrahourArray = value.extrahour;
            this.extrahour = extrahourArray.reduce((a, b) => parseInt(a) + parseInt(b), 0)

            let tollArray = value.tooltaxPaid;
            this.tooltaxPaidtot = tollArray.reduce((a, b) => parseInt(a) + parseInt(b), 0)
            //console.log("balancePaid",this.invtot);
            setTimeout(() => {
              let printBtn: HTMLElement = this.printBtn.nativeElement;
              printBtn.click();
            });
          });
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');

          } else {
            this.toastr.success(res.message);
          }
        }

      }, error => {
      });
  }

  sendInvoice(divName, Id) {
    this.superAdminService.getParticularInvoice(Id)
      .subscribe((res: { status: any, message: any, data: any }) => {

        if (res.status == true) {
          var rawdata = res.data;
          this.users = rawdata[0].invoice;

          this.users.forEach((value: any) => {
            let balancepaidArray = value.balancePaid;
            this.invtot = balancepaidArray.reduce((a, b) => parseInt(a) + parseInt(b), 0)

            let up_down_kmArray = value.up_down_km;
            this.kmtot = up_down_kmArray.reduce((a, b) => parseInt(a) + parseInt(b), 0)

            let extrahourArray = value.extrahour;
            this.extrahour = extrahourArray.reduce((a, b) => parseInt(a) + parseInt(b), 0)

            let tollArray = value.tooltaxPaid;
            this.tooltaxPaidtot = tollArray.reduce((a, b) => parseInt(a) + parseInt(b), 0)

          });
          // send invoice begins
          setTimeout(() => {
            var data = document.getElementById(divName);
            data.style.display = 'block';
            html2canvas(data).then(canvas => {
              var imgWidth = 208;
              var imgHeight = canvas.height * imgWidth / canvas.width;
              const contentDataURL = canvas.toDataURL('image/png', 0.3)
              let pdf = new jsPDF('p', 'mm', 'a4');
              pdf.addImage(contentDataURL, 'PNG', 1, 1, imgWidth, imgHeight, undefined, 'FAST');
              let binary = pdf.output();
              data.style.display = 'none';
              binary = binary ? btoa(binary) : '';
              this.superAdminService.sendInvoice(Id, binary).subscribe((res: any) => {
                if (res.status == true) {
                  this.toastr.success(res.message);
                }
                else {
                  if (res.status == 401) {
                    this.toastr.error(res.message);
                    window.sessionStorage.clear();
                    this.router.navigateByUrl('superAuth/login');

                  } else {
                    this.toastr.error(res.message);
                  }
                }
              }, error => {
              });
            });
          })
        }
        else {
          if (res.status == 401) {
            this.toastr.error(res.message);
            window.sessionStorage.clear();
            this.router.navigateByUrl('superAuth/login');

          } else {
            this.toastr.success(res.message);
          }
        }

      }, error => {
      });
  }

  deleteInvoice(_id) {
    if (this.superAdminService.deleteDailogBox()) {
      this.superAdminService.deleteInvoice(_id)
        .subscribe((res: { status: any, message: any, authorization: any, code: any }) => {

          if (res.status == true) {
            this.toastr.success(res.message);
            // this.ngOnInit();
            this.ngOnInit()
          }
          else {
            if (res.status == 401) {
              this.toastr.error(res.message);
              window.sessionStorage.clear();
              this.router.navigateByUrl('superAuth/login');

            } else {
              this.toastr.error(res.message);
            }
          }

        }, error => {
        });
    }
  }

}
